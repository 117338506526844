$icon-arrow-left: '\e914';

.c-back-link {
  font-size: 1.8rem;
  font-weight: normal;
  color: $color-text-gray;
  &:before {
    content: $icon-arrow-left;
    font-family: icomoon;
    display: inline-block;
    vertical-align: middle;
    font-size: 14px; } }

@include MQ(768px) {
  .c-back-link {
    position: relative;
    &:before {
      position: absolute;
      right: 100%;
      top: 50%;
      margin-right: 5px;
      margin-top: -7px; } } }

@include MQ(1025px) {
  .c-back-link {
    @include transition;
    &:hover {
      color: $color-text; } } }

.u-back-link-wrapper {
  line-height: 1;
  display: block; }
