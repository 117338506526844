.c-catalog {
  .c-catalog__inner {
    position: relative;
    overflow: hidden; }
  .c-catalog__asside {
    transform: translateX(-130%);
    position: absolute;
    top: 0;
    bottom: 0;
    transition: transform .3s;
    opacity: 0; }
  .c-catalog__list {
    transition: .3s;
    min-height: 500px; }
  .c-catalog__backdrop {
    transform: translateX(130%);
    transition: .3s;
    position: absolute;
    left: 255px;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10; }
  &.is-open {
    .c-catalog__backdrop {
      transform: translateX(0); }
    .c-catalog__asside {
      transform: translateX(0);
      opacity: 1;
      width: 255px; }
    .c-catalog__list {
      transform: translateX(255px);
      transition: .3s; }
    .c-preview,
    .c-more {
      opacity: .1; } } }

@include MQ(993px) {
  .c-catalog {
    padding-bottom: 50px;
    .c-catalog__backdrop {
      left: 395px; }
    &.is-open {
      .c-catalog__asside {
        width: 395px; }
      .c-catalog__list {
        transform: translateX(395px); } } } }

@include MQ(1280px) {
  .c-catalog {
    .c-catalog__inner {
      overflow: visible; }
    .c-catalog__asside {
      z-index: 5; }
    &.is-open {
      .c-catalog__list {
        padding-left: 415px;
        transform: translateX(0); }
      .c-preview,
      .c-more {
        opacity: 1; } }
    .c-catalog__backdrop {
      z-index: -1;
      display: none; }
    .c-catalog__list {
      padding-right: $space-desktop/2;
      padding-left: $space-desktop/2; } } }

@include MQ(1435px) {
  .c-catalog {
    padding-bottom: 100px;
    .c-catalog__list {
      padding-right: $space-desktop;
      padding-left: $space-desktop; }
    &.is-open {
      .c-catalog__list {
        padding-right: 0; } }
    .c-catalog__inner {
      max-width: 1395px;
      margin-right: auto;
      margin-left: auto; } } }
