.c-typical-block {
  font-weight: 500;
  &__center {
    display: flex;
    flex-direction: column;
    align-items: center; }
  &__const {
    position: relative; }
  &__section {
    font-size: 0;
    &:not(:last-child) {
      margin-bottom: 50px; } }
  &__title {
    font-size: 2.6rem;
    margin-bottom: 20px;
    line-height: 1.2;
    &--accent-color {
      color: $color-text-yellow; } }
  &__subtitle {
    font-size: 2.2rem;
    margin-bottom: 20px;
    line-height: 1; }
  &__subtitle-2 {
    font-size: 1.8rem;
    margin-bottom: 20px; }
  &__text {
    max-width: 450px;
    p,
    ul {
      font-size: 1.8rem;
      line-height: 1.4;
      color: $color-text-gray;
      &:not(:last-child) {
        margin-bottom: 25px; } }
    p {
      max-width: 410px;
      a {
        display: inline; }
      + ul {
        margin-top: -15px; } }
    .c-typical-block__title {
      margin-bottom: 8px;
      line-height: 1.5; }
    &--white {
      p {
        color: $color-text; } } }
  .c-btn {
    max-width: 365px;
    width: 100%; }
  .c-form {
    .c-btn {
      max-width: 405px; } } }

@include MQ(480px) {
  .c-typical-block {
    .c-form {
      &__group,
      .c-btn {
        max-width: none;
        width: 405px; } } } }

@include MQ-max(767px) {
  .c-typical-block {
    &__title {
      padding-right: 80px; }
    &__center-mob {
      display: flex;
      flex-direction: column;
      align-items: center; }
    &__sidebar {
      display: inline-block; } } }

@include MQ(768px) {
  .c-typical-block {
    font-size: 0;
    &__const,
    &__var {
      display: inline-block;
      vertical-align: top; }
    &__const {
      width: 250px;
      margin-right: -250px;
      padding-top: 10px; }
    &__var {
      padding-left: 270px;
      width: 100%; }
    &__padding0 {
      padding-left: 0;
      max-width: 430px;
      margin-left: auto;
      margin-right: auto; }
    &__title {
      margin-bottom: 50px;
      font-size: 3rem; } } }

@include MQ(1025px) {
  .c-typical-block {
    &__const {
      width: 280px;
      margin-right: -280px; }
    &__var {
      padding-left: 330px; }
    &__padding0 {
      min-width: 430px;
      padding-left: 0; }
    .c-btn {
      margin-left: -20px; } } }

@include MQ-max(1024px) {
  .l-mob-space-left {
    padding-left: 20px; } }

.l-space-top {
  padding-top: 40px; }
