.c-prev {
  [class^='icon'] {
    transform: rotate(90deg); } }

.c-next {
  [class^='icon'] {
    transform: rotate(-90deg); } }

.c-arrow {
  z-index: 5;
  width: 61px;
  height: 47px;
  border-radius: 4px;
  .c-arrow__inner {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  [class^='icon'] {
    font-size: 1.8rem;
    color: rgba(#181d23, .5);
    display: inline-block; } }

@include MQ(1280px) {
  .c-arrow {
    cursor: pointer;
    width: 86px;
    [class^='icon'] {
      font-size: 2.1rem; } } }
