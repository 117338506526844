.c-order-view {
  max-width: 940px;
  &__const {
    padding-top: 25px; }
  .c-typical-block {
    &__subtitle {
      margin-bottom: 5px; } }
  .c-cabinet-info {
    &__text {
      color: $color-text-gray; }
    &:not(:last-child) {
      margin-bottom: 25px; }
    &__item {
      width: 100%; } }
  .c-typical-block__text {
    margin-bottom: 50px; }
  .c-history-table {
    td {
      vertical-align: top; }
    .c-history-table {
      &__image {
        width: 85px;
        height: 85px; } } } }

@include MQ-max(767px) {
  .c-order-view {
    .c-history-table {
      td {
        display: inline-block;
        &:not(:last-child) {
          padding-bottom: 0; } } } } }

@include MQ(768px) {
  .c-order-view {
    .c-history-table {
      td {
        padding-top: 20px;
        padding-bottom: 20px; } } } }

@include MQ(1200px) {
  .c-order-view {
    font-size: 0;
    &__var,
    &__const {
      display: inline-block;
      vertical-align: top; }
    &__var {
      padding-right: 370px;
      width: 100%; }
    &__const {
      width: 320px;
      margin-left: -320px; } } }
