.s-menu {
  ul {
    padding-bottom: 35px; }
  li {
    color: #fff;
    font-size: 2.4rem;
    font-weight: 500;
    &.t-bonus {
      color: $color-text-yellow; }

    &:first-child {
      padding-top: 19px; } }
  li {
    > a {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      padding-left: $space;
      padding-right: $space;
      padding-top: 13px;
      padding-bottom: 13px;
      background: unset;
      line-height: 1.3; }
    .has-icon {
      position: relative;
      padding-left: 57px;
      [class^='icon'] {
        font-size: 2.4rem;
        width: 57px;
        height: 31px;
        position: absolute;
        display: block;
        left: 0;
        text-align: center;
        top: 50%;
        bottom: auto;
        transform: translateY(-50%); } } } }

.s-menu-nested {
  &.is-drilldown-submenu {
    background-color: $color-blue;
    li {
      font-size: 1.8rem; }
    li {
      > a {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 34px;
        &.has-icon {
          padding-left: 57px; }
        &.is-back {
          font-size: 2.4rem;
          padding-top: 13px;
          a {
            padding-bottom: 13px; } } } } } }

.s-menu-subnested {
  &.is-drilldown-submenu {
    background-color: $color-bg;
    li {
      font-size: 1.8rem; }
    li {
      > a {
        padding-left: 38px;
        &.is-back {
          font-size: 2.4rem;
          padding-top: 13px;
          a {
            padding-bottom: 13px; } } } } } }

