.c-contacts {
  .c-contacts__heading {
    margin-bottom: 37px; }
  .c-contacts__label {
    margin-bottom: 8px; }
  .c-contacts__column {
    + .c-contacts__column {
      margin-top: 30px; } } }

@include MQ(1025px) {
  .c-contacts {
    .c-contacts__row {
      font-size: 0;
      margin-left: -$space;
      margin-right: -$space; }
    .c-contacts__column {
      width: 33.3333%;
      display: inline-block;
      vertical-align: top;
      padding-left: $space;
      padding-right: $space;
      + .c-contacts__column {
        margin-top: 0; } } } }

@include MQ(1280px) {
  .c-contacts {
    .c-contacts__row {
      margin-left: -35px;
      margin-right: -35px; }
    .c-contacts__column {
      padding-left: 35px;
      padding-right: 35px; } } }
