$loc: '\e916';

.c-cabinet-info {
  display: inline-block;
  vertical-align: top;
  font-size: 1.8rem;
  line-height: 1.35;
  width: 100%;
  p {
    font-size: inherit;
    line-height: inherit; }
  &__text {
    display: block;
    &:not(:last-child) {
      margin-bottom: 18px; } }
  &--with-marker {
    .c-cabinet-info {
      &__text {
        position: relative;
        color: $color-text-gray;
        &:before {
          content: $loc;
          position: absolute;
          right: 100%;
          top: -3px;
          margin-right: 12px;
          font-size: 2.2rem;
          font-family: icomoon;
          color: $color-blue; } } } } }

.c-cabinet-info-wrapper {
  .c-cabinet-info {
    &:not(:last-child) {
      margin-bottom: 40px; } } }

@include MQ(480px) {
  .c-cabinet-info-wrapper {
    margin: 0 -10px;
    .c-cabinet-info {
      width: 50%;
      padding: 0 10px; } } }

@include MQ(768px) {
  .c-cabinet-info {
    &--with-marker {
      .c-cabinet-info {
        &__text {
          &:before {
            margin-right: 6px; } } } } }
  .c-cabinet-info-wrapper {
    .c-cabinet-info {
      &:not(:last-child) {
        margin-bottom: 20px; } } } }

@include MQ(1025px) {
  .c-cabinet-info-wrapper {
    .c-cabinet-info {
      width: 33.33333%; } } }

@include MQ(1367px) {
  .c-cabinet-info-wrapper {
    margin: 0 -45px;
    .c-cabinet-info {
      width: auto;
      padding: 0 45px; } } }
