*, *::after, *::before {
  box-sizing: border-box; }
a {
  outline: none; }

html {
  font-size: 62.5%;
  height: 100%;
  font-family: sans-serif /* 1 */;
  -ms-text-size-adjust: 100% /* 2 */;
  -webkit-text-size-adjust: 100% /* 2 */; }
body {
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0; }
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }
audio,
canvas,
progress,
video {
  display: inline-block /* 1 */;
  vertical-align: baseline /* 2 */; }
audio:not([controls]) {
  display: none;
  height: 0; }
[hidden],template {
  display: none; }
p,h1,h2,h3,h4,h5,h6,ul {
  padding: 0;
  margin: 0; }
a {
  background-color: transparent; }
a:active,
a:hover {
  outline: 0; }
abbr[title] {
  border-bottom: 1px dotted; }
b,
strong {
  font-weight: bold; }
dfn {
  font-style: italic; }
mark {
  background: #ff0;
  color: #000; }
small {
  font-size: 80%; }
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }
sup {
  top: -0.5em; }
sub {
  bottom: -0.25em; }
img {
  border: 0; }
svg:not(:root) {
  overflow: hidden; }
figure {
  margin: 1em 40px; }
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }
pre {
  overflow: auto; }
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }
button,
input,
optgroup,
select,
textarea {
  color: inherit /* 1 */;
  font: inherit /* 2 */;
  margin: 0 /* 3 */; }
button {
  overflow: visible; }
button,
select {
  text-transform: none; }
button, {
 }  //html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button /* 2 */;
  cursor: pointer /* 3 */; }
button[disabled],
html input[disabled] {
  cursor: default; }
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }
input {
  line-height: normal; }
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box /* 1 */;
  padding: 0 /* 2 */; }
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }
input[type="search"] {
  -webkit-appearance: textfield /* 1 */; }
//-moz-box-sizing: content-box
//-webkit-box-sizing: content-box /* 2 */
//box-sizing: content-box
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }
legend {
  border: 0 /* 1 */;
  padding: 0 /* 2 */; }
textarea {
  overflow: auto; }
optgroup {
  font-weight: bold; }
table {
  border-collapse: collapse;
  border-spacing: 0; }
td,
th {
  padding: 0; }
ul,ol {
  margin: 0;
  padding: 0;
  list-style: none; }
label, button {
  cursor: pointer; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }
sup {
  top: -0.5em; }
sub {
  bottom: -0.25em; }
ol, ul {
  &.numeration {
    list-style-type: decimal;
    margin: 0 0 20px 22px;
    li {
      color: #000;
      font-size: 14px;
      font-weight: 300;
      line-height: 1.6;
      margin-bottom: 5px;
      strong {
        font-weight: 700; } } } }

strong {
  font-weight: bold; }

.cuSelect {
  z-index: 1; }

.text-center {
  text-align: center !important; }
.text-left {
  text-align: left !important; }
.text-right {
  text-align: right !important; }

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline; } }

ul.link-holder {
  li {
    display: inline-block;
    vertical-align: middle;
    + li {
      margin-left: 30px; } } }

button {
  border: none; }

a,
area,
button,
[role="button"],
input:not([type=range]),
label,
select,
summary,
textarea {
  touch-action: manipulation; }
