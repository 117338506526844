@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.2
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article, aside, footer, header, nav, section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption, figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active, a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b, strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b, strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code, kbd, samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio, video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button, input, optgroup, select, textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button, select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button, [type="button"], [type="reset"], [type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*, *::before, *::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 3px; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 3px; }

.map_canvas img, .map_canvas embed, .map_canvas object, .mqa-display img, .mqa-display embed, .mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 3px;
  background: transparent;
  line-height: 1;
  cursor: auto; }

[data-whatinput='mouse'] button {
  outline: 0; }

pre {
  overflow: auto; }

button, input, optgroup, select, textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

[data-whatinput='mouse'] .menu li {
  outline: 0; }

.menu a, .menu .button {
  line-height: 1;
  text-decoration: none;
  display: block;
  padding: 0.7rem 1rem; }

.menu input, .menu select, .menu a, .menu button {
  margin-bottom: 0; }

.menu input {
  display: inline-block; }

.menu, .menu.horizontal {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-direction: row;
  flex-direction: row; }

.menu.vertical {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-direction: column;
  flex-direction: column; }

.menu.expanded li {
  -ms-flex: 1 1 0px;
  flex: 1 1 0px; }

.menu.simple {
  -ms-flex-align: center;
  align-items: center; }

.menu.simple li + li {
  margin-left: 1rem; }

.menu.simple a {
  padding: 0; }

@media print, screen and (min-width: 40em) {
  .menu.medium-horizontal {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: row;
    flex-direction: row; }
  .menu.medium-vertical {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column; }
  .menu.medium-expanded li {
    -ms-flex: 1 1 0px;
    flex: 1 1 0px; }
  .menu.medium-simple li {
    -ms-flex: 1 1 0px;
    flex: 1 1 0px; } }

@media print, screen and (min-width: 64em) {
  .menu.large-horizontal {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: row;
    flex-direction: row; }
  .menu.large-vertical {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column; }
  .menu.large-expanded li {
    -ms-flex: 1 1 0px;
    flex: 1 1 0px; }
  .menu.large-simple li {
    -ms-flex: 1 1 0px;
    flex: 1 1 0px; } }

.menu.nested {
  margin-right: 0;
  margin-left: 1rem; }

.menu.icons a {
  display: -ms-flexbox;
  display: flex; }

.menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
  display: -ms-flexbox;
  display: flex; }

.menu.icon-left li a {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap; }

.menu.icon-left li a img, .menu.icon-left li a i, .menu.icon-left li a svg {
  margin-right: 0.25rem; }

.menu.icon-right li a {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap; }

.menu.icon-right li a img, .menu.icon-right li a i, .menu.icon-right li a svg {
  margin-left: 0.25rem; }

.menu.icon-top li a {
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap; }

.menu.icon-top li a img, .menu.icon-top li a i, .menu.icon-top li a svg {
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center; }

.menu.icon-bottom li a {
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap; }

.menu.icon-bottom li a img, .menu.icon-bottom li a i, .menu.icon-bottom li a svg {
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center; }

.menu .is-active > a {
  background: #2ba6cb;
  color: #fefefe; }

.menu .active > a {
  background: #2ba6cb;
  color: #fefefe; }

.menu.align-left {
  -ms-flex-pack: start;
  justify-content: flex-start; }

.menu.align-right li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.menu.align-right li .submenu li {
  -ms-flex-pack: start;
  justify-content: flex-start; }

.menu.align-right.vertical li {
  display: block;
  text-align: right; }

.menu.align-right.vertical li .submenu li {
  text-align: right; }

.menu.align-right .nested {
  margin-right: 1rem;
  margin-left: 0; }

.menu.align-center li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center; }

.menu.align-center li .submenu li {
  -ms-flex-pack: start;
  justify-content: flex-start; }

.menu .menu-text {
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit; }

.menu-centered > .menu {
  -ms-flex-pack: center;
  justify-content: center; }

.menu-centered > .menu li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center; }

.menu-centered > .menu li .submenu li {
  -ms-flex-pack: start;
  justify-content: flex-start; }

.no-js [data-responsive-menu] ul {
  display: none; }

.is-drilldown {
  position: relative;
  overflow: hidden; }

.is-drilldown li {
  display: block; }

.is-drilldown.animate-height {
  transition: height 0.5s; }

.drilldown a {
  padding: 0.7rem 1rem;
  background: #fefefe; }

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fefefe;
  transition: transform 0.15s linear; }

.drilldown .is-drilldown-submenu.is-active {
  z-index: 1;
  display: block;
  -ms-transform: translateX(-100%);
  transform: translateX(-100%); }

.drilldown .is-drilldown-submenu.is-closing {
  -ms-transform: translateX(100%);
  transform: translateX(100%); }

.drilldown .is-drilldown-submenu a {
  padding: 0.7rem 1rem; }

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0; }

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%; }

.drilldown .is-drilldown-submenu-parent > a {
  position: relative; }

.drilldown .is-drilldown-submenu-parent > a::after {
  position: absolute;
  top: 50%;
  margin-top: -6px;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #2ba6cb; }

.drilldown.align-left .is-drilldown-submenu-parent > a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #2ba6cb; }

.drilldown.align-right .is-drilldown-submenu-parent > a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #2ba6cb transparent transparent; }

.drilldown .js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #2ba6cb transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #2ba6cb transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.7rem 1rem; }

[data-whatinput='mouse'] .dropdown.menu a {
  outline: 0; }

.dropdown.menu .is-active > a {
  background: transparent;
  color: #2ba6cb; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #2ba6cb transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #2ba6cb; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #2ba6cb transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #2ba6cb transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #2ba6cb; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #2ba6cb transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #2ba6cb transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #2ba6cb; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }

.is-dropdown-menu.vertical.align-right {
  float: right; }

.is-dropdown-submenu-parent {
  position: relative; }

.is-dropdown-submenu-parent a::after {
  position: absolute;
  top: 50%;
  right: 5px;
  left: auto;
  margin-top: -6px; }

.is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
  top: 100%;
  left: auto; }

.is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto; }

.is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fefefe; }

.dropdown .is-dropdown-submenu a {
  padding: 0.7rem 1rem; }

.is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
  right: 14px; }

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #2ba6cb transparent transparent; }

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #2ba6cb; }

.is-dropdown-submenu .is-dropdown-submenu {
  margin-top: -1px; }

.is-dropdown-submenu > li {
  width: 100%; }

.is-dropdown-submenu.js-dropdown-active {
  display: block; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }

.menu-icon::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #fefefe;
  box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
  content: ''; }

.menu-icon:hover::after {
  background: #cacaca;
  box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }

.menu-icon.dark::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #0a0a0a;
  box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
  content: ''; }

.menu-icon.dark:hover::after {
  background: #8a8a8a;
  box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a; }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  -ms-transform: translateY(0);
  transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  -ms-transform: translateX(0);
  transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  -ms-transform: translateY(0);
  transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  -ms-transform: translateX(0);
  transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateY(0);
  transform: translateY(0);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  -ms-transform: translateY(100%);
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  -ms-transform: translateX(100%);
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateY(0);
  transform: translateY(0);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  -ms-transform: translateY(-100%);
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  -ms-transform: translateX(-100%);
  transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  -ms-transform-origin: top;
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  -ms-transform-origin: right;
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  -ms-transform-origin: bottom;
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  -ms-transform-origin: left;
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  -ms-transform-origin: center;
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  -ms-transform-origin: center;
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: top;
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: right;
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: bottom;
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: left;
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: center;
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: center;
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: scale(0.5);
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: scale(1);
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: scale(1);
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  -ms-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: rotate(-0.75turn);
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  -ms-transform: rotate(0);
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: rotate(0);
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  -ms-transform: rotate(0.75turn);
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: rotate(0.75turn);
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  -ms-transform: rotate(0);
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: rotate(0);
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  -ms-transform: rotate(-0.75turn);
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake, .spin-cw, .spin-ccw, .wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

fieldset[disabled] .multiselect {
  pointer-events: none; }

.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block; }

.multiselect__spinner:after, .multiselect__spinner:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid transparent;
  border-top-color: #41b883;
  box-shadow: 0 0 0 1px transparent; }

.multiselect__spinner:before {
  animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite; }

.multiselect__spinner:after {
  animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite; }

.multiselect__loading-enter-active, .multiselect__loading-leave-active {
  transition: opacity .4s ease-in-out;
  opacity: 1; }

.multiselect__loading-enter, .multiselect__loading-leave-active {
  opacity: 0; }

.multiselect, .multiselect__input, .multiselect__single {
  font-family: inherit;
  font-size: 16px;
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e; }

.multiselect * {
  box-sizing: border-box; }

.multiselect:focus {
  outline: none; }

.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
  opacity: .6; }

.multiselect--active {
  z-index: 50; }

.multiselect--active:not(.multiselect--above) .multiselect__current, .multiselect--active:not(.multiselect--above) .multiselect__input, .multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.multiselect--active .multiselect__select {
  transform: rotate(180deg); }

.multiselect--above.multiselect--active .multiselect__current, .multiselect--above.multiselect--active .multiselect__input, .multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.multiselect__input, .multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 0 0 0 5px;
  width: 100%;
  transition: border .1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top; }

.multiselect__input:-ms-input-placeholder {
  color: #35495e; }

.multiselect__input::placeholder {
  color: #35495e; }

.multiselect__tag ~ .multiselect__input, .multiselect__tag ~ .multiselect__single {
  width: auto; }

.multiselect__input:hover, .multiselect__single:hover {
  border-color: #cfcfcf; }

.multiselect__input:focus, .multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none; }

.multiselect__single {
  padding-left: 5px;
  margin-bottom: 8px; }

.multiselect__tags-wrap {
  display: inline; }

.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px; }

.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #41b883;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis; }

.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: normal;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all .2s ease;
  border-radius: 5px; }

.multiselect__tag-icon:after {
  content: "\D7";
  color: #266d4d;
  font-size: 14px; }

.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
  background: #369a6e; }

.multiselect__tag-icon:focus:after, .multiselect__tag-icon:hover:after {
  color: #fff; }

.multiselect__current {
  min-height: 40px;
  overflow: hidden;
  padding: 8px 30px 0 12px;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #e8e8e8; }

.multiselect__current, .multiselect__select {
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer; }

.multiselect__select {
  position: absolute;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  transition: transform .2s ease; }

.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ""; }

.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px; }

.multiselect--active .multiselect__placeholder {
  display: none; }

.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch; }

.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top; }

.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8; }

.multiselect__content::webkit-scrollbar {
  display: none; }

.multiselect__element {
  display: block; }

.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap; }

.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px; }

.multiselect__option--highlight {
  background: #41b883;
  outline: none;
  color: #fff; }

.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #41b883;
  color: #fff; }

.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
  font-weight: 700; }

.multiselect__option--selected:after {
  content: attr(data-selected);
  color: silver; }

.multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff; }

.multiselect__option--selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff; }

.multiselect--disabled .multiselect__current, .multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6; }

.multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
  cursor: text;
  pointer-events: none; }

.multiselect__option--group {
  background: #ededed;
  color: #35495e; }

.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #fff; }

.multiselect__option--group.multiselect__option--highlight:after {
  background: #35495e; }

.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede; }

.multiselect__option--group-selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff; }

.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff; }

.multiselect-enter-active, .multiselect-leave-active {
  transition: all .15s ease; }

.multiselect-enter, .multiselect-leave-active {
  opacity: 0; }

.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top; }

[dir=rtl] .multiselect {
  text-align: right; }

[dir=rtl] .multiselect__select {
  right: auto;
  left: 1px; }

[dir=rtl] .multiselect__tags {
  padding: 8px 8px 0 40px; }

[dir=rtl] .multiselect__content {
  text-align: right; }

[dir=rtl] .multiselect__option:after {
  right: auto;
  left: 0; }

[dir=rtl] .multiselect__clear {
  right: auto;
  left: 12px; }

[dir=rtl] .multiselect__spinner {
  right: auto;
  left: 1px; }

@keyframes spinning {
  0% {
    transform: rotate(0); }
  to {
    transform: rotate(2turn); } }

*, *::after, *::before {
  box-sizing: border-box; }

a {
  outline: none; }

html {
  font-size: 62.5%;
  height: 100%;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

p, h1, h2, h3, h4, h5, h6, ul {
  padding: 0;
  margin: 0; }

a {
  background-color: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none; }

label, button {
  cursor: pointer; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

ol.numeration, ul.numeration {
  list-style-type: decimal;
  margin: 0 0 20px 22px; }
  ol.numeration li, ul.numeration li {
    color: #000;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.6;
    margin-bottom: 5px; }
    ol.numeration li strong, ul.numeration li strong {
      font-weight: 700; }

strong {
  font-weight: bold; }

.cuSelect {
  z-index: 1; }

.text-center {
  text-align: center !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

a {
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }

ul.link-holder li {
  display: inline-block;
  vertical-align: middle; }
  ul.link-holder li + li {
    margin-left: 30px; }

button {
  border: none; }

a,
area,
button,
[role="button"],
input:not([type=range]),
label,
select,
summary,
textarea {
  touch-action: manipulation; }

body {
  min-height: 100%;
  position: relative;
  color: #dedede;
  font-size: 1.6rem;
  font-family: 'Gotham Pro', sans-serif;
  font-weight: normal; }

a {
  color: inherit;
  display: inline-block;
  text-decoration: none; }
  a:focus {
    outline: none; }
  a:hover, a:active {
    text-decoration: none; }

hr.line {
  background: rgba(74, 74, 74, 0.3);
  margin: 0;
  padding: 0;
  height: 2px;
  border-left: 15px solid #4A4A4A;
  border-right: 15px solid #4A4A4A; }

address {
  font-style: normal;
  font-size: 1.8rem;
  font-weight: 500;
  color: #696b6f; }

img {
  pointer-events: none;
  max-width: 100%;
  display: block; }

button {
  margin: 0;
  padding: 0;
  background-color: unset; }
  button:focus {
    outline: none; }

section h2 {
  margin-bottom: 20px; }

section + section {
  margin-top: 23px; }

@media only screen and (min-width: 993px) {
  section + section {
    margin-top: 30px; } }

@media only screen and (min-width: 1280px) {
  section h2 {
    margin-bottom: 34px; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: none;
  letter-spacing: 0;
  font-weight: 500;
  transform: translateX(-2px);
  line-height: 1.42; }

.t1 {
  font-size: 3rem;
  line-height: 1; }

.t2 {
  font-size: 2.4rem; }

@media only screen and (min-width: 993px) {
  .t1 {
    font-size: 3.6rem; }
  .t2 {
    font-size: 3rem; } }

.icon-search-ico {
  font-size: 1.8rem; }

.icon-cart-ico {
  font-size: 2.2rem; }

.icon-cabinet-ico {
  font-size: 2.2rem; }

.icon-mirrolab-ico {
  font-size: 1.2rem;
  color: #fff;
  display: inline-block;
  transform: translateY(-2px); }

.icon-filter-ico {
  font-size: 2.4rem;
  color: #0758ff; }
  .t-bonus .icon-filter-ico {
    color: #daba57; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon/icomoon.eot");
  src: url("../fonts/icomoon/icomoon.eot") format("embedded-opentype"), url("../fonts/icomoon/icomoon.woff2") format("woff2"), url("../fonts/icomoon/icomoon.ttf") format("truetype"), url("../fonts/icomoon/icomoon.woff") format("woff"), url("../fonts/icomoon/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Gotham Pro';
  src: url("../fonts/nolocalgothem/GothamPro.eot");
  src: url("../fonts/nolocalgothem/GothamPro.eot") format("embedded-opentype"), url("../fonts/nolocalgothem/GothamPro.woff2") format("woff2"), url("../fonts/nolocalgothem/GothamPro.woff") format("woff"), url("../fonts/nolocalgothem/GothamPro.ttf") format("truetype"), url("../fonts/nolocalgothem/GothamPro.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Gotham Pro';
  src: url("../fonts/nolocalgothem/GothamPro-Medium.eot");
  src: url("../fonts/nolocalgothem/GothamPro-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/nolocalgothem/GothamPro-Medium.woff2") format("woff2"), url("../fonts/nolocalgothem/GothamPro-Medium.woff") format("woff"), url("../fonts/nolocalgothem/GothamPro-Medium.ttf") format("truetype"), url("../fonts/nolocalgothem/GothamPro-Medium.svg") format("svg");
  font-weight: 500;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-logo-ico:before {
  content: "\e910"; }

.icon-more-ico:before {
  content: "\e917"; }

.icon-loc:before {
  content: "\e916"; }

.icon-info:before {
  content: "\e915"; }

.icon-arrow-left:before {
  content: "\e914"; }

.icon-monitor-ico:before {
  content: "\e913"; }

.icon-mirrolab-ico:before {
  content: "\e923"; }

.icon-print-ico:before {
  content: "\e900"; }

.icon-micro-ico:before {
  content: "\e901"; }

.icon-laptop-ico:before {
  content: "\e902"; }

.icon-phone-ico:before {
  content: "\e903"; }

.icon-pc-ico:before {
  content: "\e904"; }

.icon-usb-ico:before {
  content: "\e905"; }

.icon-sew-ico:before {
  content: "\e906"; }

.icon-radio-ico:before {
  content: "\e907"; }

.icon-bonus-ico:before {
  content: "\e908"; }

.icon-arrow-ico:before {
  content: "\e909"; }

.icon-cabinet-ico:before {
  content: "\e90a"; }

.icon-cart-ico:before {
  content: "\e90b"; }

.icon-tick-ico:before {
  content: "\e90c"; }

.icon-eyecross-ico:before {
  content: "\e90d"; }

.icon-eye-ico:before {
  content: "\e90e"; }

.icon-filter-ico:before {
  content: "\e90f"; }

.icon-menu-ico:before {
  content: "\e911"; }

.icon-search-ico:before {
  content: "\e912"; }

@media (max-width: 600px) {
  .u-hide-small {
    display: none !important; } }

@media (max-width: 992px) and (min-width: 601px) {
  .u-hide-medium {
    display: none !important; } }

@media (min-width: 993px) {
  .u-hide-large {
    display: none !important; } }

@media (min-width: 768px) {
  .u-show-mob {
    display: none !important; } }

@media (max-width: 767px) {
  .u-hide-mob {
    display: none !important; } }

.u-hide {
  display: none !important; }

.u-text-center {
  text-align: center; }

.u-text-right {
  text-align: right !important; }

.u-nospace {
  padding-top: 0 !important; }

.u-spacing {
  padding-left: 17px;
  padding-right: 17px; }

.u-special-center {
  text-align: center; }

.u-load {
  position: relative;
  min-height: 120px; }
  .u-load:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    visibility: hidden;
    opacity: 0;
    z-index: 10;
    transition: .2s; }
  .u-load:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1em;
    height: 1em;
    margin: -.5em 0 0 -.5em;
    font-size: 40px;
    color: #0758ff;
    border-radius: 50%;
    z-index: 11;
    visibility: hidden;
    opacity: 0;
    transition: .2s; }
  .u-load.is-loading:before, .u-load.is-loading:after {
    visibility: visible;
    opacity: 1; }
  .u-load.is-loading:after {
    animation: load6 1.7s infinite ease, round 1.7s infinite ease; }

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em; }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em; }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em; }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; } }

@keyframes round {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@media only screen and (min-width: 1025px) {
  .u-special-center {
    margin-left: -20px;
    padding-right: 20px; } }

.l-container, .l-cabinet-container,
.l-item-container {
  padding-left: 17px;
  padding-right: 17px; }

@media only screen and (min-width: 1025px) {
  .l-container, .l-cabinet-container,
  .l-item-container {
    padding-left: 40px;
    padding-right: 40px; } }

@media only screen and (min-width: 1315px) {
  .l-container, .l-cabinet-container,
  .l-item-container {
    max-width: 1235px;
    margin-left: auto;
    margin-right: auto; } }

@media only screen and (min-width: 1315px) {
  .l-cabinet-container,
  .l-item-container {
    max-width: unset; } }

@media only screen and (min-width: 1367px) {
  .l-cabinet-container {
    padding-right: 65px;
    padding-left: 65px; } }

@media only screen and (min-width: 1342px) {
  .l-item-container {
    max-width: 1342px;
    padding-left: 33px;
    padding-right: 33px;
    margin-left: auto;
    margin-right: auto; } }

.l-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-bottom: 29px;
  padding-top: 76px; }
  .l-contacts .l-footer {
    padding-top: 29px; }

@media only screen and (min-width: 993px) {
  .l-footer {
    padding-bottom: 50px;
    padding-top: 50px; } }

@media only screen and (min-width: 1280px) {
  .l-footer {
    padding-top: 40px;
    padding-bottom: 45px; } }

.l-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  transition: .3s; }
  .l-header.is-unpinned {
    transform: translateY(-130%); }

html {
  height: 100%; }

body {
  height: 100%;
  min-height: 100%;
  background: radial-gradient(circle, rgba(34, 34, 40, 0.5) 30%, rgba(0, 0, 0, 0.75) 100%, transparent);
  background-attachment: fixed;
  background-color: #191919;
  -webkit-overflow-scrolling: touch; }

.l-site {
  height: 100%;
  overflow: auto; }

.l-wrapper {
  min-height: 100%;
  padding-bottom: 193px;
  position: relative;
  padding-top: 46px; }
  .l-contacts .l-wrapper {
    padding-bottom: 116px; }

.l-row {
  font-size: 0;
  font-size: 0;
  text-align: justify;
  text-justify: newspaper; }
  .l-row li {
    display: inline-block;
    vertical-align: middle; }
  .l-row:after {
    height: 1px;
    margin-top: -1px;
    content: "";
    display: inline-block;
    width: 100%; }

.l-column {
  font-size: 0;
  display: inline-block; }

@media only screen and (min-width: 1280px) {
  .l-row {
    margin-top: -12px; } }

@media only screen and (min-width: 993px) {
  .l-wrapper {
    padding-bottom: 117px; }
    .l-contacts .l-wrapper {
      padding-bottom: 0; } }

@media only screen and (min-width: 1024px) {
  .l-wrapper {
    display: flex;
    flex-direction: column; } }

@media only screen and (min-width: 1280px) {
  .l-wrapper {
    padding-bottom: 77px;
    padding-top: 72px; } }

@media only screen and (min-width: 1440px) {
  .l-wrapper {
    display: flex;
    flex-direction: column; } }

@media only screen and (min-width: 1024px) {
  .l-main {
    margin-top: auto;
    margin-bottom: auto; }
    .l-main--top {
      margin-top: 0; } }

.l-page {
  padding-top: 22px;
  padding-bottom: 20px; }
  .l-contacts .l-page {
    padding-bottom: 0; }

@media only screen and (min-width: 768px) {
  .l-page {
    padding-top: 50px; } }

@media only screen and (min-width: 993px) {
  .l-page {
    padding-bottom: 50px; } }

.l-item-column {
  max-width: 500px; }

@media only screen and (min-width: 993px) {
  .l-item-column {
    max-width: unset;
    width: 50%;
    display: inline-block;
    vertical-align: top; } }

@media only screen and (min-width: 993px) {
  .l-item-row {
    font-size: 0; } }

@media only screen and (min-width: 1025px) {
  .l-item-row {
    padding-left: 13px;
    padding-right: 13px; } }

@media only screen and (min-width: 1280px) {
  .l-item-row {
    padding-left: 25px;
    padding-right: 25px; } }

@media only screen and (min-width: 1342px) {
  .l-item-row {
    max-width: 1342px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 58px;
    padding-right: 58px; } }

.s-props li {
  line-height: 1;
  display: none; }
  .s-props li:first-child, .s-props li:nth-child(2) {
    display: block; }
  .s-props li + li {
    margin-top: 4px; }

@media only screen and (min-width: 678px) {
  .s-props li {
    font-size: 1.6rem; } }

@media only screen and (min-width: 993px) {
  .s-props li {
    display: inline;
    line-height: 1.5; }
    .s-props li:first-child, .s-props li:nth-child(2) {
      display: inline; }
    .s-props li:after {
      content: '\0002f';
      display: inline-block;
      padding-left: 5px;
      padding-right: 5px; }
    .s-props li:last-child:after {
      content: none; } }

.s-item section + section {
  margin-top: 45px; }

@media only screen and (min-width: 1280px) {
  .s-item section + section {
    margin-top: 60px; } }

@media only screen and (min-width: 1440px) {
  .s-item section + section {
    margin-top: 80px; } }

.s-brief ul {
  font-size: initial; }

.s-brief li {
  font-size: 1.6rem;
  font-weight: normal;
  color: #696b6f;
  display: inline; }
  .s-brief li:after {
    content: '\0002f';
    display: inline-block; }
  .s-brief li:last-child:after {
    content: none; }

@media only screen and (min-width: 1280px) {
  .s-brief li {
    font-size: 1.8rem;
    line-height: 1.33;
    font-weight: 500; } }

.s-menu ul {
  padding-bottom: 35px; }

.s-menu li {
  color: #fff;
  font-size: 2.4rem;
  font-weight: 500; }
  .s-menu li.t-bonus {
    color: #daba57; }
  .s-menu li:first-child {
    padding-top: 19px; }

.s-menu li > a {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 13px;
  padding-bottom: 13px;
  background: unset;
  line-height: 1.3; }

.s-menu li .has-icon {
  position: relative;
  padding-left: 57px; }
  .s-menu li .has-icon [class^='icon'] {
    font-size: 2.4rem;
    width: 57px;
    height: 31px;
    position: absolute;
    display: block;
    left: 0;
    text-align: center;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%); }

.s-menu-nested.is-drilldown-submenu {
  background-color: #0758ff; }
  .s-menu-nested.is-drilldown-submenu li {
    font-size: 1.8rem; }
  .s-menu-nested.is-drilldown-submenu li > a {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 34px; }
    .s-menu-nested.is-drilldown-submenu li > a.has-icon {
      padding-left: 57px; }
    .s-menu-nested.is-drilldown-submenu li > a.is-back {
      font-size: 2.4rem;
      padding-top: 13px; }
      .s-menu-nested.is-drilldown-submenu li > a.is-back a {
        padding-bottom: 13px; }

.s-menu-subnested.is-drilldown-submenu {
  background-color: #1b1b1e; }
  .s-menu-subnested.is-drilldown-submenu li {
    font-size: 1.8rem; }
  .s-menu-subnested.is-drilldown-submenu li > a {
    padding-left: 38px; }
    .s-menu-subnested.is-drilldown-submenu li > a.is-back {
      font-size: 2.4rem;
      padding-top: 13px; }
      .s-menu-subnested.is-drilldown-submenu li > a.is-back a {
        padding-bottom: 13px; }

.s-chips li {
  color: #696b6f;
  font-size: 1.6rem;
  font-weight: normal;
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 5px;
  margin-bottom: 5px; }
  .s-chips li.is-active {
    color: #dedede; }
    .s-chips li.is-active a,
    .s-chips li.is-active button {
      background-color: #35353e;
      pointer-events: none; }

.s-chips a,
.s-chips button {
  font-size: inherit;
  color: inherit;
  padding-top: 9px;
  padding-bottom: 7.5px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 17px;
  border: 2px solid #35353e;
  line-height: .9;
  height: 100%; }
  .s-chips a:disabled,
  .s-chips button:disabled {
    cursor: not-allowed; }

@media only screen and (min-width: 1280px) {
  .s-chips a:hover:not(:disabled),
  .s-chips button:hover:not(:disabled) {
    background-color: #1b1b1e; } }

.s-breadcrumbs li {
  font-size: 1.6rem;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  color: #696b6f; }
  .s-breadcrumbs li:before {
    display: inline-block;
    content: '\0002f'; }
  .s-breadcrumbs li:first-child:before {
    content: ''; }
  .s-breadcrumbs li:first-child a {
    padding-left: 0; }
  .s-breadcrumbs li.is-active {
    color: #dedede; }
    .s-breadcrumbs li.is-active a {
      pointer-events: none; }
  .s-breadcrumbs li a:first-letter {
    text-transform: uppercase; }

.s-breadcrumbs a {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  padding-left: 5px;
  display: inline-block; }

.t-bonus .s-breadcrumbs li {
  color: rgba(218, 186, 87, 0.5); }
  .t-bonus .s-breadcrumbs li.is-active {
    color: #daba57; }

@media only screen and (min-width: 993px) {
  .s-breadcrumbs li {
    line-height: 1;
    font-size: 2rem; } }

@media only screen and (min-width: 1280px) {
  .s-breadcrumbs li {
    font-size: 3rem; }
  .s-breadcrumbs a {
    padding-left: 10px;
    padding-right: 8px; } }

.s-notice a {
  font-size: inherite;
  color: #696b6f; }

.drilldown a {
  background: initial; }

.drilldown .is-drilldown-submenu a {
  padding: initial; }

.is-drilldown,
.is-drilldown-submenu {
  min-height: 100vh; }

.drilldown .is-drilldown-submenu-parent > a:after {
  content: "";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  border: none;
  position: relative;
  font-size: 1.3rem;
  right: initial;
  width: unset;
  height: unset;
  top: unset;
  margin-left: 5px;
  transform: rotate(-90deg); }

.drilldown .is-drilldown-submenu-parent .t-bonus > a:after {
  color: #daba57; }

.drilldown .js-drilldown-back > a {
  padding-left: 38px;
  position: relative; }
  .drilldown .js-drilldown-back > a:before {
    content: "";
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    left: 0;
    height: unset;
    width: 38px;
    text-align: center;
    display: block;
    border: none;
    font-size: 1.3rem;
    right: initial;
    transform: rotate(90deg);
    margin: 0;
    margin-top: 3px; }

.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  outline: none !important; }
  .slick-list:focus {
    outline: none; }
  .slick-loading .slick-list {
    background: #fff url("/static-images/ajax-loader.gif") center center no-repeat; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  outline: none !important; }
  .slick-track:before, .slick-track:after {
    display: table;
    content: ''; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  outline: none !important; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-dots {
  font-size: 0;
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  text-align: center; }
  .slick-dots li {
    display: inline-block;
    margin: 0 5px;
    vertical-align: middle; }
    .slick-dots li.slick-active button {
      background: #fff; }
    .slick-dots li button {
      position: relative;
      padding: 0;
      outline: 0;
      background-color: transparent; }

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  cursor: default;
  opacity: .6; }

.slick-vertical .slick-prev,
.slick-vertical .slick-next {
  z-index: 5;
  top: initial;
  right: initial; }

.slick-vertical .slick-next {
  bottom: 0;
  text-indent: 1px; }
  .slick-vertical .slick-next span {
    top: 1px; }

.slick-vertical .slick-prev {
  top: 0;
  left: 50%; }

.slick-slider {
  margin-bottom: 0; }

.c-drop {
  position: relative;
  white-space: normal; }
  .c-drop .c-burger {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 72px; }
  .c-drop .t-bonus a {
    color: rgba(218, 186, 87, 0.5); }
    .c-drop .t-bonus a:hover {
      color: #daba57; }
  .c-drop a {
    font-size: 1.8rem;
    background-color: #2c2c33;
    color: rgba(255, 255, 255, 0.5);
    font-weight: normal;
    cursor: pointer; }
    .c-drop a.has-icon {
      padding-left: 57px;
      position: relative; }
      .c-drop a.has-icon [class^='icon'] {
        font-size: 2.4rem;
        width: 57px;
        height: 31px;
        position: absolute;
        display: block;
        left: 0;
        text-align: center;
        top: 50%;
        bottom: auto;
        transform: translateY(-50%); }
  .c-drop__nested {
    top: 100%;
    left: 0; }
    .c-drop__nested li {
      display: block;
      position: relative; }
      .c-drop__nested li a {
        display: block;
        width: 245px;
        line-height: 1;
        padding-top: 21px;
        padding-bottom: 20px;
        padding-right: 30px;
        text-align: left; }
    .c-drop__nested .is-hover > a {
      color: #fff;
      background-color: #1e1f24; }
  .c-drop__subnested {
    top: 0;
    left: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #1e1f24; }
    .c-drop__subnested li a {
      padding: 10px 31px 9px;
      background-color: #1e1f24; }
      .c-drop__subnested li a:hover {
        color: #fff;
        background-color: #1e1f24; }
    .is-hover .c-drop__subnested, .c-drop__subnested:hover {
      opacity: 1;
      visibility: visible; }
  .c-drop__nested, .c-drop__subnested {
    position: absolute;
    margin-left: 0;
    visibility: hidden;
    opacity: 0; }
  .c-drop:hover .c-burger {
    color: #fff;
    background-color: #1e1f24; }
  .c-drop:hover .c-drop__nested {
    opacity: 1;
    visibility: visible; }

.icon-logo-ico {
  font-size: 2rem;
  display: inline-block;
  margin-top: 2px; }

.c-logo {
  height: 46px;
  background-color: #0758ff;
  padding-left: 19px;
  padding-right: 25px;
  display: inline-block;
  color: #fff;
  font-size: 0;
  text-align: center; }
  .c-logo .help {
    display: inline-block;
    vertical-align: middle; }
  .c-logo:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 0.1%;
    height: 100%;
    margin-left: -.1%; }
  .t-index .c-logo {
    pointer-events: none; }

@media only screen and (min-width: 1280px) {
  .c-logo {
    height: 72px;
    padding-left: 17px;
    padding-right: 17px;
    transition: all 0.2s ease; }
    .c-logo:hover {
      transition: none; }
    .c-logo:hover {
      background-color: #3a7aff; } }

.t-guest .c-logo {
  padding-left: 19px;
  padding-right: 25px; }

@media only screen and (min-width: 1440px) {
  .t-guest .c-logo {
    padding-left: 34px;
    padding-right: 34px; } }

@media only screen and (min-width: 1280px) {
  .t-guest .c-logo {
    padding-right: 26px;
    padding-left: 23px; } }

.t-logged-in .c-logo {
  padding-left: 0;
  padding-right: 0; }

@media only screen and (min-width: 360px) {
  .t-logged-in .c-logo {
    padding-right: 17px; } }

@media only screen and (min-width: 480px) {
  .t-logged-in .c-logo {
    padding-right: 34px; } }

@media only screen and (min-width: 1280px) {
  .t-logged-in .c-logo {
    padding-right: 26px;
    padding-left: 23px; } }

@media only screen and (min-width: 1440px) {
  .t-logged-in .c-logo {
    padding-left: 34px;
    padding-right: 34px; } }

.c-header {
  position: relative;
  height: 46px;
  color: #fff;
  background-color: #0758ff;
  z-index: 11; }
  .c-header .c-header__phones {
    padding-left: 17px;
    padding-right: 17px; }
  .c-header .c-header__currency {
    padding-left: 34px;
    padding-right: 17px; }
  .c-header .c-header__button {
    padding-right: 19px; }
  .c-header .c-header__column {
    display: inline-block;
    vertical-align: middle;
    font-size: 0; }
  .c-header .c-action__label {
    display: none; }
  .t-guest .c-header {
    background-color: unset; }
  .c-header .t-visivile {
    display: none; }
  .c-header .has-burger-hoverable {
    display: none; }

@media only screen and (min-width: 1025px) {
  .c-header .t-visivile {
    display: inline-block; } }

@media only screen and (min-width: 1280px) {
  .c-header {
    color: #0758ff;
    height: 72px;
    padding-bottom: 12px;
    padding-top: 12px;
    background-color: #222227; }
    .c-header .c-header__button {
      padding-right: 26px; } }

@media only screen and (min-width: 1367px) {
  .c-header .has-burger-hoverable {
    display: inline-block; }
  .c-header .has-burger-clickable {
    display: none; } }

@media only screen and (min-width: 1440px) {
  .c-header .c-header__inner {
    padding-right: 17px; }
  .c-header .c-header__button {
    padding-right: 34px; } }

.c-action {
  display: block;
  padding-right: 17px;
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative; }
  .c-action .c-action__box {
    height: 15px;
    width: 22px;
    position: relative;
    display: table; }
  .c-action [class^='icon'] {
    display: table-cell;
    vertical-align: middle; }

@media only screen and (min-width: 480px) {
  .c-action {
    padding-left: 17px; } }

@media only screen and (min-width: 1280px) {
  .c-action {
    padding-top: 25px;
    padding-bottom: 25px; }
    .c-action:hover {
      background-color: #1E2024; }
    .c-action .c-action__box {
      display: inline-block;
      vertical-align: middle;
      width: unset;
      height: unset; }
    .c-action .c-action__label {
      display: inline-block;
      vertical-align: middle;
      font-weight: normal;
      font-size: 1.6rem;
      color: rgba(255, 255, 255, 0.5);
      margin-left: 10px;
      line-height: 1; } }

.c-footer {
  text-align: center; }
  .c-footer .c-footer__inner {
    padding-left: 17px;
    padding-right: 17px; }
  .c-footer .c-footer__field {
    font-size: 1.6rem;
    font-weight: normal;
    color: #696b6f; }
    .c-footer .c-footer__field + .c-footer__field {
      margin-top: 30px; }
  .c-footer .c-footer__column + .c-footer__column {
    margin-top: 8px; }
  .c-footer .c-footer__label {
    line-height: 1.7rem; }
  .c-footer .c-footer__label,
  .c-footer .c-footer__anchor {
    display: inline-block;
    vertical-align: middle; }
  .c-menu .c-footer .c-footer__inner {
    padding-left: 0;
    padding-right: 0;
    padding-top: 25px;
    padding-bottom: 25px; }
  .c-menu .c-footer .c-footer__row {
    flex-direction: column; }

@media only screen and (min-width: 480px) {
  .c-menu .c-footer {
    text-align: left; }
    .c-menu .c-footer .c-footer__field {
      font-weight: normal; }
    .c-menu .c-footer .c-footer__inner {
      display: inline-block;
      padding-left: 20px;
      text-align: center; }
    .c-menu .c-footer .c-footer__left,
    .c-menu .c-footer .c-footer__right {
      width: 100%;
      text-align: center; }
    .c-menu .c-footer .c-footer__left {
      flex-direction: column; }
      .c-menu .c-footer .c-footer__left .c-footer__field {
        width: 100%;
        display: block; }
        .c-menu .c-footer .c-footer__left .c-footer__field + .c-footer__field {
          margin-top: 30px; } }

@media only screen and (min-width: 993px) {
  .l-contacts .c-footer .c-footer__left .c-footer__field {
    width: 100%; }
  .c-footer .c-footer__row {
    font-size: 0;
    margin-left: -17px;
    margin-right: -17px;
    display: flex;
    flex-direction: row; }
  .c-footer .c-footer__left,
  .c-footer .c-footer__right {
    width: 50%;
    padding-left: 17px;
    padding-right: 17px; }
    .c-footer .c-footer__left .c-footer__field + .c-footer__field,
    .c-footer .c-footer__right .c-footer__field + .c-footer__field {
      margin-top: 0; }
  .c-footer .c-footer__left {
    text-align: left;
    display: flex;
    flex-direction: row-reverse; }
    .c-footer .c-footer__left .c-footer__field {
      width: 50%; }
      .c-footer .c-footer__left .c-footer__field + .c-footer__field {
        padding-right: 17px; }
  .c-footer .c-footer__right {
    text-align: right; }
  .c-footer .c-footer__left + .c-footer__right {
    margin-top: 0; } }

@media only screen and (min-width: 1280px) {
  .c-footer .c-footer__inner {
    padding-left: 40px;
    padding-right: 40px; } }

@media only screen and (min-width: 1380px) {
  .c-footer .c-footer__inner {
    max-width: 1380px;
    margin-left: auto;
    margin-right: auto; } }

.c-rights a {
  display: inline; }

.c-menu__rights .c-rights {
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 40px;
  padding-bottom: 40px; }
  .c-menu__rights .c-rights .c-rights__item {
    font-size: 1.6rem;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.5); }

.c-search {
  height: 100%;
  transition: width .3s; }
  .c-search .c-search__close {
    display: none; }
  .c-search .c-search__submit {
    display: none;
    pointer-events: none; }
  .c-search .c-search__button {
    -webkit-backface-visibility: hidden;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 17px;
    padding-right: 8.5px; }
  .c-search .c-search__controls {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 80%; }
    .c-search .c-search__controls input {
      display: none;
      height: 33px;
      padding-left: 20px;
      padding-right: 45px;
      padding-top: 12px;
      padding-bottom: 10.5px;
      background-color: #0748cd;
      border: none;
      outline: 0;
      font-size: 1.6rem;
      font-weight: normal;
      text-align: left;
      width: 100%;
      border: none;
      transition: all .1s linear 0s;
      border-radius: 22px; }
      .c-search .c-search__controls input::placeholder {
        color: rgba(255, 255, 255, 0.5);
        font-weight: normal; }
      .c-search .c-search__controls input:focus {
        color: #fff;
        text-transform: none; }
        .c-search .c-search__controls input:focus::placeholder {
          color: transparent; }
      .c-search .c-search__controls input.placeholder {
        color: rgba(255, 255, 255, 0.5);
        font-weight: normal; }
      .c-search .c-search__controls input:-moz-placeholder {
        color: rgba(255, 255, 255, 0.5);
        font-weight: normal; }
      .c-search .c-search__controls input::-moz-placeholder {
        color: rgba(255, 255, 255, 0.5);
        font-weight: normal; }
      .c-search .c-search__controls input:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.5);
        font-weight: normal; }
      .c-search .c-search__controls input::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.5);
        font-weight: normal; }
    .c-search .c-search__controls input[type="search"] {
      -webkit-appearance: none; }
  .c-search.is-open {
    width: 100%;
    position: absolute;
    z-index: 10;
    background-color: #0758ff;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
    .c-search.is-open .c-search__controls {
      padding-left: 17px; }
    .c-search.is-open .c-search__sign {
      position: absolute;
      top: 0;
      right: 0px;
      padding-right: 17px; }
    .c-search.is-open .c-search__close {
      display: inline-block; }
    .c-search.is-open .c-search__button {
      display: none; }
    .c-search.is-open .c-search__submit {
      display: block;
      pointer-events: auto;
      height: 33px; }
    .c-search.is-open input {
      display: inline-block; }

@media only screen and (min-width: 480px) {
  .c-search .c-search__button {
    padding-right: 17px; } }

@media only screen and (min-width: 678px) {
  .c-search .c-search__form {
    padding-left: 17px; }
  .c-search .c-search__controls {
    min-width: 340px; }
    .c-search .c-search__controls input {
      display: block;
      width: 100%; }
    .c-search .c-search__controls .c-search__submit {
      height: 33px;
      pointer-events: auto;
      position: absolute;
      display: block;
      padding-right: 17px;
      right: 0;
      top: 50%;
      transform: translateY(-50%); }
    .c-search .c-search__controls .c-search__button {
      display: none; } }

@media only screen and (min-width: 1280px) {
  .c-search.is-open {
    background-color: #222327; }
    .c-search.is-open .c-search__form {
      padding-top: 13px;
      padding-bottom: 13px; }
  .c-search .c-search__form {
    padding-right: 17px; }
  .c-search .c-search__close:before, .c-search .c-search__close:after {
    background-color: #0758ff; }
  .c-search .c-search__controls input {
    background-color: #39393d;
    height: 43px; } }

.c-close {
  padding-left: 17px;
  padding-right: 17px;
  height: 46px;
  position: relative;
  vertical-align: middle;
  width: 20%; }
  .c-close:before {
    transform: translateX(-50%) rotate(45deg); }
  .c-close:after {
    transform: translateX(-50%) rotate(-45deg); }
  .c-close:before, .c-close:after {
    content: '';
    width: 22px;
    height: 1.5px;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 4px;
    background-color: #fff; }

.c-burger {
  display: block;
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 15px;
  padding-bottom: 15px; }
  .c-burger .c-burger__box {
    height: 16px;
    width: 22px;
    display: inline-block;
    vertical-align: middle;
    position: relative; }
  .c-burger .c-burger__label {
    display: inline-block;
    vertical-align: middle;
    font-weight: normal; }
  .c-burger .c-burger__inner {
    display: block;
    position: absolute;
    width: 22px;
    bottom: 0;
    top: 0;
    margin: auto;
    background-color: #fff;
    height: 1.5px;
    transition: .3s;
    will-change: transform; }
    .c-burger .c-burger__inner:after, .c-burger .c-burger__inner:before {
      content: '';
      position: absolute;
      display: block;
      width: 22px;
      background-color: #fff;
      height: 1.5px; }
    .c-burger .c-burger__inner:after {
      bottom: -6px; }
    .c-burger .c-burger__inner:before {
      top: -6px; }
  .c-burger.is-open .c-burger__inner {
    transition: .3s;
    transform: translate3d(0, 0, 0) rotate(45deg); }
    .c-burger.is-open .c-burger__inner:before {
      opacity: 0; }
    .c-burger.is-open .c-burger__inner:after {
      transform: translate3d(0, -6px, 0) rotate(-90deg); }

@media only screen and (min-width: 1280px) {
  .c-burger {
    height: 72px;
    background-color: #2c2c33; }
    .c-burger .c-burger__inner {
      background-color: rgba(255, 255, 255, 0.5); }
      .c-burger .c-burger__inner:after, .c-burger .c-burger__inner:before {
        background-color: rgba(255, 255, 255, 0.5); } }

.c-badge {
  position: absolute;
  top: -11px;
  right: -10px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background-color: #dedede;
  width: 22px;
  height: 22px; }
  .c-badge .c-badge__anchor {
    color: #0758ff;
    display: inline-block;
    transfrom: translateY(3px);
    font-size: 12px;
    line-height: 23px; }

.c-currency {
  display: flex;
  align-items: center; }
  .c-currency .c-currency__label {
    display: none; }
  .c-currency .c-currency__item {
    position: relative;
    text-align: center;
    border-radius: 50%;
    height: 34px;
    width: 34px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .c-currency .c-currency__item.is-active {
      background-color: #fff;
      color: #0758ff; }
    .c-currency .c-currency__item + .c-currency__item {
      margin-left: 7px; }
  .c-currency .c-currency__sign {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.6rem;
    color: inherit;
    font-family: 'Gotham Pro', sans-serif; }
  .c-menu .c-currency .c-currency__label {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.8rem;
    color: #fff;
    font-weight: 500;
    margin-right: 24px; }
  .c-menu .c-currency .c-currency__item.is-active {
    background-color: #fff;
    color: #0758ff; }

@media only screen and (min-width: 1280px) {
  .c-currency .c-currency__item {
    cursor: pointer; }
    .c-currency .c-currency__item:hover {
      background-color: rgba(7, 88, 255, 0.7); }
    .c-currency .c-currency__item.is-active {
      background-color: #0758ff;
      color: #fff;
      pointer-events: none; } }

.c-phones {
  font-size: 0; }
  .c-phones .c-phones__item {
    font-size: 0;
    display: block;
    margin-top: 4px; }
  .c-phones .c-phones__link {
    font-size: 1.2rem; }
  .c-phones .c-phones__label {
    display: none; }
  .c-header__phones .c-phones .c-phones__link {
    font-weight: normal; }
  .c-header__phones .c-phones .c-phones__label {
    font-weight: normal; }
  .c-menu__phones .c-phones .c-phones__label {
    font-size: 1.6rem;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.5);
    display: block; }
  .c-menu__phones .c-phones .c-phones__item {
    font-size: 1.8rem;
    font-weight: 500;
    margin-left: -17px;
    margin-right: -17px; }
  .c-menu__phones .c-phones .c-phones__link {
    padding-left: 17px;
    padding-right: 17px;
    font-size: inherit;
    font-weight: inherit; }
  .c-contacts .c-phones .c-phones__label {
    display: block;
    font-size: 1.8rem;
    font-weight: 500; }
  .c-contacts .c-phones .c-phones__item {
    margin-top: 0; }
    .c-contacts .c-phones .c-phones__item + .c-phones__item {
      margin-top: 4px; }
  .c-contacts .c-phones .c-phones__list {
    transform: translateX(-2px); }
  .c-contacts .c-phones .c-phones__link {
    font-size: 1.8rem;
    font-weight: 500;
    color: #696b6f; }

@media only screen and (min-width: 1280px) {
  .c-phones .c-phones__item {
    margin-top: 6px; }
  .c-phones .c-phones__link {
    font-size: 1.6rem;
    color: rgba(255, 255, 255, 0.5); }
    .c-phones .c-phones__link:hover {
      color: rgba(255, 255, 255, 0.7); } }

@media only screen and (min-width: 1500px) {
  .c-phones .c-phones__item {
    display: inline-block; }
    .c-phones .c-phones__item:last-child .c-phones__link:after {
      content: ''; }
    .c-phones .c-phones__item + .c-phones__item {
      padding-left: 5px; }
  .c-phones .c-phones__link:after {
    content: '\002c';
    display: inline-block; }
  .c-phones .c-phones__label {
    padding-right: 10px;
    display: inline-block;
    margin-left: 0;
    color: rgba(255, 255, 255, 0.3); } }

@media only screen and (min-width: 1700px) {
  .c-phones {
    margin-top: 10px; }
    .c-phones .c-phones__item {
      margin-top: 0; }
    .c-phones .c-phones__list {
      display: inline-block;
      vertical-align: middle; }
    .c-phones .c-phones__label {
      vertical-align: middle;
      padding-right: 10px; } }

.c-overlay, .c-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transform: translate(-120%, 0);
  transition: opacity .3s;
  background-color: rgba(27, 27, 30, 0.9);
  opacity: 0; }
  .c-overlay.is-open, .is-open.c-modal-overlay {
    transform: translate(0, 0);
    opacity: 1; }
  .c-overlay .c-overlay__content, .c-modal-overlay .c-overlay__content {
    background-color: #0758ff;
    width: 276px;
    height: 100%;
    overflow: auto; }

@media only screen and (min-width: 1367px) {
  .c-overlay.is-open, .is-open.c-modal-overlay {
    transform: translate(-120%, 0); } }

.c-modal-overlay {
  z-index: 12; }
  .c-modal-overlay .c-modal-overlay__close {
    width: 61px;
    height: 47px;
    display: block;
    padding: 0;
    position: fixed;
    right: 10px;
    top: 10px;
    margin-left: auto;
    margin-right: 0;
    border-radius: 4px; }
    .c-modal-overlay .c-modal-overlay__close:before, .c-modal-overlay .c-modal-overlay__close:after {
      right: 7px; }
  .c-modal-overlay .c-overlay__content {
    background-color: rgba(0, 0, 0, 0.65);
    width: 100%; }
    .c-modal-overlay .c-overlay__content::-webkit-scrollbar {
      width: 7px;
      height: 7px; }
    .c-modal-overlay .c-overlay__content::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      border-radius: 5px; }
    .c-modal-overlay .c-overlay__content::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #696b6f;
      -webkit-box-shadow: none; }

@media only screen and (min-width: 1280px) {
  .c-modal-overlay.is-open {
    transform: translate(0, 0); }
  .c-modal-overlay .c-modal-overlay__close {
    cursor: pointer;
    top: 50px;
    right: 50px; }
    .c-modal-overlay .c-modal-overlay__close:before, .c-modal-overlay .c-modal-overlay__close:after {
      width: 30px;
      right: -1px; } }

.c-bar .c-bar__inner {
  padding-top: 46px; }

.c-menu .c-menu__curency {
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 30px;
  padding-bottom: 47px;
  font-size: 0; }

.c-menu .c-menu__phones {
  padding-left: 17px;
  padding-right: 17px; }

.c-categories .c-categories__inner {
  padding-left: 7px;
  padding-right: 7px; }
  @media only screen and (min-width: 1280px) {
    .c-categories .c-categories__inner {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (min-width: 1315px) {
    .c-categories .c-categories__inner {
      max-width: 1235px;
      margin-left: auto;
      margin-right: auto; } }

.c-cat .c-cat__list {
  padding-top: 15px;
  font-size: 0;
  margin-left: -5px;
  margin-right: -5px; }

.c-cat .c-cat__icon {
  display: block;
  margin-bottom: 8px; }
  .c-cat .c-cat__icon [class^='icon-'] {
    display: inline-block;
    font-size: 3.7rem;
    transform: translateX(-2px); }
  .c-cat .c-cat__icon .icon-usb-ico,
  .c-cat .c-cat__icon .icon-phone-ico {
    transform: translateX(-10px); }

.c-cat .c-cat__item {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-top: 5px; }
  .c-cat .c-cat__item.t-bonus {
    color: #daba57; }

.c-cat .c-cat__inner {
  background-color: rgba(39, 39, 44, 0.6);
  padding-top: 11px;
  padding-left: 11px;
  padding-bottom: 11px;
  padding-right: 11px;
  border-radius: 4px;
  min-height: 117px; }

.c-cat .c-cat__title {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.3;
  display: block; }

.c-cat .c-cat__links {
  display: none; }

@media only screen and (min-width: 678px) {
  .c-cat .c-cat__item {
    width: 33.333%; } }

@media only screen and (min-width: 1025px) {
  .c-cat .c-cat__title {
    font-size: 2.4rem; }
  .c-cat .c-cat__icon {
    margin-bottom: 16px; }
    .c-cat .c-cat__icon [class^='icon-'] {
      font-size: 4.6rem;
      color: inherit; }
  .c-cat .c-cat__inner {
    padding-left: 27px;
    padding-right: 10px;
    padding-top: 23px;
    padding-bottom: 25px;
    min-height: 252px; }
  .c-cat .c-cat__links {
    margin-top: 15px;
    display: block;
    font-size: 1.6rem;
    color: rgba(255, 255, 255, 0.5); }
    .c-cat .c-cat__links a {
      font-size: inherit;
      display: inline-block;
      line-height: 1.38; }
      .c-cat .c-cat__links a:hover {
        color: #fff; }
      .c-cat .c-cat__links a:after {
        display: inline-block; } }

@media only screen and (min-width: 1280px) {
  .c-cat .c-cat__list {
    margin-left: -17px;
    margin-right: -17px; }
  .c-cat .c-cat__item {
    width: 25%;
    position: relative; }
    .c-cat .c-cat__item.t-bonus .c-cat__title {
      color: rgba(218, 186, 87, 0.5); }
      .c-cat .c-cat__item.t-bonus .c-cat__title:hover {
        color: #daba57; }
    .c-cat .c-cat__item:hover:after {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      bottom: 5px;
      right: -8px;
      background-color: rgba(39, 39, 44, 0.6);
      z-index: -1;
      border-radius: 6px; }
  .c-cat .c-cat__title {
    color: rgba(255, 255, 255, 0.5); }
    .c-cat .c-cat__title:hover {
      color: #fff; }
  .c-cat .c-cat__inner {
    background-color: unset;
    min-height: unset; } }

@media only screen and (min-width: 1315px) {
  .c-cat .c-cat__list {
    margin-left: -40px;
    margin-right: -40px; } }

@media only screen and (min-width: 1440px) {
  .c-cat .c-cat__list {
    margin-left: -100px;
    margin-right: -100px; }
  .c-cat .c-cat__item {
    padding-top: 15px;
    padding-left: 20px;
    padding-bottom: 15px;
    padding-right: 20px; } }

.c-breadcrumbs {
  padding-top: 20px;
  padding-bottom: 17px; }

@media only screen and (min-width: 993px) {
  .c-breadcrumbs {
    padding-top: 30px;
    padding-bottom: 27px; } }

@media only screen and (min-width: 1280px) {
  .c-breadcrumbs {
    padding-top: 37px;
    padding-bottom: 20px; }
    .s-item .c-breadcrumbs {
      padding-bottom: 45px; } }

.c-chips .c-chips__list {
  font-size: 0;
  margin-left: -4px;
  margin-left: -4px; }

.c-chips .c-chips__dotted {
  padding-top: 8px; }

@media only screen and (min-width: 993px) {
  .c-chips .c-chips__dotted {
    padding-top: 20px; } }

@media only screen and (min-width: 1280px) {
  .c-chips .c-chips__list {
    max-width: 1030px; } }

.c-dotted {
  color: #696b6f; }
  .c-dotted .c-dotted__anchor {
    display: inline;
    color: inherit;
    background-image: linear-gradient(90deg, #696b6f 50%, transparent 0), linear-gradient(90deg, transparent, transparent);
    background-size: 2px 1px,100% 1px;
    background-repeat: repeat-x;
    background-position: 0 98%; }
  .c-dotted.has-arrow .c-dotted__anchor {
    margin-right: 15px;
    position: relative; }
    .c-dotted.has-arrow .c-dotted__anchor:after {
      content: "";
      position: absolute;
      right: -20px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.3rem;
      color: inherit;
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
  .c-dotted.has-arrow.is-open .c-dotted__anchor {
    color: #dedede; }
    .c-dotted.has-arrow.is-open .c-dotted__anchor:after {
      transform: translateY(-50%) rotate(180deg); }

@media only screen and (min-width: 1280px) {
  .c-dotted .c-dotted__anchor {
    cursor: pointer; }
    .c-dotted .c-dotted__anchor:hover {
      color: #dedede; } }

.c-arrange .c-arrange__filter {
  position: relative;
  background-color: rgba(40, 40, 46, 0.6);
  padding-left: 23px;
  padding-right: 17px;
  padding-top: 10px;
  padding-bottom: 9px;
  border-bottom-right-radius: 22px;
  border-top-right-radius: 22px;
  white-space: nowrap;
  font-size: 0; }
  .c-arrange .c-arrange__filter.is-active .c-arrange__btn {
    opacity: 1;
    transform: translate(100%, -50%); }
  .c-arrange .c-arrange__filter .c-arrange__btn {
    position: absolute;
    font-size: 1.6rem;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
    opacity: 0; }

.c-arrange .c-arrange__drop {
  padding-top: 20px; }

.c-arrange .c-arrange__amount--large {
  display: none; }

.c-arrange .c-arrange__row {
  margin-left: -17px;
  margin-right: -17px; }

.c-arrange .c-arrange__box {
  display: inline-block;
  vertical-align: middle; }

.c-arrange .c-arrange__label {
  display: inline-block;
  vertical-align: middle;
  font-weight: 500;
  margin-left: 12px;
  font-size: 1.6rem; }

.c-arrange .c-arrange__column + .c-arrange__column {
  margin-top: 20px; }

.c-arrange .c-arrange__right {
  font-size: 0; }
  .c-arrange .c-arrange__right .c-arrange__amount {
    display: inline-block; }

.c-arrange .c-arrange__amount {
  font-size: 1.4rem;
  line-height: 1;
  vertical-align: middle;
  padding-right: 17px;
  padding-left: 17px;
  width: 40%; }

.c-arrange .c-arrange__sort {
  padding-left: 17px;
  padding-right: 17px;
  text-align: right;
  display: inline-block;
  vertical-align: middle;
  width: 60%;
  position: absolute;
  left: 40%;
  z-index: 1; }

@media only screen and (min-width: 993px) {
  .c-arrange .c-arrange__amount {
    font-size: 1.6rem; }
  .c-arrange .c-arrange__label {
    display: inline-block;
    vertical-align: middle; }
  .c-arrange .c-arrange__amount {
    vertical-align: middle; }
  .c-arrange .c-arrange__filter.is-active .c-arrange__btn {
    display: none; }
  .c-arrange .c-arrange__filter.is-active .c-dotted {
    color: #dedede; }
  .c-arrange .c-arrange__filter.is-active .c-dotted__anchor {
    background-image: linear-gradient(90deg, #dedede 50%, transparent 0), linear-gradient(90deg, transparent, transparent); } }

@media only screen and (min-width: 1025px) {
  .c-arrange .c-arrange__drop {
    padding-left: 7px; }
  .c-arrange .c-arrange__filter {
    background-color: unset;
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 0;
    padding-top: 0;
    padding-left: 7px;
    padding-right: 7px; }
  .c-arrange .c-arrange__row {
    font-size: 0; }
  .c-arrange .c-arrange__column + .c-arrange__column {
    margin-top: 0; }
  .c-arrange .c-arrange__label {
    font-weight: normal; }
  .c-arrange .c-arrange__column {
    width: 50%;
    display: inline-block;
    vertical-align: middle; }
  .c-arrange .c-arrange__sort {
    position: relative;
    left: initial;
    width: 100%; }
  .c-arrange .c-arrange__amount {
    width: auto; }
  .c-arrange .c-arrange__right .c-arrange__amount {
    display: none; }
  .c-arrange .c-arrange__amount--large {
    display: inline-block;
    padding-left: 7px;
    padding-right: 7px; }
  .c-arrange .c-arrange__row {
    margin-left: 0; } }

@media only screen and (min-width: 1280px) {
  .c-arrange .c-arrange__drop {
    padding-left: 27px; }
  .c-arrange .c-arrange__row {
    margin-left: -17px; } }

@media only screen and (min-width: 1440px) {
  .c-arrange .c-arrange__drop {
    padding-left: 0; }
  .c-arrange .c-arrange__row {
    margin-left: -40px; } }

.c-preview__inner {
  padding-left: 7px;
  padding-right: 7px; }

.c-preview__container {
  position: relative; }

.c-preview__available {
  margin-top: 15px;
  font-size: 1.4rem;
  color: #696b6f;
  max-width: 93px;
  display: inline-block; }

.c-preview__part {
  border-radius: 6px;
  background-color: rgba(40, 40, 46, 0.6);
  position: relative; }

.c-preview__amount {
  position: relative;
  padding-top: 13px;
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 18px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around; }

.c-preview__main {
  padding-top: 13px;
  padding-left: 9px;
  padding-bottom: 15px;
  padding-right: 9px; }

.c-preview.is-open .c-preview__secondary {
  display: block; }

.c-preview__secondary {
  background-color: #28282e;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none; }

.c-preview__media {
  position: absolute; }

.c-preview__detail {
  display: inline-block;
  vertical-align: top;
  padding-left: 115px;
  padding-right: 6px;
  width: 100%;
  min-height: 93px; }

.c-preview__title {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4; }

.c-preview__props {
  padding-top: 6px;
  color: #696b6f; }

.c-preview__image {
  width: 93px;
  height: 93px; }
  .c-preview__image img {
    border-radius: 5px; }

.c-preview__action {
  padding-top: 20px;
  margin-left: -7px; }

.c-preview__price, .c-preview__btn {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 7px; }

.c-preview__btn {
  margin-left: 7px; }

.c-preview + .c-preview {
  margin-top: 10px; }

@media only screen and (min-width: 480px) {
  .c-preview__main {
    padding-left: 23px;
    padding-top: 20px; }
  .c-preview__detail {
    padding-left: 116px; }
  .c-preview__media, .c-preview__detail {
    text-align: left; }
  .c-preview__amount {
    align-items: center; } }

@media only screen and (min-width: 678px) {
  .c-preview__btn {
    display: none; }
  .c-preview__available {
    display: none; }
  .c-preview__amount {
    padding: 0;
    flex-direction: row;
    align-items: stretch; }
  .c-preview__part {
    padding: 0;
    border-radius: 0;
    background-color: unset; }
  .c-preview__container {
    padding-top: 19px;
    padding-left: 23px;
    padding-right: 23px;
    padding-bottom: 25px;
    border-radius: 6px;
    background-color: rgba(40, 40, 46, 0.5);
    display: flex;
    flex-direction: row; }
  .c-preview__close {
    display: none; }
  .c-preview-row {
    text-align: left; }
  .c-preview__main {
    flex: 0 0 66%; }
  .c-preview__container {
    font-size: 0; }
  .c-preview__detail {
    padding-right: 0;
    display: flex;
    flex-direction: column; }
  .c-preview__action {
    margin-top: auto; }
  .c-preview__secondary {
    text-align: right;
    vertical-align: top;
    display: inline-block;
    position: relative;
    flex: 0 0 34%; }
  .c-preview.is-open .c-preview__secondary {
    display: inline-block; }
  .c-preview__price, .c-preview__btn {
    margin-bottom: 0; } }

@media only screen and (min-width: 993px) {
  .c-preview__image {
    width: 127px;
    height: 127px; }
    .c-preview__image img {
      border-radius: 10px; }
  .c-preview__detail {
    padding-left: 150px;
    min-height: 127px; }
  .c-preview__container {
    padding-top: 17px; }
  .c-preview__title {
    font-size: 2.2rem; }
  .c-preview__action {
    padding-top: 10px; }
  .c-preview + .c-preview {
    margin-top: 11px; } }

@media only screen and (min-width: 1280px) {
  .c-preview__inner {
    max-width: 1276px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0; }
  .c-preview:hover .c-preview__title,
  .c-preview:hover .c-price,
  .c-preview:hover .c-quantity__head {
    color: #dedede; }
  .c-preview:hover .c-preview__container {
    background-color: rgba(40, 40, 46, 0.6); }
  .c-preview .c-preview__title,
  .c-preview .c-price,
  .c-preview .c-quantity__head {
    color: #696b6f;
    transition: all 0.2s ease; }
    .c-preview .c-preview__title:hover,
    .c-preview .c-price:hover,
    .c-preview .c-quantity__head:hover {
      transition: none; } }

.c-price {
  display: inline-block; }
  .c-price .c-price__inner {
    line-height: 1;
    font-weight: 500;
    font-size: 2rem;
    padding-top: 9px;
    padding-bottom: 8px;
    padding-left: 7px;
    padding-right: 9px;
    display: block;
    background-color: #1b1b1e; }
  .c-detail .c-price .c-price__inner {
    padding-left: 0;
    background-color: unset; }

@media only screen and (min-width: 768px) {
  .c-price .c-price__inner {
    padding-top: 14px;
    padding-bottom: 13px;
    padding-left: 14px;
    padding-right: 17px; } }

@media only screen and (min-width: 993px) {
  .c-detail .c-price .c-price__inner {
    font-size: 3.6rem; } }

.c-quantity .c-quantity__close {
  position: absolute;
  top: 0;
  right: 0; }
  .c-quantity .c-quantity__close:before, .c-quantity .c-quantity__close:after {
    width: 15px;
    background-color: #696b6f; }

.c-quantity__head {
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 10px; }

.c-quantity__action {
  font-size: 0; }

.c-quantity__button {
  margin-left: -2px;
  margin-right: -2px;
  margin-top: 9px; }

.c-quantity__field {
  display: inline-block;
  vertical-align: middle;
  height: 47px;
  background-color: #1b1b1e;
  font-size: 1.6rem;
  font-weight: 500;
  color: #696b6f; }

.c-quantity__sign {
  width: 20%;
  font-size: 3rem;
  font-weight: normal; }

.c-quantity__minus {
  font-size: 1.6rem;
  font-weight: 500; }

.c-quantity__controls {
  width: 58%;
  margin-left: 1%;
  margin-right: 1%;
  position: relative;
  border-radius: 4px;
  overflow: hidden; }

.c-quantity__label {
  position: absolute;
  left: 51%;
  top: 50%;
  transform: translateY(-50%); }

.c-quantity__input {
  text-align: right;
  padding-left: 10px;
  padding-top: 18px;
  padding-bottom: 17px;
  padding-right: 51%;
  -webkit-appearance: none;
  color: #696b6f;
  background-color: #1b1b1e;
  border: none;
  -moz-appearance: textfield;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%; }
  .c-quantity__input:focus {
    outline: none; }
  .c-quantity__input::-webkit-outer-spin-button, .c-quantity__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

@media only screen and (min-width: 480px) {
  .c-quantity {
    width: 430px;
    max-width: 100%; } }

@media only screen and (min-width: 678px) {
  .c-quantity {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    .c-quantity__sign {
      display: none; }
    .c-quantity__controls {
      width: 100%; }
    .c-quantity__input {
      width: 100%;
      padding-right: 52%; }
    .c-quantity__head {
      text-align: right; }
    .c-quantity__action {
      font-size: 0;
      margin-top: auto; }
    .c-quantity__button {
      margin-top: 20px; } }

@media only screen and (min-width: 993px) {
  .c-quantity__action {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
  .c-quantity__inputs {
    display: inline-block;
    padding-right: 12px; }
  .c-quantity__button {
    padding-left: 7px;
    display: inline-block;
    flex: 0 0 175px;
    margin-top: 0; }
  .c-quantity__label {
    position: relative;
    transform: none;
    left: initial;
    top: initial; }
  .c-quantity__action {
    width: 100%; }
  .c-quantity__controls {
    background-color: unset;
    text-align: right; }
  .c-quantity__field {
    border-radius: 0;
    white-space: nowrap; }
  .c-quantity__input {
    position: relative;
    width: 74px;
    height: 44px;
    padding-top: 12px;
    padding-bottom: 8px;
    padding-right: 10px;
    border-radius: 4px; }
  .c-quantity__field {
    height: unset; }
  .c-quantity__head {
    padding-top: 12px;
    padding-bottom: 11px;
    padding-left: 14px;
    padding-right: 17px;
    background-color: #1b1b1e; } }

@media only screen and (min-width: 1440px) {
  .c-quantity__inputs {
    padding-right: 23px; } }

.c-catalog .c-catalog__inner {
  position: relative;
  overflow: hidden; }

.c-catalog .c-catalog__asside {
  transform: translateX(-130%);
  position: absolute;
  top: 0;
  bottom: 0;
  transition: transform .3s;
  opacity: 0; }

.c-catalog .c-catalog__list {
  transition: .3s;
  min-height: 500px; }

.c-catalog .c-catalog__backdrop {
  transform: translateX(130%);
  transition: .3s;
  position: absolute;
  left: 255px;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10; }

.c-catalog.is-open .c-catalog__backdrop {
  transform: translateX(0); }

.c-catalog.is-open .c-catalog__asside {
  transform: translateX(0);
  opacity: 1;
  width: 255px; }

.c-catalog.is-open .c-catalog__list {
  transform: translateX(255px);
  transition: .3s; }

.c-catalog.is-open .c-preview,
.c-catalog.is-open .c-more {
  opacity: .1; }

@media only screen and (min-width: 993px) {
  .c-catalog {
    padding-bottom: 50px; }
    .c-catalog .c-catalog__backdrop {
      left: 395px; }
    .c-catalog.is-open .c-catalog__asside {
      width: 395px; }
    .c-catalog.is-open .c-catalog__list {
      transform: translateX(395px); } }

@media only screen and (min-width: 1280px) {
  .c-catalog .c-catalog__inner {
    overflow: visible; }
  .c-catalog .c-catalog__asside {
    z-index: 5; }
  .c-catalog.is-open .c-catalog__list {
    padding-left: 415px;
    transform: translateX(0); }
  .c-catalog.is-open .c-preview,
  .c-catalog.is-open .c-more {
    opacity: 1; }
  .c-catalog .c-catalog__backdrop {
    z-index: -1;
    display: none; }
  .c-catalog .c-catalog__list {
    padding-right: 20px;
    padding-left: 20px; } }

@media only screen and (min-width: 1435px) {
  .c-catalog {
    padding-bottom: 100px; }
    .c-catalog .c-catalog__list {
      padding-right: 40px;
      padding-left: 40px; }
    .c-catalog.is-open .c-catalog__list {
      padding-right: 0; }
    .c-catalog .c-catalog__inner {
      max-width: 1395px;
      margin-right: auto;
      margin-left: auto; } }

.c-sort {
  z-index: 10;
  font-size: 1.4rem;
  display: inline-flex;
  padding: 10px;
  flex-direction: column;
  transition: background .3s;
  border-radius: 6px; }
  .c-sort.is-active .c-sort__item {
    display: block; }
  .c-sort .c-sort__dotted {
    color: #dedede;
    padding-right: 14px;
    backface-visibility: hidden; }
  .c-sort .c-sort__anchor {
    background-image: linear-gradient(90deg, #dedede 50%, transparent 0), linear-gradient(90deg, transparent, transparent); }
  .c-sort .c-sort__item {
    display: none; }
    .c-sort .c-sort__item.is-active {
      display: block;
      order: -1; }
      .c-sort .c-sort__item.is-active .c-sort__dotted {
        padding-right: 0; }
      .c-sort .c-sort__item.is-active .c-sort__anchor {
        padding-right: 14px;
        position: relative; }
        .c-sort .c-sort__item.is-active .c-sort__anchor:after {
          position: absolute;
          content: "";
          right: 2px;
          top: 50%;
          bottom: auto;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          opacity: .7;
          border-style: solid;
          border-width: 7px 4px 0 4px;
          border-color: #fff transparent transparent transparent; }
  .c-sort.is-open {
    background-color: #191919; }
    .c-sort.is-open .c-sort__item {
      display: block; }
      .c-sort.is-open .c-sort__item.is-active .c-sort__anchor:after {
        transform: translateY(-50%) rotate(-180deg); }

@media only screen and (min-width: 993px) {
  .c-sort {
    padding: 0;
    font-size: 1.6rem;
    flex-direction: row;
    justify-content: flex-end; }
    .c-sort .c-sort__dotted {
      padding-right: 0;
      color: #696b6f; }
    .c-sort .c-sort__anchor {
      background-image: linear-gradient(90deg, #696b6f 50%, transparent 0), linear-gradient(90deg, transparent, transparent);
      padding-left: 0;
      padding-right: 0; }
    .c-sort .c-sort__item {
      display: block;
      padding-left: 10px;
      padding-right: 10px; }
      .c-sort .c-sort__item:not(.is-active) {
        text-transform: lowercase; }
      .c-sort .c-sort__item.is-active {
        order: 0; }
        .c-sort .c-sort__item.is-active .c-sort__dotted {
          color: #dedede; }
        .c-sort .c-sort__item.is-active .c-sort__anchor {
          pointer-events: none;
          padding-right: 0;
          background-image: linear-gradient(90deg, #dedede 50%, transparent 0), linear-gradient(90deg, transparent, transparent); }
          .c-sort .c-sort__item.is-active .c-sort__anchor:after {
            content: none; }
    .c-sort.is-open {
      background: none; } }

.c-filter {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 500px;
  max-height: 100%;
  background-color: rgba(37, 37, 43, 0.8);
  padding-left: 24px;
  padding-top: 35px;
  padding-bottom: 38px;
  padding-right: 17px;
  border-radius: 6px; }
  .c-filter::-webkit-scrollbar {
    width: 7px;
    height: 7px; }
  .c-filter::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 5px; }
  .c-filter::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #696b6f;
    -webkit-box-shadow: none; }

@media only screen and (min-width: 993px) {
  .c-filter {
    padding-bottom: 50px; } }

@media only screen and (min-width: 1025px) {
  .c-filter {
    padding-left: 47px; } }

@media only screen and (min-width: 1280px) {
  .c-filter {
    padding-left: 67px; } }

@media only screen and (min-width: 1315px) {
  .c-filter {
    padding-left: 120px; } }

.c-fgroup + .c-fgroup {
  margin-top: 37px; }

.c-fgroup + .c-fgroup--action {
  margin-top: 32px; }

.c-fgroup .c-fgroup__title {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 12px;
  transform: translateX(-3px); }

.c-fgroup .c-fgroup__dotted {
  margin-top: 7px; }

.fade-enter-active,
.fade-leave-active {
  transition: opacity .2s; }

.fade-enter,
.fade-leave-to {
  opacity: 0; }

@media only screen and (min-width: 993px) {
  .c-fgroup {
    display: inline-block;
    min-width: 51%;
    text-align: left; }
    .c-fgroup.c-fgroup--action {
      min-width: 60%; } }

@media only screen and (min-width: 1280px) {
  .c-fgroup.c-fgroup--action {
    min-width: 260px;
    transform: translateX(-40px); } }

@media only screen and (min-width: 1315px) {
  .c-fgroup.c-fgroup--action {
    transform: translateX(-71px); } }

.c-more {
  text-align: center;
  color: #696b6f;
  font-size: 1.6rem;
  font-weight: 500;
  padding-top: 34px;
  padding-bottom: 20px; }
  .c-more__anchor {
    display: inline;
    position: relative;
    padding-left: 30px; }
    .c-more__anchor:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 17px;
      font-size: 1.6rem;
      color: inherit;
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
  .c-more .c-more__column + .c-more__column {
    margin-top: 30px; }

@media only screen and (min-width: 1025px) {
  .c-more .c-more__row {
    margin-right: -17px;
    margin-left: -17px; }
  .c-more .c-more__column {
    width: 49%;
    display: inline-block;
    vertical-align: bottom;
    padding-right: 17px;
    padding-left: 17px; }
    .c-more .c-more__column + .c-more__column {
      margin-top: 0;
      text-align: right;
      padding-right: 47px; } }

@media only screen and (min-width: 1280px) {
  .c-more {
    font-size: 2.2rem; }
    .c-more__anchor {
      cursor: pointer;
      padding-left: 41px; }
      .c-more__anchor:before {
        width: 33px;
        height: 24px;
        font-size: 2.4rem; }
      .c-more__anchor:hover {
        color: rgba(222, 222, 222, 0.5); }
    .c-more .c-more__column + .c-more__column {
      padding-right: 67px; } }

.c-props .c-props__list.is-invisible {
  display: none; }

.c-props .c-props__item {
  padding-bottom: 22px; }

.c-props .c-props__key {
  color: #696b6f;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.3;
  margin-bottom: 5px; }

.c-props .c-props__more.is-invisible {
  display: none; }

.c-props .c-props__val {
  line-height: 1.3;
  font-size: 1.6rem; }

@media only screen and (min-width: 768px) {
  .c-props .c-props__list {
    margin-left: -7px;
    margin-right: -7px; }
  .c-props .c-props__item {
    padding-top: 12px;
    padding-bottom: 15px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 6px;
    font-size: 0;
    transition: all 0.2s ease; }
    .c-props .c-props__item:hover {
      transition: none; }
    .c-props .c-props__item:hover {
      background-color: rgba(40, 40, 46, 0.5); }
  .c-props .c-props__more {
    margin-top: 10px;
    text-align: center; }
  .c-props .c-props__key,
  .c-props .c-props__val {
    display: inline-block;
    vertical-align: top;
    width: 50%; } }

@media only screen and (min-width: 993px) {
  .c-props .c-props__inner {
    max-width: 869px; } }

@media only screen and (min-width: 1025px) {
  .c-props .c-props__list {
    margin-left: -23px;
    margin-right: -23px; }
  .c-props .c-props__item {
    padding-left: 23px;
    padding-right: 23px; } }

@media only screen and (min-width: 1342px) {
  .c-props .c-props__list {
    margin-left: 0;
    margin-right: 0; }
  .c-props .c-props__item {
    padding-left: 33px;
    padding-right: 33px; } }

.c-prev [class^='icon'] {
  transform: rotate(90deg); }

.c-next [class^='icon'] {
  transform: rotate(-90deg); }

.c-arrow {
  z-index: 5;
  width: 61px;
  height: 47px;
  border-radius: 4px; }
  .c-arrow .c-arrow__inner {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .c-arrow [class^='icon'] {
    font-size: 1.8rem;
    color: rgba(24, 29, 35, 0.5);
    display: inline-block; }

@media only screen and (min-width: 1280px) {
  .c-arrow {
    cursor: pointer;
    width: 86px; }
    .c-arrow [class^='icon'] {
      font-size: 2.1rem; } }

.c-slider {
  position: relative; }
  .c-slider .c-slider__inner {
    padding-bottom: 22px;
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s; }
    .c-slider .c-slider__inner.slick-initialized {
      visibility: visible;
      opacity: 1; }
  .c-slider .c-slider__next,
  .c-slider .c-slider__prev {
    position: absolute;
    top: 50%;
    margin-top: -64.5px; }
  .c-slider .c-slider__prev {
    left: 0; }
  .c-slider .c-slider__next {
    right: 0; }
  .c-slider .slick-dots {
    padding-top: 14px;
    position: relative;
    text-align: left;
    bottom: initial;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto; }
    .c-slider .slick-dots::-webkit-scrollbar {
      width: 7px;
      height: 7px; }
    .c-slider .slick-dots::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      border-radius: 5px; }
    .c-slider .slick-dots::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #696b6f;
      -webkit-box-shadow: none; }
    .c-slider .slick-dots li {
      margin-left: 5px;
      margin-right: 5px;
      margin-top: 5px;
      margin-bottom: 5px; }
      .c-slider .slick-dots li:first-child {
        margin-left: 17px; }

.c-thumb {
  position: relative;
  border: 2px solid rgba(7, 88, 255, 0);
  border-radius: 6px;
  overflow: hidden; }
  .c-thumb .c-thumb__img {
    position: relative;
    border-radius: 5px;
    font-size: 0;
    background-color: #111111;
    overflow: hidden;
    opacity: .55;
    width: 74px;
    height: 74px; }
    .c-thumb .c-thumb__img img {
      position: absolute;
      max-width: 102%;
      max-height: 102%;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      margin: auto; }
  .slick-active .c-thumb {
    position: relative;
    border-color: #0758ff; }
    .slick-active .c-thumb .c-thumb__img {
      background-color: #fff;
      opacity: 1; }

@media only screen and (min-width: 1440px) {
  .c-slider {
    padding-left: 100px; }
    .c-slider .slick-dots {
      padding-top: 0;
      transform: translateX(-100px) translateY(-5px);
      top: 0;
      left: 0;
      position: absolute;
      white-space: normal;
      display: inline-block;
      height: 491px;
      overflow-y: auto;
      width: 106px;
      overflow-x: hidden; }
      .c-slider .slick-dots li {
        width: 100px;
        display: block; }
        .c-slider .slick-dots li:first-child {
          margin-left: 5px; } }

@media only screen and (min-width: 1440px) {
  .c-thumb {
    display: inline-block; }
    .c-thumb .c-thumb__img {
      background-color: #fff;
      width: 88px;
      height: 88px; } }

.c-item {
  padding-left: 17px;
  padding-right: 17px;
  cursor: zoom-in; }
  .c-item .c-item__img {
    height: 400px;
    position: relative; }
    .c-item .c-item__img img {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; }
  .c-item .c-item__inner {
    margin-left: auto;
    margin-right: auto;
    background-color: #cccccc;
    border-radius: 5px;
    overflow: hidden; }

@media only screen and (min-width: 1440px) {
  .c-item {
    height: 485px; }
    .c-item .c-item__img {
      height: 485px; } }

.c-detail {
  padding-left: 17px;
  padding-right: 17px; }
  .c-detail .c-detail__available {
    padding-bottom: 16px; }
  .c-detail .c-detail__title {
    margin-bottom: 15px; }
  .c-detail .c-detail__group {
    display: block;
    margin-top: 18px; }
  .c-detail .c-detail__price {
    font-size: 3rem;
    vertical-align: middle; }
  .c-detail__flex-divider {
    display: none; }

@media only screen and (min-width: 993px) {
  .c-detail .c-detail__inner {
    display: inline-block;
    text-align: left;
    padding-left: 40px; }
  .c-detail .c-detail__brief {
    max-width: 410px; }
  .c-detail .c-detail__group {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12px;
    padding-left: 40px; }
  .c-detail .c-detail__available {
    padding-bottom: 0;
    margin-bottom: 15px; }
  .c-detail .c-detail__price {
    order: -1;
    transform: translateX(-2px);
    margin-bottom: 15px; }
  .c-detail .c-detail__btn {
    display: inline-block;
    vertical-align: middle;
    order: 1; }
  .c-detail .c-detail__flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center; }
    .c-detail .c-detail__flex-divider {
      display: block;
      width: 100%;
      height: 20px; } }

@media only screen and (min-width: 1440px) {
  .c-detail .c-detail__brief {
    max-width: 440px; }
  .c-detail .c-detail__group,
  .c-detail .c-detail__inner {
    padding-left: 70px; } }

.c-available {
  font-size: 1.6rem; }
  .c-available .c-available__inner {
    padding-left: 17px;
    padding-right: 17px;
    padding-top: 10px;
    padding-bottom: 9px;
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 4px; }

.c-gallery .c-gallery__item {
  cursor: zoom-out; }

.c-gallery img {
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 17px;
  padding-bottom: 17px;
  max-width: 900px;
  width: auto;
  border-radius: 20px;
  margin: auto; }

.c-gallery .c-gallery__item + .c-gallery__item {
  margin-top: 50px; }

@media only screen and (min-width: 1280px) {
  .c-gallery img {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    max-width: 100%; } }

@media only screen and (min-width: 1342px) {
  .c-item-title {
    padding-left: 30px; } }

.c-notavailable .c-notavailable__inner {
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 60px; }

.c-notavailable .c-notavailable__text {
  font-weight: 500;
  font-size: 2rem;
  padding-left: 17px; }

@media only screen and (min-width: 650px) {
  .c-notavailable .c-notavailable__inner {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto; } }

@media only screen and (min-width: 1025px) {
  .c-notavailable .c-notavailable__text {
    padding-left: 0; }
  .c-notavailable .c-notavailable__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: unset;
    width: 100%;
    min-height: 100%; } }

@media only screen and (min-width: 1280px) {
  .c-notavailable .c-notavailable__text {
    font-size: 2.4rem; } }

.c-contacts .c-contacts__heading {
  margin-bottom: 37px; }

.c-contacts .c-contacts__label {
  margin-bottom: 8px; }

.c-contacts .c-contacts__column + .c-contacts__column {
  margin-top: 30px; }

@media only screen and (min-width: 1025px) {
  .c-contacts .c-contacts__row {
    font-size: 0;
    margin-left: -17px;
    margin-right: -17px; }
  .c-contacts .c-contacts__column {
    width: 33.3333%;
    display: inline-block;
    vertical-align: top;
    padding-left: 17px;
    padding-right: 17px; }
    .c-contacts .c-contacts__column + .c-contacts__column {
      margin-top: 0; } }

@media only screen and (min-width: 1280px) {
  .c-contacts .c-contacts__row {
    margin-left: -35px;
    margin-right: -35px; }
  .c-contacts .c-contacts__column {
    padding-left: 35px;
    padding-right: 35px; } }

.c-social {
  font-size: 2.4rem;
  font-weight: 500;
  color: #3b5998; }

@media only screen and (min-width: 1280px) {
  .c-social {
    transition: all 0.2s ease; }
    .c-social:hover {
      transition: none; }
    .c-social:hover {
      color: #4c70ba; } }

.c-map {
  height: 420px;
  background-color: #191919; }
  .c-map > div {
    background-color: #191919 !important; }

@media only screen and (min-width: 1025px) {
  .c-map {
    height: 580px; } }

.c-notice {
  border-radius: 6px;
  position: fixed;
  top: 59px;
  right: 7px;
  transform: translateX(100%);
  width: 280px;
  background-color: #28282e;
  opacity: 0;
  transition: transform .3s; }
  .c-notice.is-open {
    transform: translateX(0);
    opacity: 1; }
  .c-notice .c-notice__inner {
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px; }
  .c-notice .c-notice__row {
    font-size: 0;
    margin-left: -5px;
    margin-right: -5px; }
  .c-notice .c-notice__column {
    display: inline-block;
    vertical-align: top;
    padding-left: 5px;
    padding-right: 5px; }
  .c-notice .c-notice__text {
    padding-top: 5px;
    width: 75%;
    font-size: 1.4rem; }
  .c-notice .c-notice__img {
    width: 25%; }
    .c-notice .c-notice__img img {
      border-radius: 3px; }

@media only screen and (min-width: 1280px) {
  .c-notice {
    top: 85px;
    right: 29px;
    width: 323px; }
    .c-notice .c-notice__inner {
      padding-left: 17px;
      padding-top: 19px;
      padding-bottom: 19px;
      padding-right: 17px; }
    .c-notice .c-notice__row {
      margin-left: -10px;
      margin-right: -10px; }
    .c-notice .c-notice__column {
      padding-left: 10px;
      padding-right: 10px; }
    .c-notice .c-notice__text {
      font-size: 1.6rem; } }

.c-pag .c-pag__item {
  display: inline-block; }
  .c-pag .c-pag__item.is-active {
    color: #dedede; }
    .c-pag .c-pag__item.is-active a {
      pointer-events: none; }

.c-pag .c-pag__link {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 4px; }

@media only screen and (min-width: 1280px) {
  .c-pag .c-pag__link:hover {
    color: rgba(222, 222, 222, 0.5); } }

.c-info {
  background-color: rgba(40, 40, 46, 0.6);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.38;
  color: #696b6f;
  padding-top: 21px;
  padding-bottom: 21px;
  padding-left: 19px;
  padding-right: 22px;
  border-radius: 6px;
  height: 0;
  opacity: 0;
  transition: opacity .3s; }
  .c-info.is-open {
    display: block;
    opacity: 1;
    height: auto; }
  .c-info .c-info__link {
    color: #daba57; }

@media only screen and (min-width: 1025px) {
  .c-info.is-open {
    display: none; } }

.c-placeholder {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .c-placeholder:before {
    content: '';
    display: block;
    padding-bottom: 100%; }
  .c-placeholder img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 150px;
    width: 50%;
    height: 50%;
    opacity: .1; }
  .c-placeholder--sm:before {
    padding-bottom: 50%; }

.c-btn {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  outline: 0;
  -webkit-appearance: none;
  width: auto;
  padding: 15px;
  color: #fff;
  text-align: center;
  user-select: none;
  line-height: 1.35;
  background-color: #0758ff;
  border-radius: 30px;
  border: 2px solid #0758ff;
  font-size: 0;
  text-align: center;
  transition: all 0.2s ease; }
  .c-btn .help {
    display: inline-block;
    vertical-align: middle; }
  .c-btn:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 0.1%;
    height: 100%;
    margin-left: -.1%; }
  .c-btn:hover {
    transition: none; }
  .c-btn__help {
    display: inline-block;
    vertical-align: middle;
    color: inherit;
    font-weight: 500;
    font-size: 1.6rem; }
  .c-btn.t-medium {
    height: 50px;
    padding: 0 15px; }
  .c-btn.t-small {
    height: 37px;
    padding: 0 15px; }
    .c-btn.t-small .c-btn__help {
      line-height: 1; }
  .c-btn.is-gray, .c-btn.is-disabled {
    background-color: #3a3a3f;
    border-color: #3a3a3f; }
  .c-btn.is-disabled {
    pointer-events: none; }
  .c-btn:disabled {
    opacity: .5;
    cursor: not-allowed; }
  .c-detail__btn .c-btn {
    min-width: 286px; }
  .c-notavailable .c-btn {
    min-width: 260px; }
  .c-header__button .c-btn {
    height: 43px;
    padding: 0 29px;
    background-color: rgba(0, 0, 0, 0);
    min-width: 200px; }
  .t-bonus .c-btn {
    border-color: #daba57;
    background-color: #daba57;
    color: #1e1f24; }
  .c-fgroup .c-btn,
  .c-quantity .c-btn {
    width: 100%; }

@media only screen and (min-width: 678px) {
  .c-quantity .c-btn .c-btn__help {
    font-size: 1.6rem; }
  .c-quantity .c-btn.t-medium {
    height: 43px; } }

@media only screen and (min-width: 768px) {
  .c-btn__help {
    font-size: 1.8rem; } }

@media only screen and (min-width: 993px) {
  .c-quantity .c-btn {
    background-color: rgba(0, 0, 0, 0); }
  .t-bonus .c-quantity .c-btn {
    color: #dedede; }
  .t-bonus .c-btn {
    color: #1e1f24; } }

@media only screen and (min-width: 1025px) {
  .c-notavailable .c-btn {
    margin-bottom: 2px; } }

@media only screen and (min-width: 1280px) {
  .c-btn {
    transition: all 0.3s ease; }
    .c-btn:hover {
      transition: none; }
    .c-btn:hover:not(:disabled) {
      background-color: #3a7aff;
      border-color: #3a7aff; }
    .c-btn.is-gray:hover:not(:disabled) {
      background-color: #52525a;
      border-color: #52525a; }
    .c-header__button .c-btn:hover:not(:disabled) {
      background-color: #0758ff;
      border-color: #0758ff; }
    .t-bonus .c-btn:hover:not(:disabled) {
      border-color: #dfc36c;
      background-color: #dfc36c; }
    .c-quantity .c-btn:hover:not(:disabled) {
      border-color: #0758ff;
      background-color: #0758ff; }
    .t-bonus .c-quantity .c-btn:hover:not(:disabled) {
      border-color: #daba57;
      background-color: #daba57;
      color: #1e1f24; } }

.c-form {
  line-height: 1.1; }
  .c-form__groups {
    margin-bottom: 50px; }
  .c-form__group {
    max-width: 405px;
    width: 100%; }
    .c-form__group:not(:last-child) {
      margin-bottom: 30px; }
  .c-form__group-title {
    font-size: 2.4rem;
    margin-bottom: 20px; }
  .c-form__control.is-error input,
  .c-form__control.is-error select {
    border-bottom: 2px solid #db2525;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px; }
  .c-form__error-message {
    font-size: 13px;
    font-weight: normal;
    color: #696b6f;
    margin-top: 5px; }
  .c-form label {
    display: block;
    font-size: 1.8rem;
    margin-bottom: 6px;
    font-weight: 500; }
  .c-form input,
  .c-form textarea,
  .c-form select {
    outline: none;
    font-size: 1.6rem;
    text-align: left;
    font-weight: normal;
    width: 100%;
    background-color: rgba(40, 40, 46, 0.5);
    color: #696b6f;
    transition: all .2s linear;
    border: none;
    border-radius: 6px;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none; }
    .c-form input:focus:not(:disabled),
    .c-form textarea:focus:not(:disabled),
    .c-form select:focus:not(:disabled) {
      background-color: rgba(35, 36, 42, 0.5);
      color: #dedede; }
    .c-form input:disabled,
    .c-form textarea:disabled,
    .c-form select:disabled {
      cursor: not-allowed; }
  .c-form input[type="password"] {
    text-transform: none !important; }
  .c-form input,
  .c-form select {
    outline: none;
    height: 61px;
    padding: 0 20px;
    width: 100%; }
  .c-form select::-ms-expand {
    display: none; }
  .c-form textarea {
    resize: none;
    padding: 20px;
    height: 175px;
    line-height: 1.35; }
  .c-notavailable .c-form label:first-child {
    padding-left: 17px; }
  .c-notavailable .c-form .c-form__column + .c-form__column {
    margin-top: 30px; }

@media only screen and (max-width: 1024px) {
  .c-form__error-message,
  .c-form label {
    padding: 0 20px; } }

@media only screen and (min-width: 1025px) {
  .c-form input,
  .c-form textarea,
  .c-form select,
  .c-form .c-btn,
  .c-form .c-select2,
  .c-form .multiselect {
    margin-left: -20px; }
  .c-notavailable .c-form {
    min-width: 750px; }
    .c-notavailable .c-form label:first-child {
      padding-left: 0; }
    .c-notavailable .c-form .c-form__row {
      font-size: 0;
      margin-left: -11px;
      margin-right: -11px; }
    .c-notavailable .c-form .c-form__column {
      display: inline-block;
      width: 50%;
      vertical-align: bottom;
      padding-left: 11px;
      padding-right: 11px; }
      .c-notavailable .c-form .c-form__column + .c-form__column {
        margin-top: 0; } }

@media only screen and (min-width: 1280px) {
  .c-notavailable .c-form .c-form__group {
    max-width: 600px; } }

.c-input-password {
  position: relative; }
  .c-input-password__icon {
    font-size: 1.6rem;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -13px;
    color: #696b6f;
    cursor: pointer;
    padding: 5px; }
    .c-input-password__icon:before {
      font-family: icomoon; }
  .c-input-password input[type="password"] + .c-input-password__icon:before {
    content: ""; }
  .c-input-password input[type="text"] + .c-input-password__icon:before {
    content: ""; }

@media only screen and (min-width: 1025px) {
  .c-input-password__icon {
    right: 35px; } }

.c-select {
  position: relative; }
  .c-select__icon {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -12px;
    font-size: 14px;
    padding: 5px;
    color: #696b6f; }

.multiselect {
  min-height: 61px;
  cursor: pointer; }
  .multiselect__tags {
    background-color: rgba(40, 40, 46, 0.5);
    color: #696b6f;
    border: none;
    min-height: 61px;
    padding: 0 50px 0 20px;
    border-radius: 6px; }
  .multiselect__spinner {
    background: #202023;
    width: 50px;
    height: 59px; }
  .multiselect__select {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 59px;
    padding: 0; }
    .multiselect__select:before {
      content: '\e909';
      display: block;
      position: static;
      margin-top: 0;
      font-family: icomoon;
      font-size: 14px;
      color: #696b6f;
      border: none; }
  .multiselect__single {
    margin-bottom: 0;
    padding: 0;
    line-height: 61px;
    background: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal; }
  .multiselect__input {
    padding: 0;
    margin-bottom: 0;
    background: none !important;
    transition: none !important; }
  .multiselect__content {
    width: 100%; }
    .multiselect__content-wrapper {
      margin: 5px 0;
      background-color: #191919;
      border: none !important; }
  .multiselect__option {
    color: #696b6f;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .multiselect__option--highlight {
      background-color: rgba(40, 40, 46, 0.5); }
    .multiselect__option--selected {
      color: #fff;
      background-color: #0758ff !important; }
    .multiselect__option:after {
      display: none; }
  .multiselect.is-invalid:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #db2525; }
  .multiselect.is-invalid.multiselect--active:not(.multiselect--above):after {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .multiselect--disabled {
    background: none;
    cursor: not-allowed;
    opacity: 1;
    pointer-events: auto; }
    .multiselect--disabled .multiselect__current, .multiselect--disabled .multiselect__select {
      background: none;
      color: inherit; }

@media only screen and (min-width: 1025px) {
  .c-select__icon {
    right: 35px; } }

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative; }

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px; }

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-search--inline {
  float: left; }

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0; }

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }

.select2-results__option[aria-selected] {
  cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box; }

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-search--dropdown.select2-search--hide {
  display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; }

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold; }

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none; }

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left; }

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px; }

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0; }

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
  border-top: none; }

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--classic .select2-results__option[role=group] {
  padding: 0; }

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey; }

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white; }

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }

.c-select2 {
  display: inline-block;
  width: 100%; }

.select2-container--default .select2-selection--single {
  position: relative;
  border: none;
  outline: none;
  border-radius: 6px;
  height: 61px;
  padding: 0 20px;
  background-color: rgba(40, 40, 46, 0.5);
  color: #696b6f;
  font-size: 0;
  text-align: center;
  text-align: left;
  transition: all .2s linear; }
  .select2-container--default .select2-selection--single .help {
    display: inline-block;
    vertical-align: middle; }
  .select2-container--default .select2-selection--single:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 0.1%;
    height: 100%;
    margin-left: -.1%; }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    display: block;
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -12px;
    width: auto;
    height: auto; }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      display: none; }
    .select2-container--default .select2-selection--single .select2-selection__arrow:before {
      content: "";
      display: inline-block;
      font-family: icomoon;
      font-size: 14px;
      color: #696b6f;
      padding: 5px; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    font-size: 1.6rem;
    font-weight: normal;
    color: #696b6f;
    padding-left: 0;
    padding-right: 20px;
    transition: all .2s linear;
    line-height: 61px; }

.select2-container--default.select2-container--open .select2-selection--single {
  background-color: rgba(35, 36, 42, 0.5); }

.select2-container--default.select2-container--open .select2-selection__rendered {
  color: #dedede; }

.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #0758ff; }

.select2-container--default .select2-results > .select2-results__options {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar {
    display: none; }

.select2-dropdown {
  background-color: #28282e;
  border: none;
  border-radius: 6px; }

.select2-results__option {
  padding-left: 20px;
  padding-right: 20px;
  min-height: 36px; }

.c-radiobutton {
  position: relative;
  display: inline-block; }
  .c-radiobutton label {
    padding: 0; }
  .c-radiobutton input[type="radio"] {
    visibility: hidden;
    width: 0 !important;
    position: absolute;
    left: 0;
    top: 0;
    height: 0 !important;
    opacity: 0; }
    .c-radiobutton input[type="radio"] + label {
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0 0;
      cursor: pointer;
      color: #696b6f;
      font-size: 1.8rem; }
      .c-radiobutton input[type="radio"] + label:before {
        content: "";
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        border: 1px solid #696b6f;
        border-radius: 50%;
        top: 2px;
        left: 0; }
      .c-radiobutton input[type="radio"] + label:after {
        content: "";
        background: #dedede;
        border-radius: 50%;
        display: block;
        width: 8px;
        height: 8px;
        position: absolute;
        left: 4px;
        top: 6px;
        opacity: 0; }
      .c-radiobutton input[type="radio"] + label .help {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        padding-left: 26px; }
    .c-radiobutton input[type="radio"]:checked + label {
      color: #dedede; }
      .c-radiobutton input[type="radio"]:checked + label:after {
        opacity: 1; }
      .c-radiobutton input[type="radio"]:checked + label:before {
        border-color: #dedede; }
    .c-radiobutton input[type="radio"]:disabled + label {
      color: rgba(105, 107, 111, 0.3); }
      .c-radiobutton input[type="radio"]:disabled + label:before {
        border-color: rgba(105, 107, 111, 0.3); }
  .c-radiobutton--no-circle label {
    width: 100%;
    padding: 17px 20px 22px;
    border-radius: 6px; }
  .c-radiobutton--no-circle input[type="radio"] + label .help {
    padding-left: 0; }
  .c-radiobutton--no-circle input[type="radio"] + label:after, .c-radiobutton--no-circle input[type="radio"] + label:before {
    display: none; }
  .c-radiobutton--no-circle input[type="radio"]:checked + label {
    background-color: rgba(40, 40, 46, 0.5); }

@media only screen and (min-width: 1025px) {
  .c-radiobutton input[type="radio"]:not([disabled]) + label {
    transition: all 0.2s ease; }
    .c-radiobutton input[type="radio"]:not([disabled]) + label:hover {
      transition: none; }
  .c-radiobutton input[type="radio"]:not([disabled]):before {
    transition: all 0.2s ease; }
    .c-radiobutton input[type="radio"]:not([disabled]):before:hover {
      transition: none; }
  .c-radiobutton input[type="radio"]:not([disabled]):hover + label {
    color: #dedede; }
    .c-radiobutton input[type="radio"]:not([disabled]):hover + label:before {
      border-color: #dedede; }
  .c-radiobutton--no-circle:hover input[type="radio"] + label {
    background-color: rgba(40, 40, 46, 0.5); } }

.c-radiobutton {
  position: relative; }
  .c-radiobutton__message {
    display: none;
    max-width: 370px;
    background-color: #28282e;
    border-radius: 6px;
    padding: 17px 20px;
    margin-top: 20px;
    font-size: 1.6rem;
    color: #696b6f;
    font-weight: normal;
    line-height: 1.4;
    opacity: 0;
    visibility: hidden;
    z-index: 1; }

@media only screen and (min-width: 1025px) {
  .c-radiobutton__message {
    display: block;
    position: absolute;
    left: calc(100% + 20px);
    top: -20px;
    margin-top: 0;
    width: 26vw;
    transition: all .3s ease; }
    .c-radiobutton__message:before {
      content: "";
      position: absolute;
      left: -20px;
      right: -20px;
      top: -20px;
      bottom: -20px; }
  .c-radiobutton__message-inner {
    position: relative; }
  .c-radiobutton:hover .c-radiobutton__message {
    opacity: 1;
    visibility: visible;
    transition: none; } }

@media only screen and (min-width: 1025px) {
  .c-radiobutton__message {
    width: 370px; } }

.c-checkbox {
  position: relative; }
  .c-checkbox label {
    padding: 0; }
  .c-checkbox input[type="checkbox"] {
    visibility: hidden;
    width: 0 !important;
    position: absolute;
    left: 0;
    top: 0;
    height: 0 !important;
    opacity: 0; }
    .c-checkbox input[type="checkbox"] + label {
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0 0;
      cursor: pointer;
      color: #696b6f;
      font-size: 1.8rem; }
      .c-checkbox input[type="checkbox"] + label:before {
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        border: 2px solid #696b6f;
        position: absolute;
        top: 2px;
        left: 0; }
      .c-checkbox input[type="checkbox"] + label:after {
        content: "";
        opacity: 0;
        display: block;
        position: absolute;
        top: 6px;
        left: 2.4px;
        width: 10px;
        height: 6px;
        background-image: url("../../src/img/checkbox-checked.png"); }
      .c-checkbox input[type="checkbox"] + label .help {
        display: block;
        position: relative;
        width: 100%;
        padding-left: 22px;
        line-height: 1; }
    .c-checkbox input[type="checkbox"]:checked + label {
      color: #dedede; }
      .c-checkbox input[type="checkbox"]:checked + label:after {
        opacity: 1; }
      .c-checkbox input[type="checkbox"]:checked + label:before {
        border-color: #dedede; }
    .c-checkbox input[type="checkbox"]:disabled + label {
      cursor: not-allowed; }
  .t-catalog .c-checkbox input[type="checkbox"] + label {
    position: relative;
    font-size: 1.6rem;
    font-weight: normal; }
    .t-catalog .c-checkbox input[type="checkbox"] + label:before {
      border-width: 1px; }
    .t-catalog .c-checkbox input[type="checkbox"] + label:after {
      left: 2.75px; }
  .c-fgroup .c-checkbox + .c-checkbox {
    margin-top: 7px; }

@media only screen and (min-width: 1025px) {
  .c-checkbox input[type="checkbox"] + label {
    transition: all 0.2s ease; }
    .c-checkbox input[type="checkbox"] + label:hover {
      transition: none; }
    .c-checkbox input[type="checkbox"] + label:before {
      transition: all 0.2s ease; }
      .c-checkbox input[type="checkbox"] + label:before:hover {
        transition: none; }
  .c-checkbox input[type="checkbox"]:hover:not(:disabled) + label {
    color: #dedede; }
    .c-checkbox input[type="checkbox"]:hover:not(:disabled) + label:before {
      border-color: #dedede; } }

.c-input-number {
  color: #696b6f;
  font-size: 1.6rem; }
  .c-input-number input {
    background-color: #1b1b1e;
    border-radius: 4px;
    border: none;
    outline: none;
    width: 74px;
    height: 44px;
    padding: 15px 17px;
    text-align: right;
    -webkit-appearance: none;
    -moz-appearance: textfield; }
  .c-input-number input[type=number]::-webkit-inner-spin-button,
  .c-input-number input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .c-detail .c-input-number {
    margin-left: 22px;
    display: inline-block; }
    .c-detail .c-input-number input {
      background-color: rgba(255, 255, 255, 0.05); }
  .c-notavailable .c-input-number label {
    display: inline-block;
    color: #dedede; }
    .c-notavailable .c-input-number label:first-child {
      padding-right: 10px; }
  .c-notavailable .c-input-number input {
    color: #dedede; }

@media only screen and (min-width: 993px) {
  .c-detail .c-input-number {
    margin-left: 0;
    vertical-align: middle;
    margin-right: 18px;
    order: 1; } }

@media only screen and (min-width: 1025px) {
  .c-notavailable .c-input-number input {
    margin-left: 0; } }

.c-form-hints {
  padding-top: 20px;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.2; }
  .c-form-hints__title {
    color: #dedede;
    margin-bottom: 5px; }
  .c-form-hints__list {
    color: #696b6f; }
  .c-form-hints__list-item {
    display: inline-block;
    vertical-align: top; }

.c-radiobuttons-list__item {
  margin-bottom: 14px; }

.c-radiobuttons-list--50 .c-radiobuttons-list__item {
  margin-bottom: 12px; }

@media only screen and (min-width: 480px) {
  .c-radiobuttons-list {
    margin-bottom: 40px; }
    .c-radiobuttons-list--50 {
      margin-left: -3px;
      margin-right: -3px;
      font-size: 0; }
      .c-radiobuttons-list--50 .c-radiobuttons-list__item {
        display: inline-block;
        vertical-align: top;
        padding-left: 3px;
        padding-right: 3px;
        width: 50%; } }

.c-typical-block {
  font-weight: 500; }
  .c-typical-block__center {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .c-typical-block__const {
    position: relative; }
  .c-typical-block__section {
    font-size: 0; }
    .c-typical-block__section:not(:last-child) {
      margin-bottom: 50px; }
  .c-typical-block__title {
    font-size: 2.6rem;
    margin-bottom: 20px;
    line-height: 1.2; }
    .c-typical-block__title--accent-color {
      color: #daba57; }
  .c-typical-block__subtitle {
    font-size: 2.2rem;
    margin-bottom: 20px;
    line-height: 1; }
  .c-typical-block__subtitle-2 {
    font-size: 1.8rem;
    margin-bottom: 20px; }
  .c-typical-block__text {
    max-width: 450px; }
    .c-typical-block__text p,
    .c-typical-block__text ul {
      font-size: 1.8rem;
      line-height: 1.4;
      color: #696b6f; }
      .c-typical-block__text p:not(:last-child),
      .c-typical-block__text ul:not(:last-child) {
        margin-bottom: 25px; }
    .c-typical-block__text p {
      max-width: 410px; }
      .c-typical-block__text p a {
        display: inline; }
      .c-typical-block__text p + ul {
        margin-top: -15px; }
    .c-typical-block__text .c-typical-block__title {
      margin-bottom: 8px;
      line-height: 1.5; }
    .c-typical-block__text--white p {
      color: #dedede; }
  .c-typical-block .c-btn {
    max-width: 365px;
    width: 100%; }
  .c-typical-block .c-form .c-btn {
    max-width: 405px; }

@media only screen and (min-width: 480px) {
  .c-typical-block .c-form__group,
  .c-typical-block .c-form .c-btn {
    max-width: none;
    width: 405px; } }

@media only screen and (max-width: 767px) {
  .c-typical-block__title {
    padding-right: 80px; }
  .c-typical-block__center-mob {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .c-typical-block__sidebar {
    display: inline-block; } }

@media only screen and (min-width: 768px) {
  .c-typical-block {
    font-size: 0; }
    .c-typical-block__const, .c-typical-block__var {
      display: inline-block;
      vertical-align: top; }
    .c-typical-block__const {
      width: 250px;
      margin-right: -250px;
      padding-top: 10px; }
    .c-typical-block__var {
      padding-left: 270px;
      width: 100%; }
    .c-typical-block__padding0 {
      padding-left: 0;
      max-width: 430px;
      margin-left: auto;
      margin-right: auto; }
    .c-typical-block__title {
      margin-bottom: 50px;
      font-size: 3rem; } }

@media only screen and (min-width: 1025px) {
  .c-typical-block__const {
    width: 280px;
    margin-right: -280px; }
  .c-typical-block__var {
    padding-left: 330px; }
  .c-typical-block__padding0 {
    min-width: 430px;
    padding-left: 0; }
  .c-typical-block .c-btn {
    margin-left: -20px; } }

@media only screen and (max-width: 1024px) {
  .l-mob-space-left {
    padding-left: 20px; } }

.l-space-top {
  padding-top: 40px; }

.c-link {
  font-size: 1.6rem;
  margin-top: 25px; }

@media only screen and (min-width: 1025px) {
  .c-link {
    margin-left: -40px; } }

.c-dotted-link {
  display: inline;
  font-size: 1.6rem;
  color: #696b6f;
  border-bottom: 1px dotted #696b6f; }
  .c-dotted-link--fw-normal {
    font-weight: normal; }

@media only screen and (min-width: 1025px) {
  .c-dotted-link {
    transition: all 0.2s ease; }
    .c-dotted-link:hover {
      transition: none; }
    .c-dotted-link:hover {
      color: #dedede; } }

.c-gray-link {
  color: #696b6f; }

@media only screen and (min-width: 1025px) {
  .c-gray-link {
    transition: all 0.2s ease; }
    .c-gray-link:hover {
      transition: none; }
    .c-gray-link:hover {
      color: #dedede; } }

.c-error__img {
  max-width: 480px;
  width: 100%;
  margin-bottom: 30px; }
  .c-error__img img {
    display: block;
    width: 100%; }

.c-error__text {
  font-size: 1.8rem; }
  .c-error__text p:not(:last-child) {
    margin-bottom: 15px; }

.c-error .c-typical-block__title {
  padding: 0; }

@media only screen and (min-width: 1025px) {
  .c-error {
    font-size: 0; }
    .c-error__img, .c-error__text {
      display: inline-block;
      vertical-align: middle; }
    .c-error__img {
      position: relative;
      width: 480px;
      margin-right: -480px;
      padding-top: 65px;
      margin-bottom: 0; }
    .c-error__text {
      width: 100%;
      padding-left: 515px; } }

.c-back-link {
  font-size: 1.8rem;
  font-weight: normal;
  color: #696b6f; }
  .c-back-link:before {
    content: "";
    font-family: icomoon;
    display: inline-block;
    vertical-align: middle;
    font-size: 14px; }

@media only screen and (min-width: 768px) {
  .c-back-link {
    position: relative; }
    .c-back-link:before {
      position: absolute;
      right: 100%;
      top: 50%;
      margin-right: 5px;
      margin-top: -7px; } }

@media only screen and (min-width: 1025px) {
  .c-back-link {
    transition: all 0.2s ease; }
    .c-back-link:hover {
      transition: none; }
    .c-back-link:hover {
      color: #dedede; } }

.u-back-link-wrapper {
  line-height: 1;
  display: block; }

.c-accent-block {
  font-size: 2.4rem;
  line-height: 1.3;
  background-color: rgba(40, 40, 46, 0.5);
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 30px; }
  .c-accent-block__text {
    color: #daba57; }

@media only screen and (min-width: 1025px) {
  .c-accent-block {
    margin-left: -20px; } }

.c-cabinet-ctrl {
  font-size: 0; }
  .c-cabinet-ctrl__item {
    display: inline-block;
    vertical-align: top; }
    .c-cabinet-ctrl__item:not(:last-child) {
      margin-right: 15px; }
  .c-cabinet-ctrl__link {
    color: #696b6f;
    font-size: 1.8rem; }

@media only screen and (max-width: 767px) {
  .c-cabinet-ctrl--mob-top {
    position: absolute;
    top: 0;
    right: 0;
    line-height: 3rem; } }

@media only screen and (min-width: 1025px) {
  .c-cabinet-ctrl__link {
    transition: all 0.2s ease; }
    .c-cabinet-ctrl__link:hover {
      transition: none; }
    .c-cabinet-ctrl__link:hover {
      color: #dedede; } }

.c-cabinet-menu {
  font-size: 0; }
  .c-cabinet-menu:not(:last-child) {
    margin-bottom: 30px; }
  .c-cabinet-menu__link {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.2;
    color: #696b6f; }
  .c-cabinet-menu__item {
    display: inline-block;
    vertical-align: top; }
    .c-cabinet-menu__item:not(:last-child) {
      margin-bottom: 15px; }
    .c-cabinet-menu__item.is-active .c-cabinet-menu__link {
      color: #dedede; }
    .c-cabinet-menu__item--accent.is-active .c-cabinet-menu__link {
      color: #daba57; }

@media only screen and (max-width: 767px) {
  .c-cabinet-menu {
    display: flex;
    flex-direction: column; }
    .c-cabinet-menu.is-open .c-cabinet-menu__item {
      display: block; }
    .c-cabinet-menu__item {
      display: none;
      order: 1; }
      .c-cabinet-menu__item.is-active {
        display: block;
        order: 0; }
        .c-cabinet-menu__item.is-active .c-cabinet-menu__link {
          position: relative;
          padding-right: 15px; }
          .c-cabinet-menu__item.is-active .c-cabinet-menu__link:after {
            position: absolute;
            content: "";
            right: 0;
            top: 50%;
            bottom: auto;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            margin-top: 1px;
            opacity: .7;
            border-style: solid;
            border-width: 7px 4px 0 4px;
            border-color: #fff transparent transparent transparent; }
    .c-cabinet-menu:not(:last-child) {
      margin-bottom: 40px; } }

@media only screen and (min-width: 768px) {
  .c-cabinet-menu:not(:last-child) {
    margin-bottom: 50px; }
  .c-cabinet-menu__item {
    display: block; } }

@media only screen and (min-width: 1025px) {
  .c-cabinet-menu__link {
    transition: all 0.2s ease; }
    .c-cabinet-menu__link:hover {
      transition: none; }
    .c-cabinet-menu__link:hover {
      color: #dedede; }
  .c-cabinet-menu__item--accent:hover .c-cabinet-menu__link {
    color: #daba57; } }

.c-cabinet-info {
  display: inline-block;
  vertical-align: top;
  font-size: 1.8rem;
  line-height: 1.35;
  width: 100%; }
  .c-cabinet-info p {
    font-size: inherit;
    line-height: inherit; }
  .c-cabinet-info__text {
    display: block; }
    .c-cabinet-info__text:not(:last-child) {
      margin-bottom: 18px; }
  .c-cabinet-info--with-marker .c-cabinet-info__text {
    position: relative;
    color: #696b6f; }
    .c-cabinet-info--with-marker .c-cabinet-info__text:before {
      content: "";
      position: absolute;
      right: 100%;
      top: -3px;
      margin-right: 12px;
      font-size: 2.2rem;
      font-family: icomoon;
      color: #0758ff; }

.c-cabinet-info-wrapper .c-cabinet-info:not(:last-child) {
  margin-bottom: 40px; }

@media only screen and (min-width: 480px) {
  .c-cabinet-info-wrapper {
    margin: 0 -10px; }
    .c-cabinet-info-wrapper .c-cabinet-info {
      width: 50%;
      padding: 0 10px; } }

@media only screen and (min-width: 768px) {
  .c-cabinet-info--with-marker .c-cabinet-info__text:before {
    margin-right: 6px; }
  .c-cabinet-info-wrapper .c-cabinet-info:not(:last-child) {
    margin-bottom: 20px; } }

@media only screen and (min-width: 1025px) {
  .c-cabinet-info-wrapper .c-cabinet-info {
    width: 33.33333%; } }

@media only screen and (min-width: 1367px) {
  .c-cabinet-info-wrapper {
    margin: 0 -45px; }
    .c-cabinet-info-wrapper .c-cabinet-info {
      width: auto;
      padding: 0 45px; } }

.c-history-table {
  text-align: left;
  width: 100%;
  line-height: 1.2; }
  .c-history-table th,
  .c-history-table td {
    font-weight: normal;
    padding: 15px 10px; }
  .c-history-table th {
    font-size: 1.8rem;
    vertical-align: top; }
  .c-history-table td {
    font-size: 1.6rem;
    vertical-align: top; }
    .c-history-table td.c-history-table__sum-td {
      white-space: nowrap;
      line-height: 1.4; }
      .c-history-table td.c-history-table__sum-td--bold {
        font-weight: 500; }
  .c-history-table__sum-row td {
    font-size: 2.2rem;
    color: #dedede !important;
    font-weight: 500; }
  .c-history-table__accent-str {
    display: block;
    font-size: 1.4rem; }
  .c-history-table__images {
    display: block;
    font-size: 0;
    margin-top: 5px; }
  .c-history-table__image {
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    height: 60px;
    margin: 5px 0;
    border-radius: 6px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
    .c-history-table__image:not(:last-child) {
      margin-right: 4px; }
  .c-history-table__plus {
    display: inline-block;
    vertical-align: middle;
    font-size: 3rem;
    font-weight: 300; }
  .c-history-table__description-td {
    line-height: 1.3; }
    .c-history-table__description-td-title {
      font-weight: 500;
      font-size: 1.8rem; }
    .c-history-table__description-td-text {
      margin-bottom: 3px; }
    .c-history-table__description-td--no-hover {
      color: #696b6f !important; }
  .c-history-table__ttn {
    margin-top: 10px; }

@media only screen and (min-width: 901px) {
  .c-history-table {
    margin-left: -20px;
    border-collapse: separate;
    border-spacing: 0 15px; }
    .c-history-table td {
      background-color: #1b1b1e;
      color: #696b6f; }
      .c-history-table td:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px; }
      .c-history-table td:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px; }
    .c-history-table th:first-child,
    .c-history-table td:first-child {
      padding-left: 20px; }
    .c-history-table th:last-child,
    .c-history-table td:last-child {
      padding-right: 20px; }
    .c-history-table th {
      padding-bottom: 0;
      padding-top: 0; }
    .c-history-table__sum-row td {
      background-color: transparent !important; }
    .c-history-table__image {
      width: 6.1vw;
      height: 6.1vw; }
    .c-history-table__sum-td, .c-history-table__sum-th {
      text-align: right; } }

@media only screen and (min-width: 1025px) {
  .c-history-table {
    max-width: 800px; }
    .c-history-table td {
      vertical-align: baseline; }
    .c-history-table__line {
      white-space: nowrap; }
    .c-history-table__images {
      display: inline-block;
      vertical-align: middle;
      margin-left: 1vw;
      margin-top: 0; }
    .c-history-table__image {
      margin: 0; }
      .c-history-table__image:not(:last-child) {
        margin-right: 10px; }
    .c-history-table tr td {
      transition: all .2s ease; }
    .c-history-table tr:hover td {
      transition: none;
      color: #dedede;
      background-color: rgba(35, 36, 42, 0.5); }
    .c-history-table tr:hover .c-history-table__accent-str {
      color: #daba57; } }

@media only screen and (min-width: 1367px) {
  .c-history-table th,
  .c-history-table td {
    padding-left: 15px;
    padding-right: 15px; }
  .c-history-table__images {
    margin-left: 25px; }
  .c-history-table__image {
    width: 85px;
    height: 85px; } }

@media only screen and (max-width: 900px) {
  .c-history-table thead {
    display: none; }
  .c-history-table tr {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #1b1b1e;
    border-radius: 6px; }
    .c-history-table tr:not(:last-child) {
      margin-bottom: 15px; }
    .c-history-table tr.c-history-table__sum-row {
      background-color: transparent !important;
      display: flex;
      flex-direction: row; }
      .c-history-table tr.c-history-table__sum-row td {
        padding-left: 0;
        padding-right: 0; }
        .c-history-table tr.c-history-table__sum-row td.c-history-table__sum-row-td {
          padding-left: 17px;
          padding-right: 17px; }
  .c-history-table td {
    display: block;
    order: 1; }
    .c-history-table td.c-history-table__date-td {
      order: 0;
      color: #696b6f;
      padding-bottom: 0; }
    .c-history-table td.c-history-table__number-td {
      padding-bottom: 0; }
    .c-history-table td.c-history-table__sum-td {
      max-width: 50%; }
    .c-history-table td.c-history-table__status-td {
      position: absolute;
      bottom: 0;
      right: 0;
      max-width: 50%; }
  .c-history-table td {
    padding-left: 17px;
    padding-right: 17px; }
  .c-history-table__accent-str {
    color: #daba57; } }

@media only screen and (max-width: 767px) {
  .c-history-table tr {
    margin-left: -17px;
    margin-right: -17px; } }

.c-order-view {
  max-width: 940px; }
  .c-order-view__const {
    padding-top: 25px; }
  .c-order-view .c-typical-block__subtitle {
    margin-bottom: 5px; }
  .c-order-view .c-cabinet-info__text {
    color: #696b6f; }
  .c-order-view .c-cabinet-info:not(:last-child) {
    margin-bottom: 25px; }
  .c-order-view .c-cabinet-info__item {
    width: 100%; }
  .c-order-view .c-typical-block__text {
    margin-bottom: 50px; }
  .c-order-view .c-history-table td {
    vertical-align: top; }
  .c-order-view .c-history-table .c-history-table__image {
    width: 85px;
    height: 85px; }

@media only screen and (max-width: 767px) {
  .c-order-view .c-history-table td {
    display: inline-block; }
    .c-order-view .c-history-table td:not(:last-child) {
      padding-bottom: 0; } }

@media only screen and (min-width: 768px) {
  .c-order-view .c-history-table td {
    padding-top: 20px;
    padding-bottom: 20px; } }

@media only screen and (min-width: 1200px) {
  .c-order-view {
    font-size: 0; }
    .c-order-view__var, .c-order-view__const {
      display: inline-block;
      vertical-align: top; }
    .c-order-view__var {
      padding-right: 370px;
      width: 100%; }
    .c-order-view__const {
      width: 320px;
      margin-left: -320px; } }

.c-cart-good {
  position: relative;
  background-color: rgba(40, 40, 46, 0.5);
  border-radius: 6px;
  padding: 35px 36px 20px 17px;
  margin-left: -17px;
  margin-right: -17px;
  font-size: 0;
  color: #696b6f; }
  .c-cart-good:not(:last-child) {
    margin-bottom: 15px; }
  .c-cart-good__const, .c-cart-good__var {
    display: inline-block;
    vertical-align: top; }
  .c-cart-good__const {
    position: relative;
    width: 90px;
    margin-right: -90px;
    z-index: 1; }
  .c-cart-good__var {
    position: relative;
    padding-left: 105px;
    width: 100%; }
  .c-cart-good__img {
    display: block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 90px;
    height: 90px;
    border-radius: 6px; }
  .c-cart-good__text {
    max-width: 350px;
    width: 100%;
    line-height: 1.3;
    margin-bottom: 15px;
    padding-right: 8px; }
  .c-cart-good__title {
    font-size: 1.8rem;
    margin-bottom: 3px; }
  .c-cart-good__description {
    font-weight: normal;
    font-size: 1.6rem; }
  .c-cart-good__link-wrapper {
    padding-right: 10px; }
  .c-cart-good__link {
    display: inline;
    border-bottom: 1px dotted #696b6f;
    font-size: 1.4rem;
    font-weight: normal; }
  .c-cart-good__price {
    font-size: 2rem;
    line-height: 44px; }
  .c-cart-good__count {
    font-size: 1.8rem;
    font-weight: normal; }
  .c-cart-good__availability {
    font-weight: normal;
    font-size: 1.4rem;
    max-width: 105px;
    padding-right: 15px;
    overflow: hidden; }
  .c-cart-good__remove {
    position: absolute;
    left: calc(100% - 5px);
    bottom: 100%;
    font-size: 2.6rem;
    font-weight: normal;
    line-height: 1;
    padding: 5px;
    cursor: pointer; }
  .c-cart-good__line-group {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: -15px;
    justify-content: space-between;
    flex-wrap: wrap; }
  .c-cart-good--error {
    border: 2px solid #db2525; }

@media only screen and (max-width: 767px) {
  .c-cart-good__text {
    min-height: 90px; }
  .c-cart-good__availability {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 1.2; }
  .c-cart-good__price, .c-cart-good__title {
    color: #dedede; }
  .c-cart-good__line-group {
    margin-left: -105px;
    padding-left: 105px; }
  .c-cart-good--error .c-cart-good__line-group {
    align-items: flex-start;
    padding-left: 0; }
  .c-cart-good--error .c-cart-good__availability {
    position: static;
    max-width: none;
    font-size: 1.6rem;
    color: #dedede;
    margin-bottom: 2px; }
  .c-cart-good--error .c-cart-good__price {
    line-height: 1; } }

@media only screen and (min-width: 768px) {
  .c-cart-good {
    padding-left: 20px;
    padding-right: 46px;
    padding-top: 20px;
    margin-left: 0;
    margin-right: 0; }
    .c-cart-good__const {
      width: 110px;
      margin-right: -110px;
      padding-top: 4px; }
    .c-cart-good__var {
      padding-left: 130px; }
    .c-cart-good__img {
      width: 110px;
      height: 110px; }
    .c-cart-good__text {
      margin-bottom: 10px; }
    .c-cart-good__link-wrapper {
      max-width: 120px;
      display: inline-block;
      vertical-align: middle;
      padding: 0 13px; }
    .c-cart-good__link {
      font-size: 1.3rem; }
    .c-cart-good__price {
      font-size: 1.8rem; }
    .c-cart-good__availability {
      display: inline-block;
      vertical-align: middle;
      background-color: #1b1b1e;
      padding: 10px 17px;
      max-width: 200px;
      font-size: 1.6rem; }
    .c-cart-good__remove {
      top: -5px;
      bottom: inherit; } }

@media only screen and (min-width: 1025px) {
  .c-cart-result {
    margin-left: -20px; }
  .c-cart-good {
    margin-left: -20px;
    transition: all 0.2s ease; }
    .c-cart-good:hover {
      transition: none; }
    .c-cart-good__title, .c-cart-good__price, .c-cart-good__remove, .c-cart-good__link {
      transition: all .2s ease; }
    .c-cart-good:hover {
      background-color: rgba(35, 36, 42, 0.5); }
      .c-cart-good:hover .c-cart-good__title, .c-cart-good:hover .c-cart-good__price, .c-cart-good:hover .c-cart-good__remove, .c-cart-good:hover .c-cart-good__link {
        color: #dedede;
        transition: none; }
      .c-cart-good:hover .c-cart-good__link {
        border-bottom-color: #dedede; } }

.c-cart-result {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 20px; }

.c-cart-form {
  padding-top: 40px; }
  .c-cart-form .c-form__group--textarea {
    width: auto;
    max-width: none; }
  .c-cart-form__login {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px; }
    .c-cart-form__login .c-btn {
      width: 135px;
      margin: 0 7px; }

.c-self-delivery .c-cabinet-info:not(:last-child) {
  margin-bottom: 30px; }

.c-cart-btn {
  text-align: center;
  padding-top: 30px; }

@media only screen and (max-width: 767px) {
  .c-self-delivery {
    padding-left: 26px; } }

@media only screen and (min-width: 480px) {
  .c-cart-result .c-bonus-info {
    padding-right: 20px; } }

@media only screen and (min-width: 768px) {
  .c-cart {
    width: 610px; }
    .c-cart .c-cart-address {
      width: 640px; }
    .c-cart-form__login .c-btn {
      width: 170px; } }

@media only screen and (min-width: 1025px) {
  .c-cart-result {
    padding: 20px 30px 20px 150px; }
    .c-cart-result .c-bonus-info {
      margin-left: -26px; }
  .c-cart-form {
    padding-left: 130px; }
    .c-cart-form .c-form__group--textarea {
      margin-left: -130px;
      margin-right: -20px; }
    .c-cart-form__login {
      justify-content: flex-start;
      margin-left: -7px; } }

.c-bonus-info {
  position: relative;
  font-weight: normal;
  font-size: 0; }
  .c-bonus-info__icon, .c-bonus-info__text {
    display: inline-block;
    vertical-align: top; }
  .c-bonus-info__icon {
    position: relative;
    width: 18px;
    margin-right: -18px;
    font-size: 1.8rem;
    line-height: 1.2;
    z-index: 1; }
  .c-bonus-info__text {
    color: #daba57;
    padding-left: 26px;
    font-size: 1.6rem;
    line-height: 1.2;
    margin-bottom: 10px;
    padding-right: 5px;
    max-width: 155px;
    z-index: 1; }
  .c-bonus-info__details {
    display: none;
    padding: 20px;
    width: 100%;
    font-size: 1.6rem;
    background-color: #28282e;
    color: #696b6f;
    border-radius: 6px;
    line-height: 1.35; }
    .c-bonus-info__details a {
      color: #daba57; }
  .c-bonus-info__link {
    color: #daba57; }

@media only screen and (min-width: 480px) {
  .c-bonus-info__details {
    max-width: 370px; }
  .c-bonus-info__text {
    max-width: 225px; } }

@media only screen and (min-width: 1025px) {
  .c-bonus-info__text {
    position: relative; }
  .c-bonus-info__details {
    display: block;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    right: calc(100% + 14px);
    top: -20px;
    width: 26vw;
    transition: all .3s ease; }
    .c-bonus-info__details:before {
      content: "";
      display: block;
      position: absolute;
      top: -20px;
      right: -245px;
      bottom: -20px;
      left: -20px; }
  .c-bonus-info__details-inner {
    position: relative; }
  .c-bonus-info:hover .c-bonus-info__details {
    opacity: 1;
    visibility: visible;
    transition: none; } }

@media only screen and (min-width: 1367px) {
  .c-bonus-info__details {
    width: 370px;
    padding-right: 35px; } }

.c-cart-sum {
  font-size: 1.8rem;
  text-align: right;
  line-height: 1; }
  .c-cart-sum__title {
    margin-bottom: 20px; }
  .c-cart-sum__accent {
    font-size: 3rem; }

.c-cart-address {
  font-size: 0;
  margin: 0 -10px; }
  .c-cart-address__item {
    display: inline-block;
    vertical-align: top;
    padding: 0 10px;
    max-width: 320px;
    width: 100%;
    margin-bottom: 22px; }
    .c-cart-address__item .c-radiobutton {
      width: 100%; }

@media only screen and (min-width: 1025px) {
  .c-form .c-cart-address {
    margin-left: -30px; } }

.c-order-confirmation .c-order-view {
  max-width: 730px; }

.c-order-confirmation .c-cart-good {
  position: relative;
  border-radius: 0;
  padding-top: 20px;
  padding-bottom: 25px;
  padding-right: 20px; }
  .c-order-confirmation .c-cart-good:not(:last-child) {
    margin-bottom: 0; }
    .c-order-confirmation .c-cart-good:not(:last-child):after {
      content: "";
      position: absolute;
      left: 20px;
      right: 20px;
      bottom: 0;
      height: 1px;
      background-color: rgba(105, 107, 111, 0.3); }
  .c-order-confirmation .c-cart-good:first-child {
    padding-top: 35px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px; }
  .c-order-confirmation .c-cart-good:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px; }
  .c-order-confirmation .c-cart-good__const {
    width: 85px;
    margin-right: -85px; }
  .c-order-confirmation .c-cart-good__var {
    padding-left: 105px; }
  .c-order-confirmation .c-cart-good__img {
    width: 85px;
    height: 85px; }
  .c-order-confirmation .c-cart-good__title {
    line-height: 1.3;
    margin-bottom: 10px; }
  .c-order-confirmation .c-cart-good__price, .c-order-confirmation .c-cart-good__count {
    color: #dedede;
    font-size: 1.6rem;
    line-height: 1; }
  .c-order-confirmation .c-cart-good__line-group {
    margin-right: 0; }

.c-order-confirmation .c-cart-result {
  background-color: rgba(40, 40, 46, 0.5);
  padding: 30px 20px 35px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px; }

.c-order-confirmation .c-cart-sum__accent {
  font-size: 2.4rem;
  margin-bottom: 5px; }

.c-order-confirmation .c-cart-sum__bonus {
  font-size: 1.6rem; }

@media only screen and (max-width: 767px) {
  .c-order-confirmation .c-cart-result {
    margin-left: -17px;
    margin-right: -17px; } }

@media only screen and (min-width: 768px) {
  .c-order-confirmation .c-cart-good {
    padding-left: 30px;
    padding-right: 30px; }
    .c-order-confirmation .c-cart-good:not(:last-child):after {
      left: 30px;
      right: 30px; }
  .c-order-confirmation .c-cart-result {
    padding-right: 30px;
    padding-left: 30px; } }

@media only screen and (min-width: 1025px) {
  .c-order-confirmation .c-order-view {
    font-size: 0; }
    .c-order-confirmation .c-order-view__var, .c-order-confirmation .c-order-view__const {
      display: inline-block;
      vertical-align: top; }
    .c-order-confirmation .c-order-view__var {
      padding-right: 390px;
      width: 100%; }
    .c-order-confirmation .c-order-view__const {
      width: 320px;
      margin-left: -320px; } }
