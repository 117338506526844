.c-search {
  height: 100%;
  transition: width .3s;
  .c-search__close {
    display: none; }
  .c-search__submit {
    display: none;
    pointer-events: none; }
  .c-search__button {
    -webkit-backface-visibility: hidden;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: $space;
    padding-right: $space/2; }
  .c-search__controls {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 80%;
    input {
      display: none;
      height: 33px;
      padding-left: 20px;
      padding-right: 45px;
      padding-top: 12px;
      padding-bottom: 10.5px;
      background-color: #0748cd;
      border: none;
      outline: 0;
      font-size: 1.6rem;
      font-weight: normal;
      text-align: left;
      width: 100%;
      border: none;
      transition: all .1s linear 0s;
      border-radius: 22px;
      &::placeholder {
        color: rgba(#fff, .5);
        font-weight: normal; }
      &:focus {
        color: #fff;
        text-transform: none;
        &::placeholder {
          color: transparent; } }
      @include input-placeholder {
        color: rgba(#fff, .5);
        font-weight: normal; } }
    input[type="search"] {
      -webkit-appearance: none; } }
  &.is-open {
    width: 100%;
    position: absolute;
    z-index: 10;
    background-color: $color-blue;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    .c-search__controls {
      padding-left: $space; }
    .c-search__sign {
      position: absolute;
      top: 0;
      right: 0px;
      padding-right: $space; }
    .c-search__close {
      display: inline-block; }
    .c-search__button {
      display: none; }
    .c-search__submit {
      display: block;
      pointer-events: auto;
      height: 33px; }
    input {
      display: inline-block; } } }

@include MQ(480px) {
  .c-search {
    .c-search__button {
      padding-right: $space; } } }

@include MQ(678px) {
  .c-search {
    .c-search__form {
      padding-left: $space; }
    .c-search__controls {
      min-width: 340px;
      input {
        display: block;
        width: 100%; }
      .c-search__submit {
        height: 33px;
        pointer-events: auto;
        position: absolute;
        display: block;
        padding-right: $space;
        right: 0;
        top: 50%;
        transform: translateY(-50%); }
      .c-search__button {
        display: none; } } } }

@include MQ(1280px) {
  .c-search {
    &.is-open {
      background-color: #222327;
      .c-search__form {
        padding-top: 13px;
        padding-bottom: 13px; } }
    .c-search__form {
      padding-right: $space; }
    .c-search__close {
      &:before,
      &:after {
        background-color: $color-blue; } }
    .c-search__controls {
      input {
        background-color: #39393d;
        height: 43px; } } } }
