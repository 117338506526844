h1,
h2,
h3,
h4,
h5,
h6 {
	text-transform: none;
	letter-spacing: 0;
	font-weight: 500;
	transform: translateX(-2px);
	line-height: 1.42; }

.t1 {
	font-size: 3rem;
	line-height: 1; }

.t2 {
	font-size: 2.4rem; }

@include MQ(993px) {
	.t1 {
		font-size: 3.6rem; }
	.t2 {
		font-size: 3rem; } }

