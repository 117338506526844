@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon/icomoon.eot');
  src: url('../fonts/icomoon/icomoon.eot') format("embedded-opentype"), url('../fonts/icomoon/icomoon.woff2') format("woff2"), url('../fonts/icomoon/icomoon.ttf') format("truetype"), url('../fonts/icomoon/icomoon.woff') format("woff"), url('../fonts/icomoon/icomoon.svg') format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/nolocalgothem/GothamPro.eot');
  src: url('../fonts/nolocalgothem/GothamPro.eot') format("embedded-opentype"), url('../fonts/nolocalgothem/GothamPro.woff2') format("woff2"), url('../fonts/nolocalgothem/GothamPro.woff') format("woff"), url('../fonts/nolocalgothem/GothamPro.ttf') format("truetype"), url('../fonts/nolocalgothem/GothamPro.svg') format("svg");
  font-weight: normal;
  font-style: normal; }


@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/nolocalgothem/GothamPro-Medium.eot');
  src: url('../fonts/nolocalgothem/GothamPro-Medium.eot?#iefix') format("embedded-opentype"), url('../fonts/nolocalgothem/GothamPro-Medium.woff2') format("woff2"), url('../fonts/nolocalgothem/GothamPro-Medium.woff') format("woff"), url('../fonts/nolocalgothem/GothamPro-Medium.ttf') format("truetype"), url('../fonts/nolocalgothem/GothamPro-Medium.svg') format("svg");
  font-weight: 500;
  font-style: normal; }


[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-logo-ico:before {
  content: "\e910"; }

.icon-more-ico:before {
  content: "\e917"; }

.icon-loc:before {
  content: "\e916"; }

.icon-info:before {
  content: "\e915"; }

.icon-arrow-left:before {
  content: "\e914"; }

.icon-monitor-ico:before {
  content: "\e913"; }

.icon-mirrolab-ico:before {
  content: "\e923"; }

.icon-print-ico:before {
  content: "\e900"; }

.icon-micro-ico:before {
  content: "\e901"; }

.icon-laptop-ico:before {
  content: "\e902"; }

.icon-phone-ico:before {
  content: "\e903"; }

.icon-pc-ico:before {
  content: "\e904"; }

.icon-usb-ico:before {
  content: "\e905"; }

.icon-sew-ico:before {
  content: "\e906"; }

.icon-radio-ico:before {
  content: "\e907"; }

.icon-bonus-ico:before {
  content: "\e908"; }

.icon-arrow-ico:before {
  content: "\e909"; }

.icon-cabinet-ico:before {
  content: "\e90a"; }

.icon-cart-ico:before {
  content: "\e90b"; }

.icon-tick-ico:before {
  content: "\e90c"; }

.icon-eyecross-ico:before {
  content: "\e90d"; }

.icon-eye-ico:before {
  content: "\e90e"; }

.icon-filter-ico:before {
  content: "\e90f"; }

.icon-menu-ico:before {
  content: "\e911"; }

.icon-search-ico:before {
  content: "\e912"; }
