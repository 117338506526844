.c-bonus-info {
  position: relative;
  font-weight: normal;
  font-size: 0;
  &__icon,
  &__text {
    display: inline-block;
    vertical-align: top; }
  &__icon {
    position: relative;
    width: 18px;
    margin-right: -18px;
    font-size: 1.8rem;
    line-height: 1.2;
    z-index: 1; }
  &__text {
    color: $color-text-yellow;
    padding-left: 26px;
    font-size: 1.6rem;
    line-height: 1.2;
    margin-bottom: 10px;
    padding-right: 5px;
    max-width: 155px;
    z-index: 1; }
  &__details {
    display: none;
    padding: 20px;
    width: 100%;
    font-size: 1.6rem;
    background-color: $accent-bg-color;
    color: $color-text-gray;
    border-radius: 6px;
    line-height: 1.35;
    a, {
      color: $color-text-yellow; } }
  &__link {
    color: $color-text-yellow; } }

@include MQ(480px) {
  .c-bonus-info {
    &__details {
      max-width: 370px; }
    &__text {
      max-width: 225px; } } }

@include MQ(1025px) {
  .c-bonus-info {
    &__text {
      position: relative; }
    &__details {
      display: block;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      right: calc(100% + 14px);
      top: -20px;
      width: 26vw;
      transition: all .3s ease;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: -20px;
        right: -245px;
        bottom: -20px;
        left: -20px; } }
    &__details-inner {
      position: relative; }
    &:hover {
      .c-bonus-info__details {
        opacity: 1;
        visibility: visible;
        transition: none; } } } }

@include MQ(1367px) {
  .c-bonus-info {
    &__details {
      width: 370px;
      padding-right: 35px; } } }
