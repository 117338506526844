.c-burger {
  display: block;
  padding-left: $space;
  padding-right: $space;
  padding-top: 15px;
  padding-bottom: 15px;
  .c-burger__box {
    height: 16px;
    width: 22px;
    display: inline-block;
    vertical-align: middle;
    position: relative; }
  .c-burger__label {
    display: inline-block;
    vertical-align: middle;
    font-weight: normal; }
  .c-burger__inner {
    display: block;
    position: absolute;
    width: 22px;
    bottom: 0;
    top: 0;
    margin: auto;
    background-color: #fff;
    height: 1.5px;
    transition: .3s;
    will-change: transform;
    &:after,
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 22px;
      background-color: #fff;
      height: 1.5px; }
    &:after {
      bottom: -6px; }
    &:before {
      top: -6px; } }
  &.is-open {
    .c-burger__inner {
      transition: .3s;
      transform: translate3d(0,0,0) rotate(45deg);
      &:before {
        opacity: 0; }
      &:after {
        transform: translate3d(0,-6px,0) rotate(-90deg); } } } }

@include MQ(1280px) {
  .c-burger {
    height: 72px;
    background-color: #2c2c33;
    .c-burger__inner {
      background-color: rgba(#fff, .5);
      &:after,
      &:before {
        background-color: rgba(#fff, .5); } } } }
