$arrow: "\e909";

.drilldown {
  a {
    background: initial; } }
.drilldown {
  .is-drilldown-submenu {
    a {
      padding: initial; } } }

.is-drilldown,
.is-drilldown-submenu {
  min-height: 100vh; }

.drilldown {
  .is-drilldown-submenu-parent {
    > a {
      &:after {
        content: $arrow;
        @include icomoon;
        display: inline-block;
        border: none;
        position: relative;
        font-size: 1.3rem;
        right: initial;
        width: unset;
        height: unset;
        top: unset;
        margin-left: 5px;
        transform: rotate(-90deg); } }
    .t-bonus {
      > a {
        &:after {
          color: $color-text-yellow; } } } }
  .js-drilldown-back {
    > a {
      padding-left: 38px;
      position: relative;
      &:before {
        content: $arrow;
        @include icomoon;
        position: absolute;
        left: 0;
        height: unset;
        width: 38px;
        text-align: center;
        display: block;
        border: none;
        font-size: 1.3rem;
        right: initial;
        transform: rotate(90deg);
        margin: 0;
        margin-top: 3px; } } } }
