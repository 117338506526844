.c-order-confirmation {
  .c-order-view {
    max-width: 730px; }
  .c-cart-good {
    position: relative;
    border-radius: 0;
    padding-top: 20px;
    padding-bottom: 25px;
    padding-right: 20px;
    &:not(:last-child) {
      margin-bottom: 0;
      &:after {
        content: "";
        position: absolute;
        left: 20px;
        right: 20px;
        bottom: 0;
        height: 1px;
        background-color: rgba($color-text-gray, .3); } }
    &:first-child {
      padding-top: 35px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px; }
    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px; }
    &__const {
      width: 85px;
      margin-right: -85px; }
    &__var {
      padding-left: 105px; }
    &__img {
      width: 85px;
      height: 85px; }
    &__title {
      line-height: 1.3;
      margin-bottom: 10px; }
    &__price,
    &__count {
      color: $color-text;
      font-size: 1.6rem;
      line-height: 1; }
    &__line-group {
      margin-right: 0; } }
  .c-cart-result {
    background-color: $accent-bg;
    padding: 30px 20px 35px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px; }
  .c-cart-sum {
    &__accent {
      font-size: 2.4rem;
      margin-bottom: 5px; }
    &__bonus {
      font-size: 1.6rem; } } }

@include MQ-max(767px) {
  .c-order-confirmation {
    .c-cart-result {
      margin-left: -$space;
      margin-right: -$space; } } }

@include MQ(768px) {
  .c-order-confirmation {
    .c-cart-good {
      padding-left: 30px;
      padding-right: 30px;
      &:not(:last-child) {
        &:after {
          left: 30px;
          right: 30px; } } }
    .c-cart-result {
      padding-right: 30px;
      padding-left: 30px; } } }

@include MQ(1025px) {
  .c-order-confirmation {
    .c-order-view {
      font-size: 0;
      &__var,
      &__const {
        display: inline-block;
        vertical-align: top; }
      &__var {
        padding-right: 390px;
        width: 100%; }
      &__const {
        width: 320px;
        margin-left: -320px; } } } }
