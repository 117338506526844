$arrow: "\e909";

.c-dotted {
  color: $color-text-gray;
  .c-dotted__anchor {
    display: inline;
    color: inherit;
    background-image: linear-gradient(90deg,#696b6f 50%,transparent 0),linear-gradient(90deg,transparent,transparent);
    background-size: 2px 1px,100% 1px;
    background-repeat: repeat-x;
    background-position: 0 98%; }
  &.has-arrow {
    .c-dotted__anchor {
      margin-right: 15px;
      position: relative;
      &:after {
        content: $arrow;
        position: absolute;
        right: -20px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.3rem;
        color: inherit;
        @include icomoon; } }
    &.is-open {
      .c-dotted__anchor {
        color: $color-text;
        &:after {
          transform: translateY(-50%) rotate(180deg); } } } } }

@include MQ(1280px) {
  .c-dotted {
    .c-dotted__anchor {
      cursor: pointer;
      &:hover {
        color: $color-text; } } } }

