.c-social {
  font-size: 2.4rem;
  font-weight: 500;
  color:  #3b5998; }

@include MQ(1280px) {
  .c-social {
    @include transition;
    &:hover {
      color: lighten(#3b5998, 10%); } } }

