.c-radiobutton {
  position: relative;
  display: inline-block;
  label {
    padding: 0; }
  input[type="radio"] {
    visibility: hidden;
    width: 0 !important;
    position: absolute;
    left: 0;
    top: 0;
    height: 0 !important;
    opacity: 0;
    + label {
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0 0;
      cursor: pointer;
      color: $color-text-gray;
      font-size: 1.8rem;
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        border: 1px solid $color-text-gray;
        border-radius: 50%;
        top: 2px;
        left: 0; }
      &:after {
        content: "";
        background: $color-text;
        border-radius: 50%;
        display: block;
        width: 8px;
        height: 8px;
        position: absolute;
        left: 4px;
        top: 6px;
        opacity: 0; }
      .help {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        padding-left: 26px; } }
    &:checked {
      + label {
        color: $color-text;
        &:after {
          opacity: 1; }
        &:before {
          border-color: $color-text; } } }
    &:disabled {
      + label {
        color: rgba($color-text-gray, .3);
        &:before {
          border-color: rgba($color-text-gray, .3); } } } }
  &--no-circle {
    label {
      width: 100%;
      padding: 17px 20px 22px;
      border-radius: 6px; }
    input[type="radio"] {
      + label {
        .help {
          padding-left: 0; }
        &:after,
        &:before {
          display: none; } }
      &:checked {
        + label {
          background-color: $accent-bg; } } } } }

@include MQ(1025px) {
  .c-radiobutton {
    input[type="radio"]:not([disabled]) {
      + label {
        @include transition; }
      &:before {
        @include transition; }
      &:hover {
        + label {
          color: $color-text;
          &:before {
            border-color: $color-text; } } } }

    &--no-circle {
      &:hover {
        input[type="radio"] {
          + label {
            background-color: $accent-bg; } } } } } }
