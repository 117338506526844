.s-chips {
  li {
    color: $color-text-gray;
    font-size: 1.6rem;
    font-weight: normal;
    display: inline-block;
    vertical-align: middle;
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
    &.is-active {
      color: $color-text;
      a,
      button {
        background-color: #35353e;
        pointer-events: none; } } }
  a,
  button {
    font-size: inherit;
    color: inherit;
    padding-top: 9px;
    padding-bottom: 7.5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 17px;
    border: 2px solid #35353e;
    line-height: .9;
    height: 100%;

    &:disabled {
      cursor: not-allowed; } } }

@include MQ(1280px) {
  .s-chips {
    a,
    button {
      &:hover:not(:disabled) {
        background-color: $color-bg; } } } }

