.c-cart-result {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 20px; }

.c-cart-form {
  padding-top: 40px;
  .c-form {
    &__group--textarea {
      width: auto;
      max-width: none; } }
  &__login {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    .c-btn {
      width: 135px;
      margin: 0 7px; } } }

.c-self-delivery {
  .c-cabinet-info {
    &:not(:last-child) {
      margin-bottom: 30px; } } }

.c-cart-btn {
  text-align: center;
  padding-top: 30px; }

@include MQ-max(767px) {
  .c-self-delivery {
    padding-left: 26px; } }

@include MQ(480px) {
  .c-cart-result {
    .c-bonus-info {
      padding-right: 20px; } } }

@include MQ(768px) {
  .c-cart {
    width: 610px;
    .c-cart-address {
      width: 640px; }
    &-form__login {
      .c-btn {
        width: 170px; } } } }

@include MQ(1025px) {
  .c-cart-result {
    padding: 20px 30px 20px 150px;
    .c-bonus-info {
      margin-left: -26px; } }
  .c-cart-form {
    padding-left: 130px;
    .c-form {
      &__group--textarea {
        margin-left: -130px;
        margin-right: -20px; } }
    &__login {
      justify-content: flex-start;
      margin-left: -7px; } } }
