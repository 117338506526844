.l-page {
  padding-top: 22px;
  padding-bottom: 20px;
  .l-contacts & {
    padding-bottom: 0; } }

.l-page {
  @include MQ(768px) {
    padding-top: 50px; }
  @include MQ(993px) {
    padding-bottom: 50px; } }
