.c-sort {
  z-index: 10;
  font-size: 1.4rem;
  display: inline-flex;
  padding: 10px;
  flex-direction: column;
  transition: background .3s;
  border-radius: 6px;
  &.is-active {
    .c-sort__item {
      display: block; } }
  .c-sort__dotted {
    color: $color-text;
    padding-right: 14px;
    backface-visibility: hidden; }
  .c-sort__anchor {
    background-image: linear-gradient(90deg, $color-text 50%,transparent 0),linear-gradient(90deg,transparent,transparent); }
  .c-sort__item {
    display: none;
    &.is-active {
      display: block;
      order: -1;
      .c-sort__dotted {
        padding-right: 0; }
      .c-sort__anchor {
        padding-right: 14px;
        position: relative;
        &:after {
          position: absolute;
          content: "";
          right: 2px;
          top: 50%;
          bottom: auto;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          opacity: .7;
          border-style: solid;
          border-width: 7px 4px 0 4px;
          border-color: #fff transparent transparent transparent; } } } }
  &.is-open {
    background-color: #191919;
    .c-sort__item {
      display: block;
      &.is-active .c-sort__anchor:after {
        transform: translateY(-50%) rotate(-180deg); } } } }

@include MQ(993px) {
  .c-sort {
    padding: 0;
    font-size: 1.6rem;
    flex-direction: row;
    justify-content: flex-end;
    .c-sort__dotted {
      padding-right: 0;
      color: $color-text-gray; }
    .c-sort__anchor {
      background-image: linear-gradient(90deg, $color-text-gray 50%,transparent 0),linear-gradient(90deg,transparent,transparent);
      padding-left: 0;
      padding-right: 0; }
    .c-sort__item {
      display: block;
      padding-left: 10px;
      padding-right: 10px;
      &:not(.is-active) {
        text-transform: lowercase; }
      &.is-active {
        order: 0;
        .c-sort__dotted {
          color: $color-text; }
        .c-sort__anchor {
          pointer-events: none;
          padding-right: 0;
          background-image: linear-gradient(90deg, $color-text 50%,transparent 0),linear-gradient(90deg,transparent,transparent);

          &:after {
            content: none; } } } }
    &.is-open {
      background: none; } } }

