.c-gallery {
  .c-gallery__item {
    cursor: zoom-out; }
  img {
    padding-left: $space;
    padding-right: $space;
    padding-top: $space;
    padding-bottom: $space;
    max-width: 900px;
    width: auto;
    border-radius: 20px;
    margin: auto; }
  .c-gallery__item {
    + .c-gallery__item {
      margin-top: 50px; } } }

@include MQ(1280px) {
  .c-gallery {
    img {
      padding-left: $space-desktop;
      padding-right: $space-desktop;
      padding-top: $space-desktop;
      padding-bottom: $space-desktop;
      max-width: 100%; } } }

