.icon-search-ico {
  font-size: 1.8rem; }
.icon-cart-ico {
  font-size: 2.2rem; }
.icon-cabinet-ico {
  font-size: 2.2rem; }

.icon-mirrolab-ico {
  font-size: 1.2rem;
  color: #fff;
  display: inline-block;
  transform: translateY(-2px); }

.icon-filter-ico {
  font-size: 2.4rem;
  color: $color-blue;
  .t-bonus & {
    color: $color-text-yellow; } }
