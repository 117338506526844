.c-map {
  height: 420px;
  background-color: #191919;
  > div {
    background-color: #191919 !important; } }

@include MQ(1025px) {
  .c-map {
    height: 580px; } }

