.c-menu {
  .c-menu__curency {
    padding-left: $space;
    padding-right: $space;
    padding-top: 30px;
    padding-bottom: 47px;
    font-size: 0; }
  .c-menu__phones {
    padding-left: $space;
    padding-right: $space; } }
