.icon-logo-ico {
  font-size: 2rem;
  display: inline-block;
  margin-top: 2px; }

.c-logo {
  height: 46px;
  background-color: $color-blue;
  padding-left: 19px;
  padding-right: 25px;
  display: inline-block;
  color: #fff;
  @include vertical;
  .t-index & {
    pointer-events: none; } }

@include MQ(1280px) {
  .c-logo {
    height: 72px;
    padding-left: $space;
    padding-right: $space;
    @include transition;
    &:hover {
      background-color: lighten($color-blue, 10%); } } }
