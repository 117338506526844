body {
	min-height: 100%;
	position: relative;
	color: $color-text;
	font-size: 1.6rem;
	font-family: 'Gotham Pro', sans-serif;
	font-weight: normal; }

a {
	color: inherit;
	display: inline-block;
	text-decoration: none;
	&:focus {
		outline: none; }
	&:hover,
	&:active {
		text-decoration: none; } }

hr.line {
	background: rgba(#4A4A4A, .3);
	margin: 0;
	padding: 0;
	height: 2px;
	border: {
		left: 15px solid #4A4A4A;
		right: 15px solid #4A4A4A; } }

address {
	font-style: normal;
	font-size: 1.8rem;
	font-weight: 500;
	color: $color-text-gray; }

img {
	pointer-events: none;
	max-width: 100%;
	display: block; }

button {
	margin: 0;
	padding: 0;
	background-color: unset;
	&:focus {
		outline: none; } }

section {
  h2 {
    margin-bottom: 20px; }
  + section {
    margin-top: 23px; } }

@include MQ(993px) {
  section {
    + section {
      margin-top: 30px; } } }

@include MQ(1280px) {
  section {
    h2 {
      margin-bottom: 34px; } } }
