.l-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-bottom: 29px;
  padding-top: 76px;
  .l-contacts & {
    padding-top: 29px; } }

.l-footer {
  @include MQ(993px) {
    padding-bottom: 50px;
    padding-top: 50px; }
  @include MQ(1280px) {
    padding-top: 40px;
    padding-bottom: 45px; } }
