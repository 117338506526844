.c-available {
  font-size: 1.6rem;
  .c-available__inner {
    padding-left: $space;
    padding-right: $space;
    padding-top: 10px;
    padding-bottom: 9px;
    display: inline-block;
    background-color: rgba(#fff, .05);
    border-radius: 4px; } }

