.c-breadcrumbs {
  padding-top: 20px;
  padding-bottom: 17px; }

@include MQ(993px) {
  .c-breadcrumbs {
    padding-top: 30px;
    padding-bottom: 27px; } }

@include MQ(1280px) {
  .c-breadcrumbs {
    padding-top: 37px;
    padding-bottom: 20px;
    .s-item & {
      padding-bottom: 45px; } } }
