.c-cat {
  .c-cat__list {
    padding-top: 15px;
    font-size: 0;
    margin-left: -5px;
    margin-right: -5px; }
  .c-cat__icon {
    display: block;
    margin-bottom: 8px;
    [class^='icon-'] {
      display: inline-block;
      font-size: 3.7rem;
      transform: translateX(-2px); }
    .icon-usb-ico,
    .icon-phone-ico {
      transform: translateX(-10px); } }
  .c-cat__item {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
    &.t-bonus {
      color: $color-text-yellow; } }
  .c-cat__inner {
    background-color: rgba(darken(#28282e, .6%), .6);
    padding-top: 11px;
    padding-left: 11px;
    padding-bottom: 11px;
    padding-right: 11px;
    border-radius: 4px;
    min-height: 117px; }
  .c-cat__title {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.3;
    display: block; }
  .c-cat__links {
    display: none; } }

@include MQ(678px) {
  .c-cat {
    .c-cat__item {
      width: 33.333%; } } }

@include MQ(1025px) {
  .c-cat {
    .c-cat__title {
      font-size: 2.4rem; }
    .c-cat__icon {
      margin-bottom: 16px;
      [class^='icon-'] {
        font-size: 4.6rem;
        color: inherit; } }
    .c-cat__inner {
      padding-left: 27px;
      padding-right: 10px;
      padding-top: 23px;
      padding-bottom: 25px;
      min-height: 252px; }
    .c-cat__links {
      margin-top: 15px;
      display: block;
      font-size: 1.6rem;
      color: rgba(#fff, .5);
      a {
        font-size: inherit;
        display: inline-block;
        line-height: 1.38;
        &:hover {
          color: #fff; }
        &:after {
          display: inline-block; } } } } }

@include MQ(1280px) {
  .c-cat {
    .c-cat__list {
      margin-left: -$space;
      margin-right: -$space; }
    .c-cat__item {
      width: 25%;
      position: relative;
      &.t-bonus {
        .c-cat__title {
          color: rgba($color-text-yellow, .5);
          &:hover {
            color: $color-text-yellow; } } }
      &:hover {
        &:after {
          content: '';
          position: absolute;
          top: 5px;
          left: 5px;
          bottom: 5px;
          right: -8px;
          background-color: rgba(darken(#28282e, .6%), .6);
          z-index: -1;
          border-radius: 6px; } } }
    .c-cat__title {
      color: rgba(#fff, .5);
      &:hover {
        color: #fff; } }
    .c-cat__inner {
      background-color: unset;
      min-height: unset; } } }

@include MQ(1315px) {
  .c-cat {
    .c-cat__list {
      margin-left: -$space-desktop;
      margin-right: -$space-desktop; } } }

@include MQ(1440px) {
  .c-cat {
    .c-cat__list {
      margin-left: -100px;
      margin-right: -100px; }
    .c-cat__item {
      padding-top: 15px;
      padding-left: 20px;
      padding-bottom: 15px;
      padding-right: 20px; } } }

