$icon-eyecross-ico: '\e90d';
$icon-eye-ico: '\e90e';

.c-input-password {
  position: relative;
  &__icon {
    font-size: 1.6rem;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -13px;
    color: $color-text-gray;
    cursor: pointer;
    padding: 5px;
    &:before {
      font-family: icomoon; } }
  input[type="password"] {
    + .c-input-password__icon {
      &:before {
        content: $icon-eyecross-ico; } } }
  input[type="text"] {
    + .c-input-password__icon {
      &:before {
        content: $icon-eye-ico; } } } }

@include MQ(1025px) {
  .c-input-password {
    &__icon {
      right: 35px; } } }
