.c-cabinet-menu {
  font-size: 0;
  &:not(:last-child) {
    margin-bottom: 30px; }
  &__link {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.2;
    color: $color-text-gray; }
  &__item {
    display: inline-block;
    vertical-align: top;
    &:not(:last-child) {
      margin-bottom: 15px; }
    &.is-active {
      .c-cabinet-menu {
        &__link {
          color: $color-text; } } }
    &--accent {
      &.is-active {
        .c-cabinet-menu {
          &__link {
              color: $color-text-yellow; } } } } } }

@include MQ-max(767px) {
  .c-cabinet-menu {
    display: flex;
    flex-direction: column;
    &.is-open {
      .c-cabinet-menu__item {
        display: block; } }
    &__item {
      display: none;
      order: 1;
      &.is-active {
        display: block;
        order: 0;
        .c-cabinet-menu__link {
          position: relative;
          padding-right: 15px;
          &:after {
            position: absolute;
            content: "";
            right: 0;
            top: 50%;
            bottom: auto;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            margin-top: 1px;
            opacity: .7;
            border-style: solid;
            border-width: 7px 4px 0 4px;
            border-color: #fff transparent transparent transparent; } } } }
    &:not(:last-child) {
      margin-bottom: 40px; } } }

@include MQ(768px) {
  .c-cabinet-menu {
    &:not(:last-child) {
      margin-bottom: 50px; }
    &__item {
      display: block; } } }

@include MQ(1025px) {
  .c-cabinet-menu {
    &__link {
      @include transition;
      &:hover {
        color: $color-text; } }
    &__item {
      &--accent {
        &:hover {
          .c-cabinet-menu {
            &__link {
              color: $color-text-yellow; } } } } } } }
