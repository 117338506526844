.c-cabinet-ctrl {
  font-size: 0;
  &__item {
    display: inline-block;
    vertical-align: top;
    &:not(:last-child) {
      margin-right: 15px; } }
  &__link {
    color: $color-text-gray;
    font-size: 1.8rem; } }

@include MQ-max(767px) {
  .c-cabinet-ctrl {
    &--mob-top {
      position: absolute;
      top: 0;
      right: 0;
      line-height: 3rem; } } }

@include MQ(1025px) {
  .c-cabinet-ctrl {
    &__link {
      @include transition;
      &:hover {
        color: $color-text; } } } }
