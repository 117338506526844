.l-item-column {
  max-width: 500px; }

@include MQ(993px) {
  .l-item-column {
    max-width: unset;
    width: 50%;
    display: inline-block;
    vertical-align: top; } }

@include MQ(993px) {
  .l-item-row {
    font-size: 0; } }

@include MQ(1025px) {
  .l-item-row {
    padding-left: 13px;
    padding-right: 13px; } }

@include MQ(1280px) {
  .l-item-row {
    padding-left: 25px;
    padding-right: 25px; } }

@include MQ(1342px) {
  .l-item-row {
    max-width: 1342px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 58px;
    padding-right: 58px; } }

