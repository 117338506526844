.c-checkbox {
  position: relative;
  label {
    padding: 0; }
  input[type="checkbox"] {
    visibility: hidden;
    width: 0 !important;
    position: absolute;
    left: 0;
    top: 0;
    height: 0 !important;
    opacity: 0;
    + label {
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0 0;
      cursor: pointer;
      color: $color-text-gray;
      font-size: 1.8rem;
      &:before {
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        border: 2px solid $color-text-gray;
        position: absolute;
        top: 2px;
        left: 0; }
      &:after {
        content: "";
        opacity: 0;
        display: block;
        position: absolute;
        top: 6px;
        left: 2.4px;
        width: 10px;
        height: 6px;
        background-image: url('../../src/img/checkbox-checked.png'); }
      .help {
        display: block;
        position: relative;
        width: 100%;
        padding-left: 22px;
        line-height: 1; } }
    &:checked {
      + label {
        color: $color-text;
        &:after {
          opacity: 1; }
        &:before {
          border-color: $color-text; } } }
    &:disabled {
      + label {
        cursor: not-allowed; } } }
  .t-catalog & {
    input[type="checkbox"] {
      + label {
        position: relative;
        font-size: 1.6rem;
        font-weight: normal;
        &:before {
          border-width: 1px; }
        &:after {
          left: 2.75px; } } } }

  .c-fgroup & {
    + .c-checkbox {
      margin-top: 7px; } } }

@include MQ(1025px) {
  .c-checkbox {
    input[type="checkbox"] {
      + label {
        @include transition;
        &:before {
          @include transition; } }
      &:hover:not(:disabled) {
        + label {
          color: $color-text;
          &:before {
            border-color: $color-text; } } } } } }

