.c-accent-block {
  font-size: 2.4rem;
  line-height: 1.3;
  background-color: $accent-bg;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 30px;
  &__text {
    color: $color-text-yellow; } }

@include MQ(1025px) {
  .c-accent-block {
    margin-left: -20px; } }
