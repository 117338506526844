
.c-categories {
  .c-categories__inner {
    padding-left: 7px;
    padding-right: 7px;
    @include MQ(1280px) {
      padding-left: $space-desktop;
      padding-right: $space-desktop; }
    @include MQ(1315px) {
      max-width: 1235px;
      margin-left: auto;
      margin-right: auto; } } }

