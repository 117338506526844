.c-pag {
  .c-pag__item {
    display: inline-block;
    margin-left: {}
    &.is-active {
      color: $color-text;
      a {
        pointer-events: none; } } }
  .c-pag__link {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 4px; } }

@include MQ(1280px) {
  .c-pag {
    .c-pag__link {
      &:hover {
        color: rgba($color-text, .5); } } } }

