.c-select {
  position: relative;
  &__icon {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -12px;
    font-size: 14px;
    padding: 5px;
    color: $color-text-gray; } }

.multiselect {
  min-height: 61px;
  cursor: pointer;
  &__tags {
    background-color: $accent-bg;
    color: $color-text-gray;
    border: none;
    min-height: 61px;
    padding: 0 50px 0 20px;
    border-radius: 6px; }
  &__spinner {
    background: #202023;
    width: 50px;
    height: 59px; }
  &__select {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 59px;
    padding: 0;
    &:before {
      content: '\e909';
      display: block;
      position: static;
      margin-top: 0;
      font-family: icomoon;
      font-size: 14px;
      color: $color-text-gray;
      border: none; } }
  &__single {
    margin-bottom: 0;
    padding: 0;
    line-height: 61px;
    background: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal; }
  &__input {
    padding: 0;
    margin-bottom: 0;
    background: none !important;
    transition: none !important; }
  &__content {
    width: 100%;
    &-wrapper {
      margin: 5px 0;
      background-color: #191919;
      border: none !important; } }
  &__option {
    color: $color-text-gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &--highlight {
      background-color: $accent-bg; }
    &--selected {
      color: #fff;
      background-color: $color-blue !important; }
    &:after {
      display: none; } }
  &.is-invalid {
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      background-color: $color-error; }
    &.multiselect--active:not(.multiselect--above) {
      &:after {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; } } }
  &--disabled {
    background: none;
    cursor: not-allowed;
    opacity: 1;
    pointer-events: auto;
    .multiselect {
      &__current,
      &__select {
        background: none;
        color: inherit; } } } }

@include MQ(1025px) {
  .c-select {
    &__icon {
      right: 35px; } } }

