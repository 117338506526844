.c-badge {
  position: absolute;
  top: -11px;
  right: -10px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background-color: #dedede;
  @include square(22px);
  .c-badge__anchor {
    color: $color-blue;
    display: inline-block;
    transfrom: translateY(3px);
    font-size: 12px;
    line-height: 23px; } }
