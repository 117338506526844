.c-preview {
  &__inner {
    padding-left: 7px;
    padding-right: 7px; }
  &__container {
    position: relative; }
  &__available {
    margin-top: 15px;
    font-size: 1.4rem;
    color: $color-text-gray;
    max-width: 93px;
    display: inline-block; }
  &__part {
    border-radius: 6px;
    background-color: rgba(#28282e, .6);
    position: relative; }
  &__amount {
    position: relative;
    padding-top: 13px;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 18px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around; }
  &__main {
    padding-top: 13px;
    padding-left: 9px;
    padding-bottom: 15px;
    padding-right: 9px; }
  &.is-open {
    .c-preview__secondary {
      display: block; } }
  &__secondary {
    background-color: #28282e;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none; }
  &__media {
    position: absolute; }
  &__detail {
    display: inline-block;
    vertical-align: top;
    padding-left: 115px;
    padding-right: 6px;
    width: 100%;
    min-height: 93px; }
  &__title {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.4; }
  &__props {
    padding-top: 6px;
    color: $color-text-gray; }
  &__image {
    width: 93px;
    height: 93px;
    img {
      border-radius: 5px; } }
  &__action {
    padding-top: 20px;
    margin-left: -7px; }
  &__price,
  &__btn {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 7px; }
  &__btn {
    margin-left: 7px; }
  + .c-preview {
    margin-top: 10px; } }

.c-preview {
  @include MQ(480px) {
    &__main {
      padding-left: 23px;
      padding-top: 20px; }
    &__detail {
      padding-left: 116px; }
    &__media,
    &__detail {
      text-align: left; }
    &__amount {
      align-items: center; } } }

@include MQ(678px) {
  .c-preview {
    &__btn {
      display: none; }
    &__available {
      display: none; }
    &__amount {
      padding: 0;
      flex-direction: row;
      align-items: stretch; }
    &__part {
      padding: 0;
      border-radius: 0;
      background-color: unset; }
    &__container {
      padding-top: 19px;
      padding-left: 23px;
      padding-right: 23px;
      padding-bottom: 25px;
      border-radius: 6px;
      background-color: rgba(#28282e, .5);
      display: flex;
      flex-direction: row; }
    &__close {
      display: none; }
    &-row {
      text-align: left; }
    &__main {
      flex: 0 0 66%; }
    &__container {
      font-size: 0; }
    &__detail {
      padding-right: 0;
      display: flex;
      flex-direction: column; }
    &__action {
      margin-top: auto; }
    &__secondary {
      text-align: right;
      vertical-align: top;
      display: inline-block;
      position: relative;
      flex: 0 0 34%; }
    &.is-open {
      .c-preview__secondary {
        display: inline-block; } }
    &__price,
    &__btn {
      margin-bottom: 0; } } }

@include MQ(993px) {
  .c-preview {
    &__image {
      width: 127px;
      height: 127px;
      img {
        border-radius: 10px; } }
    &__detail {
      padding-left: 150px;
      min-height: 127px; }
    &__container {
      padding-top: 17px; }
    &__title {
      font-size: 2.2rem; }
    &__action {
      padding-top: 10px; }
    + .c-preview {
      margin-top: 11px; } } }

@include MQ(1280px) {
  .c-preview {
    &__inner {
      max-width: 1276px;
      margin: 0 auto;
      padding-left: 0;
      padding-right: 0; }
    &:hover {
      .c-preview__title,
      .c-price,
      .c-quantity__head {
        color: $color-text; }
      .c-preview__container {
        background-color: rgba(#28282e, .6); } }
    .c-preview__title,
    .c-price,
    .c-quantity__head {
      color: $color-text-gray;
      @include transition; } } }
