.c-notice {
  border-radius: 6px;
  position: fixed;
  top: 59px;
  right: 7px;
  transform: translateX(100%);
  width: 280px;
  background-color: $accent-bg-color;
  opacity: 0;
  transition: transform .3s;
  &.is-open {
    transform: translateX(0);
    opacity: 1; }
  .c-notice__inner {
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px; }
  .c-notice__row {
    font-size: 0;
    margin-left: -5px;
    margin-right: -5px; }
  .c-notice__column {
    display: inline-block;
    vertical-align: top;
    padding-left: 5px;
    padding-right: 5px; }
  .c-notice__text {
    padding-top: 5px;
    width: 75%;
    font-size: 1.4rem; }
  .c-notice__img {
    width: 25%;
    img {
      border-radius: 3px; } } }

@include MQ(1280px) {
  .c-notice {
    top: 85px;
    right: 29px;
    width: 323px;
    .c-notice__inner {
      padding-left: $space;
      padding-top: 19px;
      padding-bottom: 19px;
      padding-right: $space; }
    .c-notice__row {
      margin-left: -10px;
      margin-right: -10px; }
    .c-notice__column {
      padding-left: 10px;
      padding-right: 10px; }
    .c-notice__text {
      font-size: 1.6rem; } } }

