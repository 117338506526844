.c-error {
  &__img {
    max-width: 480px;
    width: 100%;
    margin-bottom: 30px;
    img {
      display: block;
      width: 100%; } }
  &__text {
    font-size: 1.8rem;
    p:not(:last-child) {
      margin-bottom: 15px; } }
  .c-typical-block__title {
    padding: 0; } }

@include MQ(1025px) {
  .c-error {
    font-size: 0;
    &__img,
    &__text {
      display: inline-block;
      vertical-align: middle; }
    &__img {
      position: relative;
      width: 480px;
      margin-right: -480px;
      padding-top: 65px;
      margin-bottom: 0; }
    &__text {
      width: 100%;
      padding-left: 515px; } } }
