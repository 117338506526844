@media (max-width: 600px) {
  .u-hide-small {
    display: none !important; } }

@media (max-width: 992px) and (min-width: 601px) {
  .u-hide-medium {
    display: none !important; } }

@media (min-width: 993px) {
  .u-hide-large {
    display: none !important; } }

@media (min-width: 768px) {
  .u-show-mob {
    display: none !important; } }

@media (max-width: 767px) {
  .u-hide-mob {
    display: none !important; } }

.u-hide {
  display: none !important; }
.u-text-center {
  text-align: center; }
.u-text-right {
  text-align: right !important; }
.u-nospace {
  padding-top: 0 !important; }
.u-spacing {
  padding-left: $space;
  padding-right: $space; }

.u-special-center {
  text-align: center; }

.u-load {
  position: relative;
  min-height: 120px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(#000, .4);
    visibility: hidden;
    opacity: 0;
    z-index: 10;
    transition: .2s; }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1em;
    height: 1em;
    margin: -.5em 0 0 -.5em;
    font-size: 40px;
    color: $color-blue;
    border-radius: 50%;
    z-index: 11;
    visibility: hidden;
    opacity: 0;
    transition: .2s; }

  &.is-loading {
    &:before, &:after {
      visibility: visible;
      opacity: 1; }
    &:after {
      animation: load6 1.7s infinite ease, round 1.7s infinite ease; } } }

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em; }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em; }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em; }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; } }

@keyframes round {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@include MQ(1025px) {
  .u-special-center {
    margin-left: -20px;
    padding-right: 20px; } }
