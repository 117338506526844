.c-radiobutton {
  position: relative;
  &__message {
    display: none;
    max-width: 370px;
    background-color: $accent-bg-color;
    border-radius: 6px;
    padding: 17px 20px;
    margin-top: 20px;
    font-size: 1.6rem;
    color: $color-text-gray;
    font-weight: normal;
    line-height: 1.4;
    opacity: 0;
    visibility: hidden;
    z-index: 1; } }

@include MQ(1025px) {
  .c-radiobutton {
    &__message {
      display: block;
      position: absolute;
      left: calc(100% + 20px);
      top: -20px;
      margin-top: 0;
      width: 26vw;
      transition: all .3s ease;
      &:before {
        content: "";
        position: absolute;
        left: -20px;
        right: -20px;
        top: -20px;
        bottom: -20px; } }
    &__message-inner {
      position: relative; }
    &:hover {
      .c-radiobutton {
        &__message {
          opacity: 1;
          visibility: visible;
          transition: none; } } } } }

@include MQ(1025px) {
  .c-radiobutton {
    &__message {
      width: 370px; } } }
