.l-container {
  padding-left: $space;
  padding-right: $space; }

.l-cabinet-container,
.l-item-container {
  @extend .l-container; }

.l-container {
  @include MQ(1025px) {
    padding-left: $space-desktop;
    padding-right: $space-desktop; }
  @include MQ(1315px) {
    max-width: 1235px;
    margin-left: auto;
    margin-right: auto; } }

.l-cabinet-container,
.l-item-container {
  @include MQ(1315px) {
    max-width: unset; } }

.l-cabinet-container {
  @include MQ(1367px) {
    padding-right: 65px;
    padding-left: 65px; } }

.l-item-container {
  @include MQ(1342px) {
    max-width: 1342px;
    padding-left: 33px;
    padding-right: 33px;
    margin-left: auto;
    margin-right: auto; } }

