.c-chips {
  .c-chips__list {
    font-size: 0;
    margin-left: -4px;
    margin-left: -4px; }
  .c-chips__dotted {
    padding-top: 8px; } }

@include MQ(993px) {
  .c-chips {
    .c-chips__dotted {
      padding-top: 20px; } } }

@include MQ(1280px) {
  .c-chips {
    .c-chips__list {
      max-width: 1030px; } } }
