$color-blue: #0758ff;
$color-text: #dedede;
$color-text-gray: #696b6f;
$color-bg: #1b1b1e;
$color-text-yellow: #daba57;
$color-error: #db2525;
$accent-bg-color: #28282e;
$accent-bg: rgba($accent-bg-color, .5);
$accent-bg-hover: rgba(#23242a, .5);
$space: 17px;
$space-desktop: 40px;
