$arrow-ico: '\e909';

.c-select2 {
  display: inline-block;
  width: 100%; }

.select2-container--default {
  .select2-selection--single {
    position: relative;
    border: none;
    outline: none;
    border-radius: 6px;
    height: 61px;
    padding: 0 20px;
    background-color: $accent-bg;
    color: $color-text-gray;
    @include vertical;
    text-align: left;
    transition: all .2s linear;
    .select2-selection__arrow {
      display: block;
      position: absolute;
      top: 50%;
      right: 15px;
      margin-top: -12px;
      width: auto;
      height: auto;
      b {
        display: none; }
      &:before {
        content: $arrow-ico;
        display: inline-block;
        font-family: icomoon;
        font-size: 14px;
        color: $color-text-gray;
        padding: 5px; } }
    .select2-selection__rendered {
      font-size: 1.6rem;
      font-weight: normal;
      color: $color-text-gray;
      padding-left: 0;
      padding-right: 20px;
      transition: all .2s linear;
      line-height: 61px; } }
  &.select2-container--open {
    .select2-selection--single {
      background-color: $accent-bg-hover; }
    .select2-selection__rendered {
      color: $color-text; } }
  .select2-results__option--highlighted[aria-selected],
  .select2-results__option[aria-selected=true] {
    background-color: $color-blue; }
  .select2-results {
    & > .select2-results__options {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none; } } } }
.select2-dropdown {
  background-color: $accent-bg-color;
  border: none;
  border-radius: 6px; }

.select2-results__option {
  padding-left: 20px;
  padding-right: 20px;
  min-height: 36px; }
