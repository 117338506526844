.c-phones {
  font-size: 0;
  .c-phones__list {}
  .c-phones__item {
    font-size: 0;
    display: block;
    margin-top: 4px; }
  .c-phones__link {
    font-size: 1.2rem; }
  .c-phones__label {
    display: none; }
  .c-header__phones & {
    .c-phones__link {
      font-weight: normal; }
    .c-phones__label {
      font-weight: normal; } }
  .c-menu__phones & {
    .c-phones__label {
      font-size: 1.6rem;
      font-weight: normal;
      color: rgba(#fff, .5);
      display: block; }
    .c-phones__item {
      font-size: 1.8rem;
      font-weight: 500;
      margin-left: -$space;
      margin-right: -$space; }
    .c-phones__link {
      padding-left: $space;
      padding-right: $space;
      font-size: inherit;
      font-weight: inherit; } }
  .c-contacts & {
    .c-phones__label {
      display: block;
      font-size: 1.8rem;
      font-weight: 500; }
    .c-phones__item {
      margin-top: 0;
      + .c-phones__item {
        margin-top: 4px; } }
    .c-phones__list {
      transform: translateX(-2px); }
    .c-phones__link {
      font-size: 1.8rem;
      font-weight: 500;
      color: $color-text-gray; } } }

@include MQ(1280px) {
  .c-phones {
    .c-phones__item {
      margin-top: 6px; }
    .c-phones__link {
      font-size: 1.6rem;
      color: rgba(#fff, .5);
      &:hover {
        color: rgba(#fff, .7); } } } }

@include MQ(1500px) {
  .c-phones {
    .c-phones__item {
      display: inline-block;
      &:last-child {
        .c-phones__link {
          &:after {
            content: ''; } } }
      + .c-phones__item {
        padding-left: 5px; } }
    .c-phones__link {
      &:after {
        content: '\002c';
        display: inline-block; } }
    .c-phones__label {
      padding-right: 10px;
      display: inline-block;
      margin-left: 0;
      color: rgba(#fff, .3); } } }

@include MQ(1700px) {
  .c-phones {
    margin-top: 10px;
    .c-phones__item {
      margin-top: 0; }
    .c-phones__list {
      display: inline-block;
      vertical-align: middle; }
    .c-phones__label {
      vertical-align: middle;
      padding-right: 10px; } } }

