.c-notavailable {
  .c-notavailable__inner {
    padding-left: $space;
    padding-right: $space;
    padding-top: 60px; }
  .c-notavailable__text {
    font-weight: 500;
    font-size: 2rem;
    padding-left: $space; } }

@include MQ(650px) {
  .c-notavailable {
    .c-notavailable__inner {
      max-width: 700px;
      margin-left: auto;
      margin-right: auto; } } }

@include MQ(1025px) {
  .c-notavailable {
    .c-notavailable__text {
      padding-left: 0; }
    .c-notavailable__inner {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: unset;
      width: 100%;
      min-height: 100%; } } }

@include MQ(1280px) {
  .c-notavailable {
    .c-notavailable__text {
      font-size: 2.4rem; } } }

