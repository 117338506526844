.c-form {
  line-height: 1.1;
  &__groups {
    margin-bottom: 50px; }
  &__group {
    max-width: 405px;
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 30px; } }
  &__group-title {
    font-size: 2.4rem;
    margin-bottom: 20px; }
  &__control {
    &.is-error {
      input,
      select {
        border-bottom: 2px solid $color-error;
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 2px; } } }
  &__error-message {
    font-size: 13px;
    font-weight: normal;
    color: $color-text-gray;
    margin-top: 5px; }
  label {
    display: block;
    font-size: 1.8rem;
    margin-bottom: 6px;
    font-weight: 500; }
  input,
  textarea,
  select {
    outline: none;
    font-size: 1.6rem;
    text-align: left;
    font-weight: normal;
    width: 100%;
    background-color: $accent-bg;
    color: $color-text-gray;
    transition: all .2s linear;
    border: none;
    border-radius: 6px;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none;
    &:focus:not(:disabled) {
      background-color: $accent-bg-hover;
      color: $color-text; }
    &:disabled {
      cursor: not-allowed; } }
  input[type="password"] {
    text-transform: none!important; }
  input,
  select {
    outline: none;
    height: 61px;
    padding: 0 20px;
    width: 100%; }
  select {
    &::-ms-expand {
      display: none; } }
  textarea {
    resize: none;
    padding: 20px;
    height: 175px;
    line-height: 1.35; }
  .c-notavailable & {
    label {
      &:first-child {
        padding-left: $space; } }
    .c-form__column {
      + .c-form__column {
        margin-top: 30px; } } } }


@include MQ-max(1024px) {
  .c-form {
    &__error-message,
    label {
      padding: 0 20px; } } }

@include MQ(1025px) {
  .c-form {
    input,
    textarea,
    select,
    .c-btn,
    .c-select2,
    .multiselect {
      margin-left: -20px; }

    .c-notavailable & {
      min-width: 750px;
      label {
        &:first-child {
          padding-left: 0; } }
      .c-form__row {
        font-size: 0;
        margin-left: -11px;
        margin-right: -11px; }
      .c-form__column {
        display: inline-block;
        width: 50%;
        vertical-align: bottom;
        padding-left: 11px;
        padding-right: 11px;
        + .c-form__column {
          margin-top: 0; } } } } }

@include MQ(1280px) {
  .c-form {
    .c-notavailable & {
      .c-form__group {
        max-width: 600px; } } } }
