.s-item {
  section {
    + section {
      margin-top: 45px; } } }

@include MQ(1280px) {
  .s-item {
    section {
      + section {
        margin-top: 60px; } } } }

@include MQ(1440px) {
  .s-item {
    section {
      + section {
        margin-top: 80px; } } } }

