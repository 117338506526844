.c-placeholder {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
        content: '';
        display: block;
        padding-bottom: 100%; }

    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 150px;
        width: 50%;
        height: 50%;
        opacity: .1; }

    &--sm {
        &:before {
            padding-bottom: 50%; } } }
