.c-input-number {
  color: $color-text-gray;
  font-size: 1.6rem;
  input {
    background-color: $color-bg;
    border-radius: 4px;
    border: none;
    outline: none;
    width: 74px;
    height: 44px;
    padding: 15px 17px;
    text-align: right;
    -webkit-appearance: none;
    -moz-appearance: textfield; }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .c-detail & {
    margin-left: 22px;
    display: inline-block;
    input {
      background-color: rgba(#fff, .05); } }
  .c-notavailable & {
    label {
      display: inline-block;
      color: $color-text;
      &:first-child {
        padding-right: 10px; } }
    input {
      color: $color-text; } } }

@include MQ(993px) {
  .c-input-number {
    .c-detail & {
      margin-left: 0;
      vertical-align: middle;
      margin-right: 18px;
      order: 1; } } }

@include MQ(1025px) {
  .c-input-number {
    .c-notavailable & {
      input {
        margin-left: 0; } } } }
