.c-cart-good {
  position: relative;
  background-color: $accent-bg;
  border-radius: 6px;
  padding: 35px 36px 20px $space;
  margin-left: -$space;
  margin-right: -$space;
  font-size: 0;
  color: $color-text-gray;
  &:not(:last-child) {
    margin-bottom: 15px; }
  &__const,
  &__var {
    display: inline-block;
    vertical-align: top; }
  &__const {
    position: relative;
    width: 90px;
    margin-right: -90px;
    z-index: 1; }
  &__var {
    position: relative;
    padding-left: 105px;
    width: 100%; }
  &__img {
    display: block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 90px;
    height: 90px;
    border-radius: 6px; }
  &__text {
    max-width: 350px;
    width: 100%;
    line-height: 1.3;
    margin-bottom: 15px;
    padding-right: 8px; }
  &__title {
    font-size: 1.8rem;
    margin-bottom: 3px; }
  &__description {
    font-weight: normal;
    font-size: 1.6rem; }
  &__link-wrapper {
    padding-right: 10px; }
  &__link {
    display: inline;
    border-bottom: 1px dotted $color-text-gray;
    font-size: 1.4rem;
    font-weight: normal; }
  &__price {
    font-size: 2rem;
    line-height: 44px; }
  &__count {
    font-size: 1.8rem;
    font-weight: normal; }
  &__availability {
    font-weight: normal;
    font-size: 1.4rem;
    max-width: 105px;
    padding-right: 15px;
    overflow: hidden; }
  &__remove {
    position: absolute;
    left: calc(100% - 5px);
    bottom: 100%;
    font-size: 2.6rem;
    font-weight: normal;
    line-height: 1;
    padding: 5px;
    cursor: pointer; }
  &__line-group {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: -15px;
    justify-content: space-between;
    flex-wrap: wrap; }
  &--error {
    border: 2px solid $color-error; } }

@include MQ-max(767px) {
  .c-cart-good {
    &__text {
      min-height: 90px; }
    &__availability {
      position: absolute;
      top: 0;
      left: 0;
      line-height: 1.2; }
    &__price,
    &__title {
      color: $color-text; }
    &__line-group {
      margin-left: -105px;
      padding-left: 105px; }
    &--error {
      .c-cart-good {
        &__line-group {
          align-items: flex-start;
          padding-left: 0; }
        &__availability {
          position: static;
          max-width: none;
          font-size: 1.6rem;
          color: $color-text;
          margin-bottom: 2px; }
        &__price {
          line-height: 1; } } } } }

@include MQ(768px) {
  .c-cart-good {
    padding-left: 20px;
    padding-right: 46px;
    padding-top: 20px;
    margin-left: 0;
    margin-right: 0;
    &__const {
      width: 110px;
      margin-right: -110px;
      padding-top: 4px; }
    &__var {
      padding-left: 130px; }
    &__img {
      width: 110px;
      height: 110px; }
    &__text {
      margin-bottom: 10px; }
    &__link-wrapper {
      max-width: 120px;
      display: inline-block;
      vertical-align: middle;
      padding: 0 13px; }
    &__link {
      font-size: 1.3rem; }
    &__price {
      font-size: 1.8rem; }
    &__availability {
      display: inline-block;
      vertical-align: middle;
      background-color: $color-bg;
      padding: 10px 17px;
      max-width: 200px;
      font-size: 1.6rem; }
    &__remove {
      top: -5px;
      bottom: inherit; } } }

@include MQ(1025px) {
  .c-cart-result {
    margin-left: -20px; }
  .c-cart-good {
    margin-left: -20px;
    @include transition;
    &__title,
    &__price,
    &__remove,
    &__link {
      transition: all .2s ease; }
    &:hover {
      background-color: $accent-bg-hover;
      .c-cart-good {
        &__title,
        &__price,
        &__remove,
        &__link {
          color: $color-text;
          transition: none; }
        &__link {
          border-bottom-color: $color-text; } } } } }
