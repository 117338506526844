.c-slider {
  position: relative;
  .c-slider__inner {
    padding-bottom: 22px;
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s;
    &.slick-initialized {
      visibility: visible;
      opacity: 1; } }
  .c-slider__next,
  .c-slider__prev {
    position: absolute;
    top: 50%;
    margin-top: -64.5px; }
  .c-slider__prev {
      left: 0; }
  .c-slider__next {
      right: 0; }
  .slick-dots {
    padding-top: 14px;
    position: relative;
    text-align: left;
    bottom: initial;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    @include scrollbar-style;
    li {
      margin-left: 5px;
      margin-right: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
      &:first-child {
        margin-left: $space; } } } }

.c-thumb {
  position: relative;
  border: 2px solid rgba($color-blue, 0);
  border-radius: 6px;
  overflow: hidden;
  .c-thumb__img {
    position: relative;
    border-radius: 5px;
    font-size: 0;
    background-color: #111111;
    overflow: hidden;
    opacity: .55;
    @include square(74px);
    img {
      position: absolute;
      max-width: 102%;
      max-height: 102%;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      margin: auto; } }
  .slick-active & {
    position: relative;
    border-color: $color-blue;
    .c-thumb__img {
      background-color: #fff;
      opacity: 1; } } }

@include MQ(1440px) {
  .c-slider {
    padding-left: 100px;
    .slick-dots {
      padding-top: 0;
      transform: translateX(-100px) translateY(-5px);
      top: 0;
      left: 0;
      position: absolute;
      white-space: normal;
      display: inline-block;
      height: 491px;
      overflow-y: auto;
      width: 106px;
      overflow-x: hidden;
      li {
        width: 100px;
        display: block;
        &:first-child {
          margin-left: 5px; } } } } }

@include MQ(1440px) {
  .c-thumb {
    display: inline-block;
    .c-thumb__img {
      background-color: #fff;
      @include square(88px); } } }

