.s-breadcrumbs {
  li {
    font-size: 1.6rem;
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
    color: $color-text-gray;
    &:before {
      display: inline-block;
      content: '\0002f'; }
    &:first-child {
      &:before {
        content: ''; }
      a {
        padding-left: 0; } }
    &.is-active {
      color: $color-text;
      a {
        pointer-events: none; } }
    a {
      &:first-letter {
        text-transform: uppercase; } } }
  a {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    padding-left: 5px;
    display: inline-block; }
  .t-bonus & {
    li {
      color: rgba($color-text-yellow, .5);
      &.is-active {
        color: $color-text-yellow; } } } }

@include MQ(993px) {
  .s-breadcrumbs {
    li {
      line-height: 1;
      font-size: 2rem; } } }

@include MQ(1280px) {
  .s-breadcrumbs {
    li {
      font-size: 3rem; }
    a {
      padding-left: 10px;
      padding-right: 8px; } } }
