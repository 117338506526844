.c-arrange {
  .c-arrange__filter {
    position: relative;
    background-color: rgba(#28282e, .6);
    padding-left: 23px;
    padding-right: 17px;
    padding-top: 10px;
    padding-bottom: 9px;
    border-bottom-right-radius: 22px;
    border-top-right-radius: 22px;
    white-space: nowrap;
    font-size: 0;
    &.is-active {
      .c-arrange__btn {
        opacity: 1;
        transform: translate(100%, -50%); } }
    .c-arrange__btn {
      position: absolute;
      font-size: 1.6rem;
      top: 50%;
      left: 50%;
      display: block;
      transform: translate(-50%, -50%);
      opacity: 0; } }
  .c-arrange__drop {
    padding-top: 20px; }
  .c-arrange__amount--large {
    display: none; }
  .c-arrange__row {
    margin-left: -$space;
    margin-right: -$space; }
  .c-arrange__box {
    display: inline-block;
    vertical-align: middle; }
  .c-arrange__label {
    display: inline-block;
    vertical-align: middle;
    font-weight: 500;
    margin-left: 12px;
    font-size: 1.6rem; }
  .c-arrange__column {
    + .c-arrange__column {
      margin-top: 20px; } }
  .c-arrange__right {
    font-size: 0;
    .c-arrange__amount {
      display: inline-block; } }
  .c-arrange__amount {
    font-size: 1.4rem;
    line-height: 1;
    vertical-align: middle;
    padding-right: $space;
    padding-left: $space;
    width: 40%; }
  .c-arrange__sort {
      padding-left: $space;
      padding-right: $space;
      text-align: right;
      display: inline-block;
      vertical-align: middle;
      width: 60%;
      position: absolute;
      left: 40%;
      z-index: 1; } }

@include MQ(993px) {
  .c-arrange {
    .c-arrange__amount {
      font-size: 1.6rem; }
    .c-arrange__label {
      display: inline-block;
      vertical-align: middle; }
    .c-arrange__amount {
      vertical-align: middle; }
    .c-arrange__filter {
      &.is-active {
        .c-arrange__btn {
          display: none; }
        .c-dotted {
          color: $color-text; }
        .c-dotted__anchor {
          background-image: linear-gradient(90deg,$color-text 50%,transparent 0),linear-gradient(90deg,transparent,transparent); } } } } }

@include MQ(1025px) {
  .c-arrange {
    .c-arrange__drop {
      padding-left: 7px; }
    .c-arrange__filter {
      background-color: unset;
      display: inline-block;
      vertical-align: middle;
      padding-bottom: 0;
      padding-top: 0;
      padding-left: 7px;
      padding-right: 7px; }
    .c-arrange__row {
      font-size: 0; }
    .c-arrange__column {
      + .c-arrange__column {
        margin-top: 0; } }
    .c-arrange__label {
      font-weight: normal; }
    .c-arrange__column {
      width: 50%;
      display: inline-block;
      vertical-align: middle; }
    .c-arrange__sort {
      position: relative;
      left: initial;
      width: 100%; }
    .c-arrange__amount {
      width: auto; }
    .c-arrange__right {
      .c-arrange__amount {
        display: none; } }
    .c-arrange__amount--large {
      display: inline-block;
      padding-left: 7px;
      padding-right: 7px; }
    .c-arrange__row {
      margin-left: 0; } } }

@include MQ(1280px) {
  .c-arrange {
    .c-arrange__drop {
      padding-left: 27px; }
    .c-arrange__row {
      margin-left: -$space; } } }

@include MQ(1440px) {
  .c-arrange {
    .c-arrange__drop {
      padding-left: 0; }
    .c-arrange__row {
      margin-left: -$space-desktop; } } }
