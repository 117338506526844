.c-price {
  display: inline-block;
  .c-price__inner {
    line-height: 1;
    font-weight: 500;
    font-size: 2rem;
    padding-top: 9px;
    padding-bottom: 8px;
    padding-left: 7px;
    padding-right: 9px;
    display: block;
    background-color: rgba($color-bg, 1); }
  .c-detail & {
    .c-price__inner {
      padding-left: 0;
      background-color: unset; } } }

@include MQ(768px) {
  .c-price {
    .c-price__inner {
      padding-top: 14px;
      padding-bottom: 13px;
      padding-left: 14px;
      padding-right: 17px; } } }

@include MQ(993px) {
  .c-price {
    .c-detail & {
      .c-price__inner {
        font-size: 3.6rem; } } } }
