.c-radiobuttons-list {
  &__item {
    margin-bottom: 14px; }
  &--50 {
    .c-radiobuttons-list {
      &__item {
        margin-bottom: 12px; } } } }

@include MQ(480px) {
  .c-radiobuttons-list {
    margin-bottom: 40px;
    &--50 {
      margin-left: -3px;
      margin-right: -3px;
      font-size: 0;
      .c-radiobuttons-list {
        &__item {
          display: inline-block;
          vertical-align: top;
          padding-left: 3px;
          padding-right: 3px;
          width: 50%; } } } } }

