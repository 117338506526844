.c-logo {
  .t-guest & {
    padding-left: 19px;
    padding-right: 25px; } }

@include MQ(1440px) {
  .c-logo {
    .t-guest & {
      padding-left: $space * 2;
      padding-right: $space * 2; } } }

@include MQ(1280px) {
  .c-logo {
    .t-guest & {
      padding-right: 26px;
      padding-left: 23px; } } }

