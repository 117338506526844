.c-btn {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  outline: 0;
  -webkit-appearance: none;
  width: auto;
  padding: 15px;
  color: #fff;
  text-align: center;
  user-select: none;
  line-height: 1.35;
  background-color: $color-blue;
  border-radius: 30px;
  border: 2px solid $color-blue;
  @include vertical;
  @include transition;
  &__help {
    display: inline-block;
    vertical-align: middle;
    color: inherit;
    font-weight: 500;
    font-size: 1.6rem; }
  &.t-medium {
    height: 50px;
    padding: 0 15px; }
  &.t-small {
    height: 37px;
    padding: 0 15px;
    .c-btn__help {
      line-height: 1; } }
  &.is-gray,
  &.is-disabled {
    background-color: #3a3a3f;
    border-color: #3a3a3f; }
  &.is-disabled {
    pointer-events: none; }
  &:disabled {
    opacity: .5;
    cursor: not-allowed; }
  .c-detail__btn & {
    min-width: 286px; }
  .c-notavailable & {
    min-width: 260px; }
  .c-header__button & {
    height: 43px;
    padding: 0 29px;
    background-color: rgba(#000, 0);
    min-width: 200px; }
  .t-bonus & {
    border-color: $color-text-yellow;
    background-color: $color-text-yellow;
    color: #1e1f24; }
  .c-fgroup &,
  .c-quantity & {
    width: 100%; } }

@include MQ(678px) {
  .c-btn {
    .c-quantity & {
      .c-btn__help {
        font-size: 1.6rem; }
      &.t-medium {
        height: 43px; } } } }

@include MQ(768px) {
  .c-btn {
    &__help {
      font-size: 1.8rem; } } }

@include MQ(993px) {
  .c-btn {
    .c-quantity & {
      background-color: rgba(#000, 0); }
    .c-quantity & {
      .t-bonus & {
        color: $color-text; } }
    .t-bonus & {
      color: #1e1f24; } } }

@include MQ(1025px) {
  .c-btn {
    .c-notavailable & {
      margin-bottom: 2px; } } }

@include MQ(1280px) {
  .c-btn {
    @include transition(.3s);
    &:hover:not(:disabled) {
      background-color: lighten($color-blue, 10%);
      border-color: lighten($color-blue, 10%); }
    &.is-gray {
      &:hover:not(:disabled) {
        background-color: lighten(#3a3a3f, 10%);
        border-color: lighten(#3a3a3f, 10%); } }
    .c-header__button & {
      &:hover:not(:disabled) {
        background-color: $color-blue;
        border-color: $color-blue; } }
    .t-bonus & {
      &:hover:not(:disabled) {
        border-color: lighten($color-text-yellow, 5%);
        background-color: lighten($color-text-yellow, 5%); } }
    .c-quantity & {
      &:hover:not(:disabled) {
        border-color: $color-blue;
        background-color: $color-blue; } }
    .c-quantity & {
      .t-bonus & {
        &:hover:not(:disabled) {
          border-color: $color-text-yellow;
          background-color: $color-text-yellow;
          color: #1e1f24; } } } } }
