$more-icon: '\e917';

.c-more {
  text-align: center;
  color: $color-text-gray;
  font-size: 1.6rem;
  font-weight: 500;
  padding-top: 34px;
  padding-bottom: 20px;
  &__anchor {
    display: inline;
    position: relative;
    padding-left: 30px;
    &:before {
      content: $more-icon;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 17px;
      font-size: 1.6rem;
      color: inherit;
      @include icomoon; } }
  .c-more__column {
    + .c-more__column {
      margin-top: 30px; } } }

@include MQ(1025px) {
  .c-more {
    .c-more__row {
      margin-right: -$space;
      margin-left: -$space; }
    .c-more__column {
      width: 49%;
      display: inline-block;
      vertical-align: bottom;
      padding-right: $space;
      padding-left: $space;
      + .c-more__column {
        margin-top: 0;
        text-align: right;
        padding-right: 47px; } } } }

@include MQ(1280px) {
  .c-more {
    font-size: 2.2rem;
    &__anchor {
      cursor: pointer;
      padding-left: 41px;
      &:before {
        width: 33px;
        height: 24px;
        font-size: 2.4rem; }
      &:hover {
        color: rgba($color-text, .5); } }
    .c-more__column {
      + .c-more__column {
        padding-right: 67px; } } } }

