.c-logo {
  .t-logged-in & {
    padding-left: 0;
    padding-right: 0; } }

@include MQ(360px) {
  .c-logo {
    .t-logged-in & {
      padding-right: $space; } } }

@include MQ(480px) {
  .c-logo {
    .t-logged-in & {
      padding-right: $space * 2; } } }

@include MQ(1280px) {
  .c-logo {
    .t-logged-in & {
      padding-right: 26px;
      padding-left: 23px; } } }

@include MQ(1440px) {
  .c-logo {
    .t-logged-in & {
      padding-left: $space * 2;
      padding-right: $space * 2; } } }
