.c-props {
  .c-props__list {
    &.is-invisible {
      display: none; } }
  .c-props__item {
    padding-bottom: 22px; }
  .c-props__key {
    color: $color-text-gray;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 1.3;
    margin-bottom: 5px; }
  .c-props__more {
    &.is-invisible {
      display: none; } }
  .c-props__val {
    line-height: 1.3;
    font-size: 1.6rem; } }

@include MQ(768px) {
  .c-props {
    .c-props__list {
      margin-left: -7px;
      margin-right: -7px; }
    .c-props__item {
      padding-top: 12px;
      padding-bottom: 15px;
      padding-left: 7px;
      padding-right: 7px;
      border-radius: 6px;
      font-size: 0;
      @include transition;
      &:hover {
        background-color: rgba($accent-bg, .5); } }
    .c-props__more {
      margin-top: 10px;
      text-align: center; }
    .c-props__key,
    .c-props__val {
      display: inline-block;
      vertical-align: top;
      width: 50%; } } }

@include MQ(993px) {
  .c-props {
    .c-props__inner {
      max-width: 869px; } } }

@include MQ(1025px) {
  .c-props {
    .c-props__list {
      margin-left: -23px;
      margin-right: -23px; }
    .c-props__item {
      padding-left: 23px;
      padding-right: 23px; } } }

@include MQ(1342px) {
  .c-props {
    .c-props__list {
      margin-left: 0;
      margin-right: 0; }
    .c-props__item {
      padding-left: 33px;
      padding-right: 33px; } } }
