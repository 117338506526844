.c-item {
  padding-left: $space;
  padding-right: $space;
  cursor: zoom-in;
  .c-item__img {
    height: 400px;
    position: relative;
    img {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; } }
  .c-item__inner {
    margin-left: auto;
    margin-right: auto;
    background-color: #cccccc;
    border-radius: 5px;
    overflow: hidden; } }

@include MQ(1440px) {
  .c-item {
    height: 485px;
    .c-item__img {
      height: 485px; } } }

