.c-history-table {
  text-align: left;
  width: 100%;
  line-height: 1.2;
  th,
  td {
    font-weight: normal;
    padding: 15px 10px; }
  th {
    font-size: 1.8rem;
    vertical-align: top; }
  td {
    font-size: 1.6rem;
    vertical-align: top;
    &.c-history-table {
      &__sum-td {
        white-space: nowrap;
        line-height: 1.4;
        &--bold {
          font-weight: 500; } } } }
  &__sum-row {
    td {
      font-size: 2.2rem;
      color: $color-text !important;
      font-weight: 500; } }
  &__accent-str {
    display: block;
    font-size: 1.4rem; }
  &__images {
    display: block;
    font-size: 0;
    margin-top: 5px; }
  &__image {
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    height: 60px;
    margin: 5px 0;
    border-radius: 6px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    &:not(:last-child) {
      margin-right: 4px; } }
  &__plus {
    display: inline-block;
    vertical-align: middle;
    font-size: 3rem;
    font-weight: 300; }
  &__description-td {
    line-height: 1.3;
    &-title {
      font-weight: 500;
      font-size: 1.8rem; }
    &-text {
      margin-bottom: 3px; }
    &--no-hover {
      color: $color-text-gray !important; } }
  &__ttn {
    margin-top: 10px; } }

@include MQ(901px) {
  .c-history-table {
    margin-left: -20px;
    border-collapse: separate;
    border-spacing: 0 15px;
    td {
      background-color: $color-bg;
      color: $color-text-gray;
      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px; }
      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px; } }
    th,
    td {
      &:first-child {
        padding-left: 20px; }
      &:last-child {
        padding-right: 20px; } }
    th {
      padding-bottom: 0;
      padding-top: 0; }

    &__sum-row {
      td {
        background-color: transparent !important; } }
    &__image {
      width: 6.1vw;
      height: 6.1vw; }
    &__sum-td,
    &__sum-th {
      text-align: right; } } }

@include MQ(1025px) {
  .c-history-table {
    max-width: 800px;
    td {
      vertical-align: baseline; }
    &__line {
      white-space: nowrap; }
    &__images {
      display: inline-block;
      vertical-align: middle;
      margin-left: 1vw;
      margin-top: 0; }
    &__image {
      margin: 0;
      &:not(:last-child) {
        margin-right: 10px; } }
    tr {
      td {
        transition: all .2s ease; }
      &:hover {
        td {
          transition: none;
          color: $color-text;
          background-color: $accent-bg-hover; }
        .c-history-table {
          &__accent-str {
            color: $color-text-yellow; } } } } } }

@include MQ(1367px) {
  .c-history-table {
    th,
    td {
      padding-left: 15px;
      padding-right: 15px; }
    &__images {
      margin-left: 25px; }
    &__image {
      width: 85px;
      height: 85px; } } }

@include MQ-max(900px) {
  .c-history-table {
    thead {
      display: none; }
    tr {
      position: relative;
      display: flex;
      flex-direction: column;
      background-color: $color-bg;
      border-radius: 6px;
      &:not(:last-child) {
        margin-bottom: 15px; }
      &.c-history-table__sum-row {
        background-color: transparent !important;
        display: flex;
        flex-direction: row;
        td {
          padding-left: 0;
          padding-right: 0;
          &.c-history-table__sum-row-td {
            padding-left: $space;
            padding-right: $space; } } } }
    td {
      display: block;
      order: 1;
      &.c-history-table {
        &__date-td {
          order: 0;
          color: $color-text-gray;
          padding-bottom: 0; }
        &__number-td {
          padding-bottom: 0; }
        &__sum-td {
          max-width: 50%; }
        &__status-td {
          position: absolute;
          bottom: 0;
          right: 0;
          max-width: 50%; } } }
    td {
      padding-left: $space;
      padding-right: $space; }
    &__accent-str {
      color: $color-text-yellow; } } }

@include MQ-max(767px) {
  .c-history-table {
    tr {
      margin-left: -$space;
      margin-right: -$space; } } }
