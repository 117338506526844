.c-close {
  padding-left: $space;
  padding-right: $space;
  height: 46px;
  position: relative;
  vertical-align: middle;
  width: 20%;
  &:before {
    transform: translateX(-50%) rotate(45deg); }
  &:after {
    transform: translateX(-50%) rotate(-45deg); }
  &:before,
  &:after {
    content: '';
    width: 22px;
    height: 1.5px;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 4px;
    background-color: #fff; } }

