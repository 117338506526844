.c-header {
  position: relative;
  height: 46px;
  color: #fff;
  background-color: $color-blue;
  z-index: 11;
  .c-header__phones {
    padding-left: $space;
    padding-right: $space; }
  .c-header__currency {
    padding-left: $space * 2;
    padding-right: $space; }
  .c-header__button {
    padding-right: 19px; }
  .c-header__column {
    display: inline-block;
    vertical-align: middle;
    font-size: 0; }
  .c-action__label {
    display: none; }
  .t-guest & {
    background-color: unset; }
  .t-visivile {
    display: none; }
  .has-burger-hoverable {
    display: none; } }

@include MQ(1025px) {
  .c-header {
    .t-visivile {
      display: inline-block; } } }

@include MQ(1280px) {
  .c-header {
    color: $color-blue;
    height: 72px;
    padding-bottom: 12px;
    padding-top: 12px;
    background-color: #222227;
    .c-header__button {
      padding-right: 26px; } } }

@include MQ(1367px) {
  .c-header {
    .has-burger-hoverable {
      display: inline-block; }
    .has-burger-clickable {
      display: none; } } }



@include MQ(1440px) {
  .c-header {
    .c-header__inner {
      padding-right: $space; }
    .c-header__button {
      padding-right: 34px; } } }

