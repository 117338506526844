html {
  height: 100%; }
body {
  height: 100%;
  min-height: 100%;
  background: radial-gradient(circle, rgba(#222228,0.5) 30%, rgba(0,0,0,0.75) 100%, transparent);
  background-attachment: fixed;
  background-color: #191919;
  -webkit-overflow-scrolling: touch; }

.l-site {
  height: 100%;
  overflow: auto; }

.l-wrapper {
  min-height: 100%;
  padding-bottom: 193px;
  position: relative;
  padding-top: 46px;
  .l-contacts & {
    padding-bottom: 116px; } }

.l-row {
  font-size: 0;
  @include justify; }

.l-column {
  font-size: 0;
  display: inline-block; }

.l-row {
  @include MQ(1280px) {
    margin-top: -12px; } }

.l-wrapper {
  @include MQ(993px) {
    padding-bottom: 117px;
    .l-contacts & {
      padding-bottom: 0; } }
  @include MQ(1024px) {
    display: flex;
    flex-direction: column; }
  @include MQ(1280px) {
    padding-bottom: 77px;
    padding-top: 72px; }
  @include MQ(1440px) {
    display: flex;
    flex-direction: column; } }

.l-main {
  @include MQ(1024px) {
    margin-top: auto;
    margin-bottom: auto;

    &--top {
      margin-top: 0; } } }
