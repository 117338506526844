.c-dotted-link {
  display: inline;
  font-size: 1.6rem;
  color: $color-text-gray;
  border-bottom: 1px dotted $color-text-gray;
  &--fw-normal {
    font-weight: normal; } }

@include MQ(1025px) {
  .c-dotted-link {
    @include transition;
    &:hover {
      color: $color-text; } } }
