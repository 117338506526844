.c-action {
  display: block;
  padding-right: $space;
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;
  .c-action__box {
    height: 15px;
    width: 22px;
    position: relative;
    display: table; }
  [class^='icon'] {
    display: table-cell;
    vertical-align: middle; } }

@include MQ(480px) {
  .c-action {
    padding-left: $space; } }

@include MQ(1280px) {
  .c-action {
    padding-top: 25px;
    padding-bottom: 25px;
    &:hover {
      background-color: #1E2024; }
    .c-action__box {
      display: inline-block;
      vertical-align: middle;
      width: unset;
      height: unset; }
    .c-action__label {
      display: inline-block;
      vertical-align: middle;
      font-weight: normal;
      font-size: 1.6rem;
      color: rgba(#fff, .5);
      margin-left: 10px;
      line-height: 1; } } }

