.c-drop {
  position: relative;
  white-space: normal;

  .c-burger {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 72px; }

  .t-bonus {
    a {
      color: rgba($color-text-yellow, .5);

      &:hover {
        color: $color-text-yellow; } } }

  a {
    font-size: 1.8rem;
    background-color: #2c2c33;
    color: rgba(#fff, .5);
    font-weight: normal;
    cursor: pointer;

    &.has-icon {
        padding-left: 57px;
        position: relative;
        [class^='icon'] {
          font-size: 2.4rem;
          width: 57px;
          height: 31px;
          position: absolute;
          display: block;
          left: 0;
          text-align: center;
          top: 50%;
          bottom: auto;
          transform: translateY(-50%); } } }

  &__nested {
    top: 100%;
    left: 0;

    li {
      display: block;
      position: relative;

      a {
        display: block;
        width: 245px;
        line-height: 1;
        padding-top: 21px;
        padding-bottom: 20px;
        padding-right: 30px;
        text-align: left; } }

    .is-hover > a {
      color: #fff;
      background-color: #1e1f24; } }

  &__subnested {
    top: 0;
    left: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #1e1f24;

    li {
      a {
        padding: 10px 31px 9px;
        background-color: #1e1f24;
        &:hover {
          color: #fff;
          background-color: #1e1f24; } } }

    .is-hover &,
    &:hover {
      opacity: 1;
      visibility: visible; } }

  &__nested,
  &__subnested {
    position: absolute;
    margin-left: 0;
    visibility: hidden;
    opacity: 0; }

  &:hover {
    .c-burger {
      color: #fff;
      background-color: #1e1f24; }

    .c-drop__nested {
      opacity: 1;
      visibility: visible; } } }
