.s-brief {
  ul {
    font-size: initial; }
  li {
    font-size: 1.6rem;
    font-weight: normal;
    color: $color-text-gray;
    display: inline;
    &:after {
      content: '\0002f';
      display: inline-block; }
    &:last-child {
      &:after {
        content: none; } } } }

@include MQ(1280px) {
  .s-brief {
    li {
      font-size: 1.8rem;
      line-height: 1.33;
      font-weight: 500; } } }
