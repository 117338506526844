.c-filter {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 500px;
  max-height: 100%;
  background-color: rgba(#25252b, .8);
  padding-left: 24px;
  padding-top: 35px;
  padding-bottom: 38px;
  padding-right: $space;
  border-radius: 6px;
  @include scrollbar-style; }

@include MQ(993px) {
  .c-filter {
    padding-bottom: 50px; } }

@include MQ(1025px) {
  .c-filter {
    padding-left: 47px; } }

@include MQ(1280px) {
  .c-filter {
    padding-left: 67px; } }

@include MQ(1315px) {
  .c-filter {
    padding-left: 120px; } }
