.s-props {
  li {
    line-height: 1;
    display: none;
    &:first-child,
    &:nth-child(2) {
      display: block; }
    + li {
      margin-top: 4px; } } }

@include MQ(678px) {
  .s-props {
    li {
      font-size: 1.6rem; } } }

@include MQ(993px) {
  .s-props {
    li {
      display: inline;
      line-height: 1.5;
      &:first-child,
      &:nth-child(2) {
        display: inline; }
      &:after {
        content: '\0002f';
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px; }
      &:last-child {
        &:after {
          content: none; } } } } }
