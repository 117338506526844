.c-detail {
  padding-left: $space;
  padding-right: $space;
  .c-detail__available {
    padding-bottom: 16px; }
  .c-detail__title {
    margin-bottom: 15px; }
  .c-detail__group {
    display: block;
    margin-top: 18px; }
  .c-detail__price {
    font-size: 3rem;
    vertical-align: middle; }
  &__flex-divider {
    display: none; } }

@include MQ(993px) {
  .c-detail {
    .c-detail__inner {
      display: inline-block;
      text-align: left;
      padding-left: 40px; }
    .c-detail__brief {
      max-width: 410px; }
    .c-detail__group {
      text-align: left;
      margin-left: auto;
      margin-right: auto;
      margin-top: 12px;
      padding-left: 40px; }
    .c-detail__available {
      padding-bottom: 0;
      margin-bottom: 15px; }
    .c-detail__price {
      order: -1;
      transform: translateX(-2px);
      margin-bottom: 15px; }
    .c-detail__btn {
      display: inline-block;
      vertical-align: middle;
      order: 1; }
    .c-detail__flex {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      &-divider {
        display: block;
        width: 100%;
        height: 20px; } } } }

@include MQ(1440px) {
  .c-detail {
    .c-detail__brief {
      max-width: 440px; }
    .c-detail__group,
    .c-detail__inner {
      padding-left: 70px; } } }
