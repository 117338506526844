.c-footer {
  text-align: center;
  .c-footer__inner {
    padding-left: $space;
    padding-right: $space; }
  .c-footer__field {
    font-size: 1.6rem;
    font-weight: normal;
    color: $color-text-gray;
    + .c-footer__field {
      margin-top: 30px; } }
  .c-footer__column {
    + .c-footer__column {
      margin-top: 8px; } }
  .c-footer__label {
    line-height: 1.7rem; }
  .c-footer__label,
  .c-footer__anchor {
    display: inline-block;
    vertical-align: middle; }
  .c-menu & {
    .c-footer__inner {
      padding-left: 0;
      padding-right: 0;
      padding-top: 25px;
      padding-bottom: 25px; }
    .c-footer__row {
      flex-direction: column; } } }

@include MQ(480px) {
  .c-footer {
    .c-menu & {
      text-align: left;
      .c-footer__field {
        font-weight: normal; }
      .c-footer__inner {
        display: inline-block;
        padding-left: 20px;
        text-align: center; }
      .c-footer__left,
      .c-footer__right {
        width: 100%;
        text-align: center; }
      .c-footer__left {
        flex-direction: column;
        .c-footer__field {
          width: 100%;
          display: block;
          + .c-footer__field {
            margin-top: 30px; } } } } } }

@include MQ(993px) {
  .c-footer {
    .l-contacts & {
      .c-footer__left {
        .c-footer__field {
          width: 100%; } } }
    .c-footer__row {
      font-size: 0;
      margin-left: -$space;
      margin-right: -$space;
      display: flex;
      flex-direction: row; }
    .c-footer__left,
    .c-footer__right {
      width: 50%;
      padding-left: $space;
      padding-right: $space;
      .c-footer__field {
        + .c-footer__field {
          margin-top: 0; } } }
    .c-footer__left {
      text-align: left;
      display: flex;
      flex-direction: row-reverse;
      .c-footer__field {
        width: 50%;
        + .c-footer__field {
          padding-right: $space; } } }
    .c-footer__right {
      text-align: right; }
    .c-footer__left {
      + .c-footer__right {
        margin-top: 0; } } } }

@include MQ(1280px) {
  .c-footer {
    .c-footer__inner {
      padding-left: $space-desktop;
      padding-right: $space-desktop; } } }

@include MQ(1380px) {
  .c-footer {
    .c-footer__inner {
      max-width: 1380px;
      margin-left: auto;
      margin-right: auto; } } }

