.c-quantity {
  .c-quantity__close {
    position: absolute;
    top: 0;
    right: 0;
    &:before,
    &:after {
      width: 15px;
      background-color: $color-text-gray; } }
  &__head {
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 10px; }
  &__action {
    font-size: 0; }
  &__inputs {}
  &__button {
    margin-left: -2px;
    margin-right: -2px;
    margin-top: 9px; }
  &__field {
    display: inline-block;
    vertical-align: middle;
    height: 47px;
    background-color: $color-bg;
    font-size: 1.6rem;
    font-weight: 500;
    color: $color-text-gray; }
  &__sign {
    width: 20%;
    font-size: 3rem;
    font-weight: normal; }
  &__minus {
    font-size: 1.6rem;
    font-weight: 500; }
  &__controls {
    width: 58%;
    margin-left: 1%;
    margin-right: 1%;
    position: relative;
    border-radius: 4px;
    overflow: hidden; }
  &__label {
    position: absolute;
    left: 51%;
    top: 50%;
    transform: translateY(-50%); }
  &__input {
    text-align: right;
    padding-left: 10px;
    padding-top: 18px;
    padding-bottom: 17px;
    padding-right: 51%;
    -webkit-appearance: none;
    color: $color-text-gray;
    background-color: rgba($color-bg, 1);
    border: none;
    -moz-appearance: textfield;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    &:focus {
      outline: none; }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; } } }

@include MQ(480px) {
  .c-quantity {
    width: 430px;
    max-width: 100%; } }

@include MQ(678px) {
  .c-quantity {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &__sign {
      display: none; }
    &__controls {
      width: 100%; }
    &__input {
      width: 100%;
      padding-right: 52%; }
    &__head {
      text-align: right; }
    &__action {
      font-size: 0;
      margin-top: auto; }
    &__button {
      margin-top: 20px; } } }

@include MQ(993px) {
  .c-quantity {
    &__action {
      display: flex;
      flex-direction: row;
      justify-content: flex-end; }
    &__inputs {
      display: inline-block;
      padding-right: 12px; }
    &__button {
      padding-left: 7px;
      display: inline-block;
      flex: 0 0 175px;
      margin-top: 0; }
    &__label {
      position: relative;
      transform: none;
      left: initial;
      top: initial; }
    &__action {
      width: 100%; }
    &__controls {
      background-color: unset;
      text-align: right; }
    &__field {
      border-radius: 0;
      white-space: nowrap; }
    &__input {
      position: relative;
      width: 74px;
      height: 44px;
      padding-top: 12px;
      padding-bottom: 8px;
      padding-right: 10px;
      border-radius: 4px; }
    &__field {
      height: unset; }
    &__head {
      padding-top: 12px;
      padding-bottom: 11px;
      padding-left: 14px;
      padding-right: 17px;
      background-color: rgba($color-bg, 1); } } }

@include MQ(1440px) {
  .c-quantity {
    &__inputs {
      padding-right: 23px; } } }

