.c-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transform: translate(-120%, 0);
  transition: opacity .3s;
  background-color: rgba($color-bg, .9);
  opacity: 0;
  &.is-open {
    transform: translate(0, 0);
    opacity: 1; }
  .c-overlay__content {
    background-color: $color-blue;
    width: 276px;
    height: 100%;
    overflow: auto; } }

.c-overlay {
  @include MQ(1367px) {
    &.is-open {
      transform: translate(-120%, 0); } } }

.c-modal-overlay {
  @extend .c-overlay;
  z-index: 12;
  .c-modal-overlay__close {
    width: 61px;
    height: 47px;
    display: block;
    padding: 0;
    position: fixed;
    right: 10px;
    top: 10px;
    margin-left: auto;
    margin-right: 0;
    border-radius: 4px;
    &:before,
    &:after {
      right: 7px; } }
  .c-overlay__content {
    background-color: rgba(#000, .65 );
    width: 100%;
    @include scrollbar-style; } }

@include MQ(1280px) {
  .c-modal-overlay {
    &.is-open {
      transform: translate(0, 0); }
    .c-modal-overlay__close {
      cursor: pointer;
      top: 50px;
      right: 50px;
      &:before,
      &:after {
        width: 30px;
        right: -1px; } } } }

