.c-form-hints {
  padding-top: 20px;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.2;
  &__title {
    color: $color-text;
    margin-bottom: 5px; }
  &__list {
    color: $color-text-gray; }
  &__list-item {
    display: inline-block;
    vertical-align: top; } }
