.c-fgroup {
  + .c-fgroup {
    margin-top: 37px; }
  + .c-fgroup--action {
    margin-top: 32px; }
  .c-fgroup__title {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 12px;
    transform: translateX(-3px); }
  .c-fgroup__dotted {
    margin-top: 7px; } }

.fade-enter-active,
.fade-leave-active {
  transition: opacity .2s; }

.fade-enter,
.fade-leave-to {
  opacity: 0; }

@include MQ(993px) {
  .c-fgroup {
    display: inline-block;
    min-width: 51%;
    text-align: left;
    &.c-fgroup--action {
      min-width: 60%; } } }

@include MQ(1280px) {
  .c-fgroup {
    &.c-fgroup--action {
      min-width: 260px;
      transform: translateX(-40px); } } }

@include MQ(1315px) {
  .c-fgroup {
    &.c-fgroup--action {
      transform: translateX(-71px); } } }
