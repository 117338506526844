.c-info {
  background-color: rgba($accent-bg, .6);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.38;
  color: $color-text-gray;
  padding-top: 21px;
  padding-bottom: 21px;
  padding-left: 19px;
  padding-right: 22px;
  border-radius: 6px;
  height: 0;
  opacity: 0;
  transition: opacity .3s;
  &.is-open {
    display: block;
    opacity: 1;
    height: auto; }
  .c-info__link {
    color: $color-text-yellow; } }

@include MQ(1025px) {
  .c-info {
    &.is-open {
      display: none; } } }

