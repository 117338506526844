.c-rights {
  a {
    display: inline; }
  .c-menu__rights & {
    padding-left: $space;
    padding-right: $space;
    padding-top: 40px;
    padding-bottom: 40px;
    .c-rights__item {
      font-size: 1.6rem;
      font-weight: normal;
      color: rgba(#fff, .5); } } }

