@mixin MQ($canvas) {
  @media only screen and (min-width: $canvas) {
    @content; } }

@mixin MQ-max($canvas) {
  @media only screen and (max-width: $canvas) {
    @content; } }

@mixin center($xy:xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%); }
  @else if $xy == x {
    left: 50%;
    right: auto;
    transform: translateX(-50%); }
  @else if $xy == y {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%); } }

@mixin input-placeholder {
  &.placeholder {
    @content; }
  &:-moz-placeholder {
    @content; }
  &::-moz-placeholder {
    @content; }
  &:-ms-input-placeholder {
    @content; }
  &::-webkit-input-placeholder {
    @content; } }

@mixin size($width,$height) {
  width: $width;
  height: $height; }

@mixin square($width) {
  width: $width;
  height: $width; }

@mixin justify {
  font-size: 0;
  text-align: justify;
  text-justify: newspaper;
  li {
    display: inline-block;
    vertical-align: middle; }
  &:after {
    height: 1px;
    margin-top: -1px;
    content: "";
    display: inline-block;
    width: 100%; } }

@mixin vertical {
  font-size: 0;
  text-align: center;
  .help {
    display: inline-block;
    vertical-align: middle; }
  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    @include size(.1%,100%);
    margin-left: -.1%; } }

@mixin icomoon {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@mixin transition($s: .2s) {
  transition: all $s ease;
  &:hover {
    transition: none; } }

@mixin scrollbar-style {
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px; }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 5px; }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: $color-text-gray;
    -webkit-box-shadow: none; } }

