.c-currency {
  display: flex;
  align-items: center;
  .c-currency__label {
    display: none; }
  .c-currency__item {
    position: relative;
    text-align: center;
    border-radius: 50%;
    height: 34px;
    width: 34px;
    color: rgba(#fff, .5);
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &.is-active {
      background-color: #fff;
      color: $color-blue; }
    + .c-currency__item {
      margin-left: 7px; } }
  .c-currency__sign {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.6rem;
    color: inherit;
    font-family: 'Gotham Pro', sans-serif; }
  .c-menu & {
    .c-currency__label {
      display: inline-block;
      vertical-align: middle;
      font-size: 1.8rem;
      color: #fff;
      font-weight: 500;
      margin-right: 24px; }
    .c-currency__item {
      &.is-active {
        background-color: #fff;
        color: $color-blue; } } } }

@include MQ(1280px) {
  .c-currency {
    .c-currency__item {
      cursor: pointer;
      &:hover {
        background-color: rgba($color-blue, .7); }
      &.is-active {
        background-color: $color-blue;
        color: #fff;
        pointer-events: none; } } } }

